/**
 *  SelectDropDown Component
 * @format
 */
import React from "react";
import Select from "react-select";

export default function SelectDropDown({
  name,
  options,
  value,
  touched,
  errors,
  placeholder,
  isSearchable,
  isClearable,
  onChange,
}) {
  return (
    <Select
      options={options}
      blurInputOnSelect
      placeholder={placeholder || "Select"}
      isClearable={isClearable}
      isSearchable={isSearchable || false}
      name={name}
      value={value}
      controlShouldRenderValue
      hideSelectedOptions={true}
      onChange={onChange}
      styles={{
        control: (provided, state) => ({
          ...provided,
          border: state.isFocused ? "2px solid #B0E0FF" : "2px solid #B0E0FF",
          borderRadius: "12px",
          // height: "44px",
          boxShadow: state.isFocused
            ? "0 0 0 2px rgba(176, 224, 255, 0.5)"
            : "none",
          "&:hover": {
            borderColor: "#B0E0FF",
          },
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected
            ? "#B0E0FF"
            : provided.backgroundColor,
          color: state.isSelected ? "#fff" : provided.color,
        }),
      }}
    />
  );
}
