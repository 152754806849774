/**
 * All User List Screen Page
 * @flow
 * @format
 */

import React, { useEffect, useState } from "react";
import { Switch } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";

import { HeaderScreen, Sidebar } from "../../../components";
import { Edit } from "../../../assets/icons/Edit";
import { Delete } from "../../../assets/icons/Delete";
import { AddCircle } from "../../../assets/icons/AddCircle";
import { SearchIcon } from "../../../assets/icons/SearchIcon";
import { FilterIcon } from "../../../assets/icons/FilterIcon";
import { AddUserModel } from "./addUserScreen";
import {
  allUsersList,
  deleteUser,
  getUserById,
  updateUserStatusById,
} from "../slice";
import {
  selectUserDataList,
  selectUserCurrentPage,
  selectUserDataPerPage,
  selectSingleUserData,
} from "../selector";
import { toast } from "react-toastify";
import { EditUserModels } from "./editUserScreen";
import { endPoints } from "../../../services";
import DateTableComponent from "../../../components/dataTableComponent";
import { selectUserAllPermissions } from "../../common";

const UserListScreen = () => {
  const dispatch = useDispatch();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const userPermissions = useSelector(selectUserAllPermissions) || {};
  const { data = [], totalAdmin } = useSelector(selectUserDataList) || {};
  const userData = useSelector(selectSingleUserData) || {};
  const currentPage = useSelector(selectUserCurrentPage);
  const limit = useSelector(selectUserDataPerPage);

  const getData = (searchData, page, perPage) => {
    dispatch(
      allUsersList({
        search: searchData,
        page: page,
        perPage: perPage,
        onSuccess: (props) => {
          console.log("Users loaded successfully");
        },
      })
    );
  };

  const userDataById = (userId) => {
    dispatch(
      getUserById({
        id: userId,
        onSuccess: (props) => {
          console.log("User loaded successfully");
        },
      })
    );
  };

  useEffect(() => {
    getData(searchQuery, currentPage, limit);
  }, [searchQuery, currentPage, limit]);

  const toggleAddModal = () => {
    setIsAddModalOpen(!isAddModalOpen);
  };

  const toggleEditModal = (userId) => {
    if (!isEditModalOpen) {
      userDataById(userId);
    }
    setIsEditModalOpen(!isEditModalOpen);
  };

  const SortIcon = ({ isSorted, isSortedDesc }) => {
    if (!isSorted) {
      return <span className="ml-4">&uarr;&darr;</span>; // Default unsorted icon
    } else if (isSortedDesc) {
      return <span className="ml-4">&darr;</span>; // Descending sort icon
    } else {
      return <span className="ml-4">&uarr;</span>; // Ascending sort icon
    }
  };

  const columnStyle = {
    fontSize: "14px",
    fontWeight: "normal",
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row?.name,
      sortable: true,
      style: columnStyle,
    },
    {
      name: "Assigned Role",
      selector: (row) => row?.role?.map((role) => role.role_name).join(", "),
      sortable: true,
      style: columnStyle,
    },
    {
      name: "Access Level",
      selector: (row) =>
        row?.access_level?.map((item) => item.access_level_id?.name).join(", "),
      sortable: true,
      style: columnStyle,
    },
    {
      name: "Locale",
      selector: (row) => (
        <div className="">
          {row?.allLocales?.map((item, index) => (
            <div key={index}>{item},</div>
          ))}
        </div>
      ),
      sortable: true,
      style: columnStyle,
    },
    {
      name: "User Type",
      selector: (row) =>
        row?.user_type_id?.map((item) => item.user_type_name).join(", "),
      sortable: true,
      style: columnStyle,
    },
    {
      name: "Status",
      selector: (row) => row.is_active,
      sortable: true,
      style: columnStyle,
      cell: (row) => (
        <div className="flex flex-row justify-between space-x-2">
          <span className="cursor_pointer displayBlock flex flex-auto font-medium items-center justify-end text-sm w-max">
            {row.is_active === true ? "Active" : "Inactive"}
          </span>
          {userPermissions?.Activate_Deactivate_User === true && (
            <div
              className="flex justify-center items-center"
              onClick={() => {
                if (userPermissions?.Activate_Deactivate_User === false) {
                  toast.error("you have No Permssions");
                }
              }}
            >
              <Switch
                id={row.id}
                ripple={false}
                checked={row.is_active === true}
                onChange={() => {}}
                disabled={!userPermissions?.Activate_Deactivate_User}
                onClick={() => {
                  if (userPermissions?.Activate_Deactivate_User === true) {
                    dispatch(
                      updateUserStatusById({
                        id: row?._id,
                        onSuccess: (props) => {
                          toast.success(props?.message);
                          getData(searchQuery);
                        },
                      })
                    );
                  }
                }}
                className="h-full w-full checked:bg-[#B0E0FF]"
                containerProps={{
                  className: "w-8 h-4",
                }}
                circleProps={{
                  className:
                    "before:hidden left-1 border-none w-[12px] h-[12px]",
                }}
              />
            </div>
          )}

          <div className="flex flex-row justify-center items-center space-x-2 flex-1">
            {userPermissions?.Edit_User === true && (
              <div
                className="cursor_pointer flex justify-center"
                onClick={() => {
                  if (userPermissions?.Edit_User === true) {
                    toggleEditModal(row._id);
                  } else if (userPermissions?.Edit_User === false) {
                    toast.error("you have No Permssions to Edit User");
                  }
                }}
              >
                <Edit width={12} height={12} />
              </div>
            )}
            {userPermissions?.Delete_User === true && (
              <div className={`cursor_pointer flex justify-center`}>
                <span
                  onClick={() => {
                    if (userPermissions?.Delete_User === true) {
                      dispatch(
                        deleteUser({
                          id: row?._id,
                          onSuccess: (props) => {
                            toast.success(props?.message);
                            getData(searchQuery);
                          },
                        })
                      );
                    } else if (userPermissions?.Delete_User === false) {
                      toast.error("you have No Permssions to Delete User");
                    }
                  }}
                >
                  <Delete width={12} height={12} />
                </span>
              </div>
            )}
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="h-screen min-w-screen relative ">
      <HeaderScreen />
      <div className="flex h-[calc(100%-80px)] w-[100%]">
        <Sidebar />
        <div className="flex flex-col h-full bg-customBlue-mainBackground max-w-[calc(100%-87px)] w-[100%] overflow-auto">
          <div className="container min-h-[80px] min-w-full px-6 flex flex-row items-center justify-between">
            <div className="font-bold font-raleway text-[24px]">USER</div>
            <div className="container flex flex-row justify-between items-center space-x-4 h-[55%] max-w-[45%] md:max-w-[70%] ">
              {/* Search and Filter */}
              <div className="container p-0 flex justify-between items-center h-[100%]  ">
                {/* Search Bar */}
                <div className="relative flex-1 mr-4 rounded-lg border border-[#E8E8E8] h-full">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none h-full">
                    <SearchIcon width={20} height={20} />
                  </div>
                  <input
                    type="search"
                    id="default-search"
                    className="block w-full h-[100%] p-4 ps-10 text-sm text-gray-text-100 rounded-lg "
                    placeholder="Search"
                    onChange={(event) => {
                      setSearchQuery(event.target.value);
                    }}
                  />
                </div>
                {/* Filter Dropdown */}
                <div className="border w- h-full bg-white rounded-lg border-[#E8E8E8] flex justify-center items-center px-2 pointer-events-none">
                  <FilterIcon width={20} height={20} />
                </div>
              </div>
              {userPermissions?.Create_User === true && (
                <div className="h-[100%]">
                  <button
                    className="container flex flex-row px-2 justify-around rounded-lg items-center gap-2 h-[100%] w-[100%] bg-[#3699FF] text-white text-sm font-semibold"
                    onClick={() => {
                      if (userPermissions?.Create_User === true) {
                        toggleAddModal();
                      } else if (userPermissions?.Create_User === false) {
                        toast.error("you have No Permssions to Create User");
                      }
                    }}
                  >
                    <span className="flex-shrink-0">
                      <AddCircle width={18} height={18} />
                    </span>
                    <span className="text-[14px] font-semibold truncate">
                      Create New User
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="container flex flex-col justify-between h-[calc(100%-80px)] max-w-full">
            {/* Data Table */}
            <div className="px-6 overflow-y-auto scrollbar-hide">
              <DateTableComponent
                searchQuery={searchQuery}
                url={endPoints.allUsersList}
                apiColumn={columns}
                sortIcon={<SortIcon />}
                backgroundColor={"#DDF1FE"}
                selectableRows={false}
                pagination={true}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Modals */}
      {isAddModalOpen && (
        <AddUserModel toggleModal={toggleAddModal} getData={getData} />
      )}
      {isEditModalOpen && (
        <EditUserModels
          userData={userData}
          toggleModal={toggleEditModal}
          getData={getData}
        />
      )}
    </div>
  );
};

export { UserListScreen };
