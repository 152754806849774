/**
 * Assigned Role to User Model
 * @format
 */

import React, { useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getRoleByIdWithUser, updateRoleById } from "../slice";
import { Cross } from "../../../assets/icons/Cross";
import DataTable from "react-data-table-component";
import { selectRoleWithUser } from "../selector";
import { Switch } from "@material-tailwind/react";
import { Delete } from "../../../assets/icons/Delete";
import { NoRecord } from "../../../components/noRecords";

const AssignedUsers = ({ role, toggleModal, getData }) => {
  const dispatch = useDispatch();
  const { role: roleData, totalAdmin } = useSelector(selectRoleWithUser) || {};

  const initialValues = {};

  const SortIcon = ({ isSorted, isSortedDesc }) => {
    if (!isSorted) {
      return <span className="ml-4">&uarr;&darr;</span>;
    } else if (isSortedDesc) {
      return <span className="ml-4">&darr;</span>;
    } else {
      return <span className="ml-4">&uarr;</span>;
    }
  };

  const columnStyle = {
    fontSize: "14px",
    fontWeight: "normal",
  };

  const getRoleWithUserData = (id) => {
    dispatch(
      getRoleByIdWithUser({
        id,
        onSuccess: (props) => {
          console.log("Role loaded successfully");
        },
      })
    );
  };

  useEffect(() => {
    getRoleWithUserData(role?._id);
  }, [role]);

  const columns = [
    {
      name: "Name",
      selector: (row) => row?.name,
      sortable: true,
      style: columnStyle,
    },
    {
      name: "Access Level",
      selector: (row) =>
        row?.access_level?.map((item) => item.access_level_id?.name).join(", "),
      sortable: true,
      style: columnStyle,
    },
    {
      name: "Locale",
      selector: (row) => (
        <div className="">
          {row?.allLocales?.map((item, index) => (
            <div key={index}>{item},</div>
          ))}
        </div>
      ),
      sortable: true,
      style: columnStyle,
    },
    {
      name: "Status",
      selector: (row) => row.is_active,
      // sortable: true,
      style: columnStyle,
      cell: (row) => (
        <div className="flex flex-row justify-between space-x-2 w-[100%]">
          <span className="cursor_pointer flex justify-center items-center flex-auto font-medium text-sm">
            {row.is_active === true ? "Active" : "Inactive"}
          </span>
          {/* <div className="flex justify-center items-center">
            <Switch
              id={row.id}
              ripple={false}
              checked={row.is_active === true}
              // onChange={() => {}}
              // onClick={() => {
              //   dispatch(
              //     updateUserStatusById({
              //       id: row?._id,
              //       onSuccess: (props) => {
              //         toast.success(props?.message);
              //         getData(searchQuery);
              //       },
              //     })
              //   );
              // }}
              className="h-full w-full checked:bg-[#B0E0FF]"
              containerProps={{
                className: "w-8 h-4",
              }}
              circleProps={{
                className: "before:hidden left-1 border-none w-[12px] h-[12px]",
              }}
            />
          </div> */}
          <div className="flex flex-row justify-center items-center ">
            <div
              className="cursor_pointer flex justify-center"
              onClick={() => {
                // dispatch(
                //   deleteUser({
                //     id: row?._id,
                //     onSuccess: (props) => {
                //       toast.success(props?.message);
                //       getData(searchQuery);
                //     },
                //   })
                // );
              }}
            >
              <Delete width={16} height={16} />
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          // const updatedValues = values;
          // console.log(updatedValues, "updatedValues");
          // dispatch(
          //   updateRoleById({
          //     updatedValues,
          //     onSuccess: (props) => {
          //       toast.success(props?.message);
          //     },
          //   })
          // );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="fixed h-full inset-0 z-50 flex justify-end">
              <div className="fixed inset-0 bg-[#07284B] opacity-50"></div>
              <div className="flex flex-col relative w-[50%] h-full bg-white shadow-lg overflow-y-auto scrollbar-hide">
                <div className="flex justify-between items-center bg-customBlue-mainBackground w-full h-[11%] p-6">
                  <h2 className="text-[20px] font-bold">Assigned Users</h2>
                  <button
                    onClick={toggleModal}
                    className="text-xl font-semibold p-1"
                  >
                    <Cross width={32} height={32} />
                  </button>
                </div>
                {/* Modal content */}
                <div className="w-full flex flex-col flex-auto h-screen justify-between py-3 px-6 ">
                  <div className={"w-full h-full flex flex-col space-y-3"}>
                    <div className="bg-[#F5F5F5] rounded-[12px] grid grid-cols-12 ">
                      <div className=" col-span-10 grid gap-2 pl-6 py-3">
                        <div className=" grid grid-cols-12 justify-between items-start  ">
                          <div className="flex justify-start col-span-4 md:col-span-12 text-[14px] font-semibold">
                            Role Name
                          </div>
                          <div className="flex justify-start col-span-8 md:col-span-12 text-[14px] font-extrabold">
                            {roleData?.role_name}
                          </div>
                        </div>
                        <div className=" grid grid-cols-12 justify-between items-start ">
                          <div className="flex justify-start col-span-4 text-[14px]">
                            Role Type
                          </div>
                          <div className="flex justify-start col-span-8 text-[14px] ">
                            {roleData?.role_type}
                          </div>
                        </div>
                        <div className=" grid grid-cols-12 justify-between items-start ">
                          <div className="flex justify-start col-span-4 text-[14px]">
                            Role Description
                          </div>
                          <div className="flex justify-start col-span-8 text-[14px] ">
                            {roleData?.role_description}
                          </div>
                        </div>
                      </div>
                      <div className="col-span-2 flex flex-col justify-center items-center ">
                        <span className="text-[32px] font-medium p-0">
                          {totalAdmin?.data?.length}
                        </span>
                        <span className="text[14px] font-medium">Users</span>
                      </div>
                    </div>
                    {/* Data Table */}
                    <div className="flex flex-col flex-auto  justify-between  px-0 overflow-y-auto scrollbar-hide">
                      <DataTable
                        columns={columns}
                        data={totalAdmin?.data}
                        customStyles={{
                          headCells: {
                            style: {
                              backgroundColor: "#DDF1FE",
                              color: "Black",
                              fontWeight: "bold",
                              fontSize: "14px",
                            },
                          },
                        }}
                        sortIcon={<SortIcon />}
                        noDataComponent={
                          <div className="h-full py-10">
                            <NoRecord
                              title_1={"There are no records to display"}
                              title_2={""}
                            />
                          </div>
                        }
                      />
                    </div>
                  </div>

                  {/* buttons */}
                  <div className="w-full flex justify-end space-x-4 h-[48px] ">
                    <button
                      className="bg-black text-white px-4 text-[18px] py-2 font-bold rounded-lg min-w-[32%] h-full"
                      onClick={(e) => {
                        e.preventDefault();
                        resetForm();
                      }}
                    >
                      Reset
                    </button>
                    <button
                      className="text-white px-4 py-2 rounded-md bg-WildWatermelon-button w-[32%] h-full font-bold"
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export { AssignedUsers };
