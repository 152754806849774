/**
 * Navigation Bar Components
 * @format
 */

import React, { useState } from "react";
import {
  selectBeneficiaryDetailCurrentPage,
  selectBeneficiaryDetailDataPerPage,
  selectDistricId,
  selectFilter,
  selectStateId,
} from "../selector";
import { useDispatch, useSelector } from "react-redux";
import {
  allBeneficiaryDetailListByStatus,
  setBeneficiaryStatus,
} from "../slice";
import {
  selectScheduleCampCurrentPage,
  selectScheduleCampFilter,
  selectScheduleCampRespPerPage,
  selectScheduleCampSearchQuery,
} from "../../camp_management/selector";
import { setScheduleCampFilter } from "../../camp_management/slice";

const NavigationBar = ({
  statuses,
  getData,
  beneficiaryDetailToggle,
  barName,
}) => {
  const dispatch = useDispatch();
  const [activeStatus, setActiveStatus] = useState(0);
  const currentPage = useSelector(selectBeneficiaryDetailCurrentPage);
  const limit = useSelector(selectBeneficiaryDetailDataPerPage);
  const districtId = useSelector(selectDistricId);
  const stateId = useSelector(selectStateId);
  const filter = useSelector(selectFilter);
  const scheduleCampCurrentPage = useSelector(selectScheduleCampCurrentPage);
  const scheduleCampPerPage = useSelector(selectScheduleCampRespPerPage);
  const scheduleCampSearchQuery = useSelector(selectScheduleCampSearchQuery);

  const handleStatusClick = async (index) => {
    try {
      // Get the status string and trim it to get the status name
      const statusString = statuses[index];
      const statusName = statusString.split("(")[0].trim();

      // Set the active status index
      setActiveStatus(index);

      if (barName === "beneficiaryVerificationScreen") {
        // Update the beneficiary status
        dispatch(setBeneficiaryStatus(statusName === "All" ? "" : statusName));

        // Call the API with the updated status
        getData(
          "",
          stateId,
          districtId,
          statusName === "All" ? "" : statusName,
          currentPage,
          limit,
          filter
        );
      }

      if (barName === "scheduleCamp") {
        const getStatusName = (statusName) => {
          if (statusName === "Overview") {
            return "";
          } else if (statusName === "Upcoming Camps") {
            return "upcoming";
          } else if (statusName === "Past camps") {
            return "past";
          }
        };

        const scheduleCampFilter = getStatusName(statusName);

        dispatch(setScheduleCampFilter(scheduleCampFilter));
        getData(
          scheduleCampSearchQuery,
          scheduleCampFilter,
          scheduleCampCurrentPage,
          scheduleCampPerPage
        );
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  return (
    <div className="container p-0 text-black h-[30px] w-full min-w-full">
      <div
        className={`container p-0 flex flex-row justify-start items-center w-full h-full ${
          barName === "scheduleCamp"
            ? "border-none"
            : "border-b  border-customBlue-border"
        }`}
      >
        {statuses.map((status, index) => {
          const [statusName, statusCount] = status.split("(");
          return (
            <button
              key={index}
              onClick={() => handleStatusClick(index)}
              className={`mr-8 h-full text-[16px] p-navigation leading-4 flex align-center justify-center whitespace-nowrap ${
                activeStatus === index
                  ? `text-black font-[600] border-b-2 ${
                      barName === "scheduleCamp"
                        ? "border-customBlue-activeBorder"
                        : " border-customBlue-activeBorder"
                    }`
                  : "text-black"
              }`}
            >
              {statusName}
              <span className="ml-2 text-[#333333] flex align-center justify-center leading-none font-[600] text-[10px] bg-[#E6E6E6] rounded-lg py-[4px] px-[8px]">
                {statusCount?.slice(0, -1) || ""}
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default NavigationBar;
