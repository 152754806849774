/**
 * Beneficiary Verification Page
 * @flow
 * @format
 */

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HeaderScreen, Sidebar } from "../../../components";
import NavigationBar from "../components/navigationBar";
import BeneficiaryAllDetail from "../components/beneficiaryAllDetail";
import BeneficiaryList from "../components/beneficiaryList";
import {
  allBeneficiaryDetailList,
  getBeneficiaryDetailById,
  getBeneficiaryProcessDetails,
} from "../slice";
import {
  selectAllBeneficiaryDetailDataList,
  selectBeneficiaryDetailCurrentPage,
  selectBeneficiaryDetailDataPerPage,
  selectBeneficiaryDetails,
  selectBeneficiaryProcessDetail,
  selectDistricId,
  selectFilter,
  selectStateId,
} from "../selector";
import BeneficiaryJourney from "../components/beneficiaryJourney";
import PersonalDetails from "../components/personalDetails";
import BeneficiaryDetail from "../components/beneficiaryDetail";
import Pagination from "../../../components/pagination";
import { constants, manipulateDateAndTime } from "../../../utils";
import { Images } from "../../../constants";
import { NoRecord } from "../../../components/noRecords";
import BeneficiaryVerificationPart from "../components/beneficiaryVerificationForm";
import { FabricationComponent } from "../components/fabricationCard";
import { AssessmentComponent } from "../components/assessmentCard";
import { DistributionComponent } from "../components/distributionCard";

function BeneficiaryVerificationsScreen() {
  const dispatch = useDispatch();
  const [beneficiaryDetailToggle, setBeneficiaryDetailToggle] = useState(false);
  const [dateTime, setDateTime] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const scrollableRef = useRef(null);

  const {
    data: users = [],
    count,
    totalBeneficiary,
    verified,
    rejected,
    pending,
    currentPage: currentPageApi,
  } = useSelector(selectAllBeneficiaryDetailDataList) || {};
  const { beneficiaryDetails, campData, beneficiaryJourney } =
    useSelector(selectBeneficiaryProcessDetail) || {};
  const singleBeneficiary = useSelector(selectBeneficiaryDetails) || {};
  const currentPage = useSelector(selectBeneficiaryDetailCurrentPage);
  const limit = useSelector(selectBeneficiaryDetailDataPerPage);
  const districtId = useSelector(selectDistricId);
  const stateId = useSelector(selectStateId);
  const filter = useSelector(selectFilter);

  // format the verification data and status
  const verification_data =
    beneficiaryJourney?.stages?.verification?.data?.reduce(
      (latest, current) => {
        return new Date(latest.createdAt) > new Date(current.createdAt)
          ? latest
          : current;
      },
      beneficiaryJourney?.stages?.verification?.data[0] // Correct reference here
    );

  const latest_data = {
    status: verification_data?.status_id?.status_name,
    created_by_name: verification_data?.created_by?.name,
    member_id: verification_data?.created_by?.member_id,
    call_status: verification_data?.data[0]?.call_status,
    notes: verification_data?.data[0]?.notes,
    createdAt: manipulateDateAndTime(verification_data?.createdAt),
  };
  console.log({ latest_data });

  // Api calling functions
  const getBeneficiaryData = (
    searchData,
    stateId,
    districtId,
    status,
    page,
    perPage,
    filter
  ) => {
    dispatch(
      allBeneficiaryDetailList({
        search: searchData,
        stateId,
        districtId,
        status,
        page: page,
        perPage: perPage,
        filter,
        onSuccess: (props) => {
          setBeneficiaryDetailToggle(false);
          console.log("Single Beneficiary loaded successfully");
        },
      })
    );
  };

  const getBeneficiaryDetailByUserId = (userId) => {
    dispatch(
      getBeneficiaryDetailById({
        id: userId,
        onSuccess: (props) => {
          setBeneficiaryDetailToggle(true);
          console.log("Role loaded successfully");
        },
      })
    );
  };

  const getBeneficiaryProcessDetailByBeneficiaryId = (id) => {
    dispatch(
      getBeneficiaryProcessDetails({
        id,
        onSuccess: (props) => {
          console.log("Data process loaded successfully");
        },
      })
    );
  };

  //  journey Steps
  const steps = [
    {
      name: "Registration",
      descriptions: `Completed on ${dateTime.date}; ${dateTime.time}`,
    },
    {
      name: "Verification",
      component: (
        <BeneficiaryVerificationPart
          userData={singleBeneficiary}
          userVerificationData={latest_data}
        />
      ),
      descriptions: `${latest_data?.createdAt?.date}; ${
        latest_data?.createdAt?.time
      } By ${latest_data?.created_by_name} (Sai Connect ID ${
        latest_data?.member_id || ""
      })`,
    },
    {
      name: "Assessment",
      component: <AssessmentComponent />,
      descriptions: `Started on 23-01-2024; 11.25 AM`,
    },
    {
      name: "Fabrication",
      component: <FabricationComponent />,
      descriptions: `Started on 23-01-2024; 11.25 AM | Completed on 23-01-2024; 11.25 AM`,
    },
    {
      name: "Distribution",
      component: <DistributionComponent />,
      descriptions: `Started on 23-01-2024; 11.25 AM`,
    },
  ];

  useEffect(() => {
    if (
      scrollableRef.current &&
      typeof scrollableRef.current.scrollTo === "function"
    ) {
      scrollableRef.current.scrollTo(0, 0);
    } else if (singleBeneficiary?._id) {
      const result = manipulateDateAndTime(singleBeneficiary?.createdAt);
      setDateTime(result);
      getBeneficiaryProcessDetailByBeneficiaryId(singleBeneficiary?._id);
    }
  }, [singleBeneficiary]);

  useEffect(() => {
    getBeneficiaryData(
      searchQuery,
      stateId,
      districtId,
      "",
      currentPage,
      limit,
      filter
    );
  }, [searchQuery, currentPage, stateId, districtId, limit, filter]);

  return (
    <>
      <div className="h-full w-screen">
        <HeaderScreen />
        <div className="flex h-[calc(100%-80px)] w-[100%]">
          <Sidebar />

          <div className="container p-0 flex flex-col justify-between h-full bg-customBlue-mainBackground max-w-[calc(100%-87px)] w-[100%] overflow-auto">
            <div className="container  w-full max-w-full px-4 py-4 mt-4 flex items-center ">
              <div className="flex flex-row items-center justify-between font-bold text-[24px] title max-w-[42%] min-w-[42%]">
                <div>{constants.beneficiary_list}</div>
                <div>
                  <Pagination
                    name={"beneficiaryVerficationScreen"}
                    totalNumber={count}
                    dataPerPage={limit}
                    data={getBeneficiaryData}
                    currentPageApi={currentPageApi}
                  />
                </div>
              </div>
            </div>
            <div className="container p-0 flex w-full px-4  max-w-[42%] min-w-[42%]  ">
              <NavigationBar
                statuses={[
                  `All(${totalBeneficiary || 0})`,
                  `Verified(${verified || 0})`,
                  `Rejected(${rejected || 0})`,
                  `Pending(${pending || 0})`,
                ]}
                getData={getBeneficiaryData}
                beneficiaryDetailToggle={setBeneficiaryDetailToggle}
                barName="beneficiaryVerificationScreen"
              />
              <div className="col-span-6"></div>
            </div>

            <div className="container  min-h-[50%] flex-auto  flex flex-row justify-between gap-[24px] w-full p-4  pb-0 max-w-full">
              {/* User List with Search  and Filter */}
              {/* <div className="h-full w-[42%] max-h-[1530px] "> */}
              <BeneficiaryList
                users={users}
                setSearchQuery={setSearchQuery}
                getBeneficiaryDetailByUserId={getBeneficiaryDetailByUserId}
                beneficiaryStatus={true}
                completionTime={false}
                dateRange={true}
              />
              {/* </div> */}
              {/* User Details*/}
              {beneficiaryDetailToggle &&
              Object.keys(singleBeneficiary).length > 0 ? (
                singleBeneficiary?.status === "Verified" ? (
                  <div className="container p-0 h-full self-end w-[58%] pr-4">
                    <div
                      className="container h-full overflow-y-scroll scrollbar-hide space-y-6 flex flex-col bg-white px-6 py-4 max-w-full w-full rounded-xl"
                      ref={scrollableRef}
                    >
                      {/* Beneficiary Detail */}
                      <div className="container p-0 flex flex-col justify-between max-w-full w-full border-b-[2px] border-[#c1c1c1] border-dashed ">
                        <BeneficiaryDetail userData={singleBeneficiary} />
                      </div>

                      {/* Personal Detail */}
                      <div className="container p-0 flex flex-col justify-between max-w-full w-full  ">
                        <PersonalDetails userData={singleBeneficiary} />
                      </div>
                      {/* Beneficiary Journey */}
                      <div className="container p-0 flex flex-col justify-between items-center max-w-full w-full  ">
                        <BeneficiaryJourney
                          beneficiary={singleBeneficiary}
                          steps={steps}
                          beneficiaryJourney={beneficiaryJourney}
                          campData={campData}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="container h-full self-end pr-4 w-[58%]">
                    <BeneficiaryAllDetail
                      singleBeneficiary={singleBeneficiary}
                      data={getBeneficiaryData}
                      bySearch={false}
                    />
                  </div>
                )
              ) : (
                <div className="flex justify-center items-center container pr-4 pt-2 h-full self-end w-[58%] bg-white rounded-lg">
                  <NoRecord
                    title_1={"Select a Beneficiary to view"}
                    title_2={"Click on the Beneficiaries to view details"}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { BeneficiaryVerificationsScreen };
