/**
 * Running Camp Screen Page
 * @flow
 * @format
 */

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HeaderScreen, Sidebar } from "../../../components";
import NavigationBar from "../components/navigationBar";
import BeneficiaryList from "../components/beneficiaryList";
import {
  allBeneficiaryDetailList,
  getBeneficiaryDetailById,
  getBeneficiaryProcessDetails,
  setCurrCampSearch,
} from "../slice";
import {
  selectBeneficiaryDetails,
  selectBeneficiaryProcessDetail,
  selectCurrCampCurrentPage,
  selectCurrCampDataPerPage,
  selectCurrCampDistrictId,
  selectCurrCampFilter,
  selectCurrCampNavStatus,
  selectCurrCampSearch,
  selectCurrCampStateId,
} from "../selector";
import BeneficiaryJourney from "../components/beneficiaryJourney";
import PersonalDetails from "../components/personalDetails";
import BeneficiaryDetail from "../components/beneficiaryDetail";
import Pagination from "../../../components/pagination";
import { InfoIcon } from "../../../assets/icons/info";
import { CalendarNew } from "../../../assets/icons/Calender";
import { Clock } from "../../../assets/icons/Clock";
import { Images } from "../../../constants";
import { Switch } from "@material-tailwind/react";
import { getAllBeneficiariesOfCampByScheduleCampId } from "../../camp_management/slice";
import { selectAllBeneficiariesListForCamp } from "../../camp_management/selector";
import { manipulateDateAndTime } from "../../../utils";
import { FabricationComponent } from "../components/fabricationCard";
import BeneficiaryVerificationPart from "../components/beneficiaryVerificationForm";
import { AssessmentComponent } from "../components/assessmentCard";
import { DistributionComponent } from "../components/distributionCard";

function RunningCampScreen({ selectedCamp }) {
  const dispatch = useDispatch();
  const [beneficiaryDetailToggle, setBeneficiaryDetailToggle] = useState(false);
  const [dateTime, setDateTime] = useState({});
  const scrollableRef = useRef(null);

  const {
    data: beneficiaries = [],
    count,
    totalCampBeneficiary,
    currentPage: currentPageApi,
  } = useSelector(selectAllBeneficiariesListForCamp) || {};
  const { beneficiaryDetails, campData, beneficiaryJourney } =
    useSelector(selectBeneficiaryProcessDetail) || {};
  const singleBeneficiaryData = useSelector(selectBeneficiaryDetails) || {};
  const singleBeneficiary = beneficiaryDetails || {};
  const search = useSelector(selectCurrCampSearch);
  const currentPage = useSelector(selectCurrCampCurrentPage);
  const limit = useSelector(selectCurrCampDataPerPage);
  const districtId = useSelector(selectCurrCampDistrictId);
  const stateId = useSelector(selectCurrCampStateId);
  const filter = useSelector(selectCurrCampFilter);
  const navStatus = useSelector(selectCurrCampNavStatus);

  // format the verification data and status
  const verification_data =
    beneficiaryJourney?.stages?.verification?.data?.reduce(
      (latest, current) => {
        return new Date(latest.createdAt) > new Date(current.createdAt)
          ? latest
          : current;
      },
      beneficiaryJourney?.stages?.verification?.data[0] // Correct reference here
    );

  const latest_data = {
    status: verification_data?.status_id?.status_name,
    created_by_name: verification_data?.created_by?.name,
    member_id: verification_data?.created_by?.member_id,
    call_status: verification_data?.data[0]?.call_status,
    notes: verification_data?.data[0]?.notes,
    createdAt: manipulateDateAndTime(verification_data?.createdAt),
  };
  console.log({ latest_data });

  // Api calling functions
  const getBeneficiaryDataList = (
    searchData,
    stateId,
    districtId,
    status,
    page,
    perPage,
    filter
  ) => {
    dispatch(
      getAllBeneficiariesOfCampByScheduleCampId({
        // scheduled_camp_id: selectedCamp?._id,
        scheduled_camp_id: "66fe8e608447dfaa7bac738e",
        search: searchData,
        stateId,
        districtId,
        status,
        page: page,
        perPage: perPage,
        filter,
        onSuccess: (props) => {
          setBeneficiaryDetailToggle(false);
          console.log("Beneficiary loaded successfully");
        },
      })
    );
  };

  const getBeneficiaryDetailByUserId = (userId) => {
    dispatch(
      getBeneficiaryDetailById({
        // id: userId,
        id: "665a0b8be55943ba449603ef",
        onSuccess: (props) => {
          setBeneficiaryDetailToggle(true);
          console.log("loaded successfully");
        },
      })
    );
  };

  const getBeneficiaryProcessDetailByBeneficiaryId = (id) => {
    dispatch(
      getBeneficiaryProcessDetails({
        // id,
        id: "665a0b8be55943ba449603ef",
        onSuccess: (props) => {
          console.log("Data process loaded successfully");
        },
      })
    );
  };

  //  journey Steps
  const steps = [
    {
      name: "Registration",
      descriptions: `Completed on ${dateTime.date}; ${dateTime.time}`,
    },
    {
      name: "Verification",
      component: (
        <BeneficiaryVerificationPart
          userData={singleBeneficiaryData}
          userVerificationData={latest_data}
        />
      ),
      descriptions: `${latest_data?.createdAt?.date}; ${
        latest_data?.createdAt?.time
      } By ${latest_data?.created_by_name} (Sai Connect ID ${
        latest_data?.member_id || ""
      })`,
    },
    {
      name: "Assessment",
      component: <AssessmentComponent />,
      descriptions: `Started on 23-01-2024; 11.25 AM`,
    },
    {
      name: "Fabrication",
      component: <FabricationComponent />,
      descriptions: `Started on 23-01-2024; 11.25 AM | Completed on 23-01-2024; 11.25 AM`,
    },
    {
      name: "Distribution",
      component: <DistributionComponent />,
      descriptions: `Started on 23-01-2024; 11.25 AM`,
    },
  ];

  useEffect(() => {
    if (
      scrollableRef.current &&
      typeof scrollableRef.current.scrollTo === "function"
    ) {
      scrollableRef.current.scrollTo(0, 0);
    } else if (singleBeneficiaryData?._id) {
      const result = manipulateDateAndTime(singleBeneficiaryData?.createdAt);
      setDateTime(result);
      getBeneficiaryProcessDetailByBeneficiaryId(singleBeneficiaryData?._id);
    }
  }, [singleBeneficiaryData]);

  useEffect(() => {
    getBeneficiaryDataList(
      search,
      stateId,
      districtId,
      navStatus,
      currentPage,
      limit,
      filter
    );
  }, [search, currentPage, stateId, districtId, limit, filter]);

  return (
    <>
      <div className="h-full w-screen">
        <HeaderScreen />
        <div className="flex h-[calc(100%-80px)] w-[100%]">
          <Sidebar />

          <div className="container p-0 flex flex-col justify-between h-full bg-customBlue-mainBackground max-w-[calc(100%-87px)] w-[100%] overflow-auto">
            {/* Camp Details  */}
            <div className="w-full p-4 pb-0">
              <div className="flex gap-3 justify-between items-center flex-wrap p-4 bg-[#E5F4FE] rounded-xl border-[#54BBFF] border">
                <div className="flex gap-3 items-center">
                  <div className="font-bold text-[24px] title">
                    Assessment Camp
                  </div>
                  <span className="text-gray-text-divider">|</span>
                  <div className="font-bold text-[20px] title">
                    Camp Code: <span className="text-[#FF5976]">EXY1234D</span>
                  </div>
                  <span className="text-gray-text-divider">|</span>
                  <div className="font-semibold  flex items-start gap-[1rem]">
                    <div className="font-semibold  flex items-start flex-col gap-[0.3rem]">
                      <div className="font-semibold  flex items-center gap-1.5">
                        <CalendarNew />
                        <div className=" ">April 20, 2024</div>
                      </div>
                      <div className="font-semibold  flex items-center gap-1.5">
                        <Clock width={16} height={16} />
                        <div className="">9.00 Am - 6.00 Pm</div>
                      </div>
                    </div>
                    <InfoIcon />
                  </div>
                </div>
                <div className="flex gap-3 items-center p-2 bg-[white] rounded-xl border-[#C0E6FF] border">
                  <div className="font-semibold text-[18px] title">
                    Step:{" "}
                    <span className="font-bold text-[#FF5976]">Screening</span>
                  </div>
                  <div className="font-semibold text-[18px] title">
                    Counter No:
                    <span className="font-bold text-[#FF5976]">4</span>
                  </div>
                  <div className="font-bold text-[18px] title bg-[#D6FFE4] text-center py-1 px-3 rounded-xl text-[#007C2A]">
                    4<div className="text-[12px] uppercase">Completed</div>
                  </div>
                  <div className="font-bold text-[16px] title bg-[#DDF1FE] text-center py-1 px-3 rounded-xl text-[#256CD0]">
                    34<div className="text-[12px] uppercase">waiting</div>
                  </div>
                </div>
              </div>
            </div>
            {/* Beneficiary Data  */}
            <div className="container  w-full max-w-full px-4 py-0 mt-0 flex flex-row justify-between items-center ">
              <div className="flex flex-row items-center justify-between font-bold text-[24px] title max-w-[42%] min-w-[42%]">
                <div>Beneficiaries</div>
                <div>
                  <Pagination
                    name={"runningCampScreen"}
                    totalNumber={5}
                    dataPerPage={limit}
                    data={getBeneficiaryDataList}
                    currentPageApi={currentPageApi}
                  />
                </div>
              </div>
              <div className="flex flex-row space-x-2">
                <span>Unavailable</span>
                <Switch
                  // id={row.id}
                  ripple={false}
                  checked={true}
                  onChange={() => {}}
                  onClick={() => {}}
                  className="h-full w-full checked:bg-[#FE5976]"
                  containerProps={{
                    className: "w-8 h-4",
                  }}
                  circleProps={{
                    className:
                      "before:hidden left-1 border-none w-[12px] h-[12px]",
                  }}
                />
                <span>Available</span>
              </div>
            </div>
            <div className="container p-0 flex w-full px-4  max-w-[42%] min-w-[42%]  ">
              <NavigationBar
                statuses={[
                  `Screened by me(${0})`,
                  `Waiting for me(${0})`,
                  `Waiting at other desk(${0})`,
                ]}
                getData={getBeneficiaryDataList}
                beneficiaryDetailToggle={setBeneficiaryDetailToggle}
                barName="beneficiaryVerificationScreen"
              />
              <div className="col-span-6"></div>
            </div>

            <div className="container  min-h-[50%] flex-auto  flex flex-row justify-between gap-[24px] w-full p-4  pb-0 max-w-full">
              {/* User List with Search  and Filter */}
              <BeneficiaryList
                users={beneficiaries}
                setSearchQuery={setCurrCampSearch}
                getBeneficiaryDetailByUserId={getBeneficiaryDetailByUserId}
                beneficiaryStatus={false}
                completionTime={true}
                dateRange={false}
              />
              {/* User Details*/}
              {beneficiaryDetailToggle &&
              Object.keys(singleBeneficiaryData).length > 0 ? (
                singleBeneficiaryData?.status === "Verified" ? (
                  <div className="container p-0 h-full self-end w-[58%] pr-4">
                    <div
                      className="container h-full overflow-y-scroll scrollbar-hide space-y-6 flex flex-col bg-white px-6 py-4 max-w-full w-full rounded-xl"
                      ref={scrollableRef}
                    >
                      {/* Beneficiary Detail */}
                      <div className="container p-0 flex flex-col justify-between max-w-full w-full border-b-[2px] border-[#c1c1c1] border-dashed ">
                        <BeneficiaryDetail userData={singleBeneficiary} />
                      </div>

                      {/* Personal Detail */}
                      <div className="container p-0 flex flex-col justify-between max-w-full w-full  ">
                        <PersonalDetails userData={singleBeneficiary} />
                      </div>
                      {/* Beneficiary Journey */}
                      <div className="container p-0 flex flex-col justify-between items-center max-w-full w-full  ">
                        <BeneficiaryJourney
                          beneficiary={singleBeneficiary}
                          steps={steps}
                          beneficiaryJourney={beneficiaryJourney}
                          campData={campData}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="container h-full self-end pr-4 w-[58%]">
                    Tis Beneficiary is not Verifyed
                  </div>
                )
              ) : (
                <div className="flex justify-center items-center container pr-4 pt-2 h-full self-end w-[58%] bg-white rounded-lg">
                  <div className="px-4 text-center">
                    <img
                      src={Images.noRecord}
                      alt="Logo"
                      className="max-w-[25rem]"
                    />
                    <div className="text-[1.4rem] font-bold mt-5">
                      Select a Beneficiary to view
                    </div>
                    <div className="text-[1.15rem]">
                      Click on the Beneficiaries to view details
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { RunningCampScreen };
