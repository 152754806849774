import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Location } from "../../../assets/icons/Location";
import { Date } from "../../../assets/icons/Date";
import { Clock } from "../../../assets/icons/Clock";
import { MultipleUser } from "../../../assets/icons/MultipleUser";
import { RightArrow } from "../../../assets/icons/RightArrow";
import { LeftArrow } from "../../../assets/icons/LeftArrow";
import { Images } from "../../../constants";

const UpcomingCampsCard = (props) => {
  const [currentCampIndex, setCurrentCampIndex] = useState(0);
  // const { myUpcomingCampData: upcoming_camps } = props;

  // Example camp data
  const upcoming_camps = [
    {
      title: "Assessment Camp",
      schoolName: "Aradhya High School",
      address: "Beside Anand Temple, Dist-Tiruvallur.",
      stage: "Check-in",
      counterNo: 2,
      dates: "20th - 21st, April , 2024",
      time: "9.00 Am - 06.00 Pm",
      beneficiaries: 150,
      contactPerson: "Vijaya Kumar SR",
      contactNumber: "93654 98533",
    },
    {
      title: "Assessment Camp",
      schoolName: "Aradhya High School",
      address: "Beside Anand Temple, Dist-Tiruvallur.",
      stage: "Check-in",
      counterNo: 2,
      dates: "20th - 21st, April , 2024",
      time: "9.00 Am - 06.00 Pm",
      beneficiaries: 150,
      contactPerson: "Vijaya Kumar SR",
      contactNumber: "93654 98533",
    },
  ];

  const upcomingTotalCamps = upcoming_camps.length || [];

  const handleNextCamp = () => {
    setCurrentCampIndex((prevIndex) =>
      prevIndex < upcomingTotalCamps - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const handlePrevCamp = () => {
    setCurrentCampIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  const currentCamp = upcoming_camps[currentCampIndex];

  return (
    <div className="w-full bg-white rounded-xl p-6 border-2 border-gray-100">
      <div className="flex flex-row md:flex-wrap space-x-4 justify-between items-center mb-2 gap-2">
        <h3 className="text-[20px] font-bold">My Upcoming Camps</h3>
        {upcomingTotalCamps > 0 ? (
          <div className="text-sm whitespace-nowrap">
            <button
              onClick={handlePrevCamp}
              disabled={currentCampIndex === 0}
              className=" font-bold"
            >
              <LeftArrow width={6} height={11} />
            </button>
            <span className="px-2 text-[14px]">{`${
              currentCampIndex + 1
            } / ${upcomingTotalCamps}`}</span>
            <button
              onClick={handleNextCamp}
              disabled={currentCampIndex === upcomingTotalCamps - 1}
              className=" font-bold"
            >
              <RightArrow width={6} height={11} />
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
      {upcomingTotalCamps > 0 ? (
        <div>
          <div className="py-0 border-b ">
            <h4 className="text-[18px] font-semibold mb-2">
              {currentCamp.title}
            </h4>
          </div>
          <div className="flex flex-row justify-between items-center text-sm text-gray-700 mb-2 py-2">
            <strong>{currentCamp.schoolName}</strong>
            <Link href="#" className="text-blue-500 underline">
              View Map
            </Link>
          </div>
          <div className="flex flex-row space-x-2 items-center">
            <Location height={13} width={10} fillColor={"#000000"} />
            <p className="text-sm">{currentCamp.address}</p>
          </div>
          <div className="flex flex-row flex-wrap justify-start items-center bg-gray-100 p-0 rounded-xl my-4 text-[14px]">
            <span className="p-2 flex-1">
              Stage: <strong>{currentCamp.stage}</strong>
            </span>
            <span className="p-2 flex-1">
              Counter No: <strong>{currentCamp.counterNo}</strong>
            </span>
          </div>
          <div className="text-sm text-gray-700">
            <div className="flex items-center mb-2">
              <span role="img" aria-label="calendar" className="mr-2">
                <Date width={18} height={18} />
              </span>
              {currentCamp.dates}
            </div>
            <div className="flex items-center mb-2">
              <span role="img" aria-label="clock" className="mr-2">
                <Clock width={16} height={17} />
              </span>
              {currentCamp.time}
            </div>
            <div className="flex items-center mb-4">
              <span role="img" aria-label="users" className="mr-2">
                <MultipleUser width={18} height={20} />
              </span>
              {currentCamp.beneficiaries} Beneficiaries
            </div>
            <div>
              <strong className="text-[14px] font-bold underline ">
                Contact Person
              </strong>
              <p>
                {currentCamp.contactPerson},{" "}
                <span className="underline text-[14px] font-bold">
                  {currentCamp.contactNumber}
                </span>
              </p>
            </div>
          </div>{" "}
        </div>
      ) : (
        <div className="flex flex-col justify-start items-center space-y-6 ">
          <img
            src={Images.upcomingCampImage}
            alt="Upcoming Camp"
            className="w-full h-full "
          />
          <span className="text-center text-[1rem] font-medium">
            There isn’t any Upcoming Camp(s) for you!
          </span>
        </div>
      )}

      <button className="w-full bg-blue-500 text-white font-semibold py-2 rounded-xl mt-6 text-[1rem]">
        {upcomingTotalCamps > 0 ? "START" : "SCHEDULE CAMP"}
      </button>
    </div>
  );
};

export default UpcomingCampsCard;
