/**
 * List Beneficiary of Camp Screen Page
 * @flow
 * @format
 */

import React, { useEffect, useState, useRef } from "react";
import { Cross } from "../../../assets/icons/Cross";
import { SearchIcon } from "../../../assets/icons/SearchIcon";
import DataTable from "react-data-table-component";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { selectAllAssinBeneficiayDataList } from "../selector";
import { getAllAssignBeneficiariesOfCamp } from "../slice";
import { NoRecord } from "../../../components/noRecords";
import { Delete } from "../../../assets/icons/Delete";
import Pagination from "../../../components/pagination";

const ListCampBeneficiary = ({ toggleModal, rowData, getData }) => {
  const dispatch = useDispatch();
  const { data: beneficiaryListData = [], pagination } = useSelector(
    selectAllAssinBeneficiayDataList
  );

  const SortIcon = ({ isSorted, isSortedDesc }) => {
    if (!isSorted) {
      return <span className="ml-4 sort-icon">&uarr;&darr;</span>; // Default unsorted icon
    } else if (isSortedDesc) {
      return <span className="ml-4 sort-icon">&darr;</span>; // Descending sort icon
    } else {
      return <span className="ml-4 sort-icon">&uarr;</span>; // Ascending sort icon
    }
  };

  const columnStyle = {
    fontSize: "14px",
    fontWeight: "normal",
    // border: "2px solid black",
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row?.name,
      style: columnStyle,
      sortable: true,
      backgroundColor: "black",
    },
    {
      name: "Age",
      selector: (row) => row?.age,
      style: columnStyle,
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "Gender",
      selector: (row) => row?.gender,
      style: columnStyle,
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "Address",
      selector: (row) => row?.address_1,
      style: columnStyle,
      sortable: true,
      // maxWidth: "150px",
      cell: (row) => (
        <>
          <div className="w-full">
            {row?.district},{row?.state}, Pincode - {row?.pin_code}
          </div>
        </>
      ),
    },
    {
      name: "Contact",
      selector: (row) => row?.phone,
      style: columnStyle,
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: (
        <div className="flex flex-col item-center">
          <span className="">
            Registered on <SortIcon isSorted={false} />
          </span>
          <span className="">
            Verified on <SortIcon isSorted={false} />
          </span>
        </div>
      ),
      selector: (row) => row?.from_time,
      style: columnStyle,
      center: true,
      // sortable: true,
      minWidth: "200px",
      cell: (row) => (
        <>
          <div className="w-full flex flex-col item-center  space-y-2">
            <span className="">
              {moment(row?.registered_on).format("DD-MM-YYYY")}
            </span>
            <span className="">
              {moment(row?.verification_date).format("DD-MM-YYYY")}
            </span>
          </div>
        </>
      ),
    },
    {
      name: "Reg No.",
      selector: (row) => row?.registration_number,
      style: columnStyle,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "",
      selector: (row) => row?.registration_number,
      style: columnStyle,
      sortable: true,
      minWidth: "70px",
      maxWidth: "70px",
      cell: (row) => (
        <>
          <div className="w-full flex flex-col item-center justify-center">
            <span className="">
              <Delete width={16} height={16} />
            </span>
          </div>
        </>
      ),
    },
  ];

  const getBeneficiayData = (beneficiary_ids) => {
    dispatch(
      getAllAssignBeneficiariesOfCamp({ beneficiary_id: beneficiary_ids })
    );
  };
  useEffect(() => {
    if (rowData) {
      getBeneficiayData(rowData?.beneficiary_id);
      // getBeneficiayData(["66f5950ab591d439458c85f9"]);
    }
  }, []);
  return (
    <>
      <div className="fixed h-full inset-0 z-50 flex justify-end">
        <div className="fixed inset-0 bg-[#07284B] opacity-50"></div>
        <div className="flex flex-col relative w-[80%] h-full bg-white shadow-lg overflow-y-auto scrollbar-hide">
          <div className="flex justify-between items-center bg-customBlue-mainBackground w-full h-[11%] p-6">
            <h2 className="text-[20px] font-bold">Beneficiary List</h2>
            <button onClick={toggleModal} className="text-xl font-semibold">
              <Cross width={32} height={32} />
            </button>
          </div>
          {/* Modal content */}
          <div className="w-full flex flex-col flex-auto justify-start space-y-4 py-6 px-8 ">
            <div className="w-full">
              <p>
                <strong>Criteria</strong> : {rowData?.beneficiaryText}
              </p>
            </div>
            {/* Search Bar */}
            <dvi className="w-full flex flex-row justify-between items-center">
              <div className="container p-0 flex justify-between items-center w-[60%] ">
                <div className="relative flex-1 rounded-lg h-full ">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none h-full">
                    <SearchIcon width={20} height={20} />
                  </div>
                  <input
                    type="search"
                    id="default-search"
                    className="block w-full h-[100%] px-3 py-3 font-DM ps-10 text-sm text-gray-text-100 rounded-lg border border-gray-border-100"
                    placeholder="Search by mobile number/name"
                    onChange={(event) => {
                      // dispatch(setScheduleCampSearchQuery(event.target.value));
                      // setSearchQuery(event.target.value);
                    }}
                  />
                </div>
              </div>
              {/* pagination */}
              <div>
                <Pagination
                  name={"queryBeneficiary"}
                  totalNumber={pagination?.totalRecords}
                  dataPerPage={30}
                  data={getBeneficiayData}
                  currentPageApi={1}
                />
              </div>
            </dvi>
            <div className="">
              <div className=" overflow-y-auto scrollbar-hide theme-table">
                <DataTable
                  columns={columns}
                  data={beneficiaryListData}
                  customStyles={{
                    headCells: {
                      style: {
                        backgroundColor: "#DDF1FE",
                        color: "Black",
                        fontWeight: "bold",
                        fontSize: "14px",
                      },
                    },
                  }}
                  sortIcon={<SortIcon />}
                  noDataComponent={
                    <div className="h-full py-16">
                      <NoRecord
                        title_1={"There are no records to display"}
                        title_2={""}
                      />
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ListCampBeneficiary };
