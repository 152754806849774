/**
 * Role Management sagas
 * @format
 */

import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import { createLoader } from "../common";
import { endPoints, httpRequest } from "../../services";
import {
  allRoleList,
  createRole,
  deleteRole,
  getRoleById,
  getRoleByIdWithUser,
  setAllRoleDataList,
  setRole,
  setRoleWithUser,
  updateRoleById,
} from "./slice";

/**
 * Get all Role list
 * @param {*}
 */
function* allRoleListSaga(action) {
  try {
    const { search, page, perPage, onSuccess } = action.payload;
    const response = yield httpRequest.post(endPoints.allRolesList, {
      search,
      page,
      responsePerPage: perPage,
    });
    yield onSuccess({ message: response?.message });
    yield put(setAllRoleDataList(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Create Role with fields
 * @param {*} *fields
 */
function* createRoleSaga(action) {
  try {
    const { values, onSuccess } = action.payload;
    const { role_name, role_type, role_description } = values;
    const response = yield httpRequest.post(endPoints.createRole, {
      role_name,
      role_type,
      role_description,
    });

    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Update Role By Id
 * @param {*} *fields
 */
function* updateRoleByIdSaga(action) {
  try {
    const { updatedValues, onSuccess } = action.payload;
    const {
      id,
      role_name,
      role_type,
      role_description,
      permission,
      is_active,
      is_deleted,
    } = updatedValues;
    const response = yield httpRequest.put(endPoints.updateRoleById, {
      id,
      role_name,
      role_type,
      role_description,
      permission,
      is_active,
      is_deleted,
    });

    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get Role By Id With Permission
 * @param {*} Id
 */
function* getRoleByIdPermissionSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield call(
      httpRequest.get,
      `${endPoints.getRoleWithPermission}/${id}`
    );

    yield onSuccess({ message: response?.message });
    yield put(setRole(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get Role By Id  With Assign User
 * @param {*} Id
 */
function* getRoleByIdAssignUserSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield call(
      httpRequest.get,
      `${endPoints.getRoleWithAdminList}/${id}`
    );

    yield onSuccess({ message: response?.message });
    yield put(setRoleWithUser(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Delete Role
 * @param {*} Id
 */
function* deleteRoleSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield call(
      httpRequest.delete,
      `${endPoints.deleteRole}/${id}`
    );

    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

export function* roleManagementSaga() {
  yield takeLatest(allRoleList, allRoleListSaga);
  yield takeLatest(createRole, createRoleSaga);
  yield takeLatest(updateRoleById, updateRoleByIdSaga);
  yield takeLatest(getRoleById, getRoleByIdPermissionSaga);
  yield takeLatest(getRoleByIdWithUser, getRoleByIdAssignUserSaga);
  yield takeLatest(deleteRole, deleteRoleSaga);
}
