/**
 * SideBAr Component
 * @format
 */

import React, { useState } from "react";
import {
  Sidebar as ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  ProSidebarProvider,
} from "react-pro-sidebar";
import { ListIcon } from "./sidebarIcons/list";
import { Link, useLocation } from "react-router-dom";
import { Tent } from "./sidebarIcons/tent";
import { Calendar } from "./sidebarIcons/calendar";
import { Setting } from "./sidebarIcons/settings";
import { Driver } from "./sidebarIcons/driver";
import { Fame } from "./sidebarIcons/fame";
import { SpeechBubble } from "./sidebarIcons/speech-bubble";
import { Logout } from "../../assets/icons/Logout";
import { useDispatch, useSelector } from "react-redux";
import { logoutApp, selectUserAllPermissions } from "../../pages/common";
import { RoleIcon } from "../../assets/icons/RoleIcon";

function Sidebar() {
  const location = useLocation();
  const dispatch = useDispatch();
  const userPermissions = useSelector(selectUserAllPermissions) || {};
  console.log(userPermissions);

  const [collapsed, setCollapsed] = useState(true);
  // Determine if any of the submenu items are active
  const isSubMenuActive = [
    "/campOverview",
    "/myCampList",
    "/campCentersList",
    "/scheduleCampsList",
  ].includes(location.pathname);
  //   const { collapsed, collapseSidebar } = useProSidebar();
  //   const [isCollapsed, setIsCollapsed] = useState(true);

  const [width, setWidth] = useState("250px");
  //   const [open, setOpen] = useState("");

  //   const handleOpenSubMenu = (key) => {
  //     if (open === key) {
  //       setOpen("");
  //     } else {
  //       setOpen(key);
  //     }
  //   };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
    if (collapsed) {
      setWidth("87px");
    } else if (!collapsed) {
      setWidth("250px");
    }
  };

  return (
    <div className={`grid h-full  min-w-[${width}]`}>
      <ProSidebarProvider>
        <ProSidebar
          defaultCollapsed={collapsed}
          backgroundColor={"#DDF1FE"}
          collapsedWidth="87px"
          rootStyles={{ borderRight: "none" }}
          className="max-h-[calc(100vh-80px)] scrollbar-hide overflow-auto"
        >
          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                const commonStyles = {
                  height: "60px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderLeft: "3px solid #DDF1FE",
                };

                const activeStyles = {
                  backgroundColor: "#F4FBFF",
                  borderLeft: "3px solid #FF5976",
                };

                const hoverStyles = {
                  "&:hover": {
                    backgroundColor: "#F4FBFF",
                  },
                };

                return {
                  ...commonStyles,
                  ...(active && activeStyles),
                  ...hoverStyles,
                };
              },
              subMenuContent: () => {
                return {
                  backgroundColor: "#F4FBFF",
                  color: "black",
                };
              },
            }}
          >
            <div className="h-[20px]"></div>
            <Link to="/" replace={true}>
              <MenuItem
                rootStyles={{ margin: "0px", textAlign: "center" }}
                active={location.pathname === "/"}
              >
                <div className="flex flex-col  items-center ml-[7px] text-[#07284B] font-semibold">
                  <ListIcon width={24} height={24} />
                  <span className="text-[11px] mt-1 text[#21272A]">
                    Dashboard
                  </span>
                </div>
              </MenuItem>
            </Link>

            {/* {userPermissions?.View_Beneficiary_List === true && ( */}
            <Link to="/beneficiaryVerification" replace={true}>
              <MenuItem
                active={location.pathname === "/beneficiaryVerification"}
              >
                <div className="flex flex-col  items-center ml-[7px] text-[#07284B] font-semibold">
                  <Fame width={24} height={24} />
                  <span className="text-[11px] mt-1 text[#21272A]">
                    Beneficiaries
                  </span>
                  {/* {!collapsed && <div>Beneficiaries</div>} */}
                </div>
              </MenuItem>
            </Link>
            {/* )} */}

            <SubMenu
              label={
                <div className="flex flex-col items-center">
                  <Tent width={24} height={24} />
                  <span className="text-[11px] mt-1 text[#21272A]">Camps</span>
                </div>
              }
              icon={null}
              active={isSubMenuActive}
            >
              {/* {userPermissions?.View_Camp_Overview === true && ( */}
              <Link to="/campOverview" replace={true}>
                <MenuItem
                  active={location.pathname === "/campOverview"}
                  rootStyles={{
                    margin: "0px",
                    borderBottom: "2px solid #E7E7E7",
                    color: "#07284B",
                  }}
                >
                  <div className="pl-3">Camp Overview</div>
                </MenuItem>
              </Link>
              {/* )} */}

              <Link to="/myCampList" replace={true}>
                <MenuItem
                  active={location.pathname === "/myCampList"}
                  rootStyles={{
                    margin: "0px",
                    borderBottom: "2px solid #E7E7E7",
                    color: "#07284B",
                  }}
                >
                  <div className="pl-3">My Camps</div>
                </MenuItem>
              </Link>

              {/* {userPermissions?.View_Camp_Centres === true && ( */}
              <Link to="/campCentersList" replace={true}>
                <MenuItem
                  active={location.pathname === "/campCentersList"}
                  rootStyles={{
                    margin: "0px",
                    borderBottom: "2px solid #E7E7E7",
                    color: "#07284B",
                  }}
                >
                  <div className="pl-3">Camp Centres</div>
                </MenuItem>
              </Link>
              {/* )} */}

              {/* {userPermissions?.View_Scheduled_Camps === true && ( */}
              <Link to="/scheduleCampsList" replace={true}>
                <MenuItem active={location.pathname === "/scheduleCampsList"}>
                  <div className="pl-3">Schedule Camp</div>
                </MenuItem>
              </Link>
              {/* )} */}
            </SubMenu>

            {userPermissions?.View_UserList === true && (
              <Link to="/userList" replace={true}>
                <MenuItem active={location.pathname === "/userList"}>
                  <div className="flex flex-col  items-center ml-[7px] text-[#07284B] font-semibold">
                    <Driver width={24} height={24} />
                    <span className="text-[11px] mt-1 text[#21272A]">
                      Users
                    </span>
                  </div>
                </MenuItem>
              </Link>
            )}

            {/* {userPermissions?.View_Roles_List === true && ( */}
            <Link to="/roleList" replace={true}>
              <MenuItem active={location.pathname === "/roleList"}>
                <div className="flex flex-col  items-center ml-[7px] text-[#07284B] font-semibold">
                  <RoleIcon width={25} height={24} />
                  <span className="text-[11px] mt-1 text[#21272A]">Roles</span>
                </div>
              </MenuItem>
            </Link>
            {/* )} */}

            <Link to="/example" replace={true}>
              <MenuItem active={location.pathname === "/example"}>
                <div className="flex flex-col  items-center ml-[7px] text-[#07284B] font-semibold">
                  <Setting width={24} height={24} />
                  <span className="text-[11px] mt-1 text[#21272A]">
                    Settings
                  </span>
                </div>
              </MenuItem>
            </Link>

            <Link to="/example" replace={true}>
              <MenuItem active={location.pathname === "/example"}>
                <div className="flex flex-col  items-center ml-[7px] text-[#07284B] font-semibold">
                  <SpeechBubble width={24} height={24} />
                  <span className="text-[11px] mt-1 text[#21272A]">
                    Broadcast
                  </span>
                </div>
              </MenuItem>
            </Link>

            <MenuItem>
              <div className="flex flex-col  items-center ml-[7px] text-[#07284B] font-semibold">
                <button onClick={() => dispatch(logoutApp())}>
                  <Logout width={24} height={24} />
                </button>
                <span className="text-[11px] mt-1 text[#21272A]">Logout</span>
              </div>
            </MenuItem>
          </Menu>
        </ProSidebar>
      </ProSidebarProvider>
    </div>
  );
}

export { Sidebar };
