/**
 * Dashboard Management sagas
 * @format
 */

import { put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import { endPoints, httpRequest } from "../../services";
import {
  allAssessmentBeneficiaryDataCountList,
  allBeneficiaryDataCountList,
  allCampOverViewCountList,
  allDistributionBeneficiaryDataCountList,
  allDistrictAssessmentBeneficiaryDataCountList,
  allDistrictDistributionBeneficiaryDataCountList,
  allDistrictFabricationBeneficiaryDataCountList,
  allDistrictWiseBeneficiaryCount,
  allFabricationBeneficiaryDataCountList,
  allUpcomingCampsList,
  allUsersCountList,
  allVerificationBeneficiaryDataCountList,
  searchBeneficiary,
  setAllAssessmentBeneficiaryCount,
  setAllCampOverviewCount,
  setAllDashboardBeneficiaryCount,
  setAllDashboardDistrictBeneficiaryCount,
  setAllDashboardUsersCount,
  setAllDistributionBeneficiaryCount,
  setAllFabricationBeneficiaryCount,
  setAllUpComingCamps,
  setAllVerificationBeneficiaryCount,
} from "./slice";
import { setBeneficiaryProcessDetail } from "../beneficiary_management/slice";

/**
 * Get Overall Beneficiary Counts
 * @param {*}
 */
function* allDashboardBeneficiaryCountSaga() {
  try {
    const response = yield httpRequest.get(endPoints.overallBeneficiaryCount);

    yield put(setAllDashboardBeneficiaryCount(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
    console.log("Dashboard Success");
  }
}

/**
 * Get Overall Users Counts
 * @param {*}
 */
function* allDashboardUserCountSaga() {
  try {
    const response = yield httpRequest.get(endPoints.overallUserCount);

    yield put(setAllDashboardUsersCount(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
    console.log("Dashboard User Count Success");
  }
}

/**
 * Search beneficiary Data for lookup
 * @param {*}
 */
function* searchBeneficiarySaga(action) {
  try {
    const { search, onSuccess } = action.payload;
    const response = yield httpRequest.post(endPoints.searchBeneficiaryData, {
      search,
    });
    if (response?.data?.status === "Pending") {
      yield put(setBeneficiaryProcessDetail({}));
    }
    yield onSuccess({ data: response?.data });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
    console.log("Dashboard District Beneficiary Count Success");
  }
}

/**
 * Get Overall  Verify Beneficiary Counts
 * @param {*}
 */
function* allDashboardVerificationBeneficiaryCountSaga(action) {
  try {
    const { onSuccess } = action.payload;
    const response = yield httpRequest.get(
      endPoints.overallVerificationBeneficiaryCount
    );

    yield onSuccess({ data: response?.data });
    yield put(setAllVerificationBeneficiaryCount(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
    console.log("Dashboard Success");
  }
}

/**
 * Get Overall District Wise Beneficiary Counts For Verification
 * @param {*}
 */
function* allDashboardDistrictWiseBeneficiaryCountSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield httpRequest.post(
      endPoints.overallDistricBeneficiaryCountsVerification,
      {
        stateId: [id],
      }
    );

    yield onSuccess({ data: response?.data });
    // yield put(setAllDashboardDistrictBeneficiaryCount(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
    console.log("Dashboard District Beneficiary Count Success");
  }
}

/**
 * Get Overall Assessment Beneficiary Counts
 * @param {*}
 */
function* allDashboardAssessmentBeneficiaryCountSaga(action) {
  try {
    const { onSuccess } = action.payload;
    const response = yield httpRequest.get(
      endPoints.overallAssessmentBeneficiaryCount
    );
    yield onSuccess({ data: response?.data });
    yield put(setAllAssessmentBeneficiaryCount(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
    console.log("Dashboard Success");
  }
}

/**
 * Get Overall District Wise Beneficiary Counts For Assessment
 * @param {*}
 */
function* allDashboardDistrictWiseBeneficiaryCountAssessmentSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield httpRequest.post(
      endPoints.overallDistricAssessmentBeneficiaryCounts,
      {
        stateId: [id],
      }
    );

    yield onSuccess({ data: response?.data });
    // yield put(setAllDashboardDistrictBeneficiaryCount(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
    console.log("Dashboard District Beneficiary Count Success");
  }
}

/**
 * Get Overall Fabrication Beneficiary Counts
 * @param {*}
 */
function* allDashboardFabricationBeneficiaryCountSaga(action) {
  try {
    const { onSuccess } = action.payload;
    const response = yield httpRequest.get(
      endPoints.overallFabricationBeneficiaryCount
    );
    yield onSuccess({ data: response?.data });
    yield put(setAllFabricationBeneficiaryCount(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
    console.log("Dashboard Success");
  }
}

/**
 * Get Overall District Wise Beneficiary Counts For Fabrication
 * @param {*}
 */
function* allDashboardDistrictWiseBeneficiaryCountFabricationSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield httpRequest.post(
      endPoints.overallDistricFabricationBeneficiaryCounts,
      {
        stateId: [id],
      }
    );

    yield onSuccess({ data: response?.data });
    // yield put(setAllDashboardDistrictBeneficiaryCount(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
    console.log("Dashboard District Beneficiary Count Success");
  }
}

/**
 * Get Overall Distribution Beneficiary Counts
 * @param {*}
 */
function* allDashboardDistributionBeneficiaryCountSaga(action) {
  try {
    const { onSuccess } = action.payload;
    const response = yield httpRequest.get(
      endPoints.overallDistributionBeneficiaryCount
    );
    yield onSuccess({ data: response?.data });
    yield put(setAllDistributionBeneficiaryCount(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
    console.log("Dashboard Success");
  }
}

/**
 * Get Overall District Wise Beneficiary Counts For Distribution
 * @param {*}
 */
function* allDashboardDistrictWiseBeneficiaryCountDistributionSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield httpRequest.post(
      endPoints.overallDistricDistributionBeneficiaryCounts,
      {
        stateId: [id],
      }
    );

    yield onSuccess({ data: response?.data });
    // yield put(setAllDashboardDistrictBeneficiaryCount(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
    console.log("Dashboard District Beneficiary Count Success");
  }
}

/**
 * Get camps Overview Counts
 * @param {*}
 */
function* allDashboardCampOverviewCountSaga(action) {
  try {
    const response = yield httpRequest.get(endPoints.overallCampOverviewCount);
    yield put(setAllCampOverviewCount(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
    console.log("Dashboard Success");
  }
}

/**
 * Get all Upcoming Camp of User
 * @param {*}
 */
function* allDashboardUpcomingCampListSaga(action) {
  try {
    const response = yield httpRequest.get(endPoints.upcomingCampDataList);
    yield put(setAllUpComingCamps(response));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
    console.log("Dashboard Success");
  }
}

export function* dashboardManagementSaga() {
  yield takeLatest(
    allBeneficiaryDataCountList,
    allDashboardBeneficiaryCountSaga
  );
  yield takeLatest(
    allDistrictWiseBeneficiaryCount,
    allDashboardDistrictWiseBeneficiaryCountSaga
  );
  yield takeLatest(allUsersCountList, allDashboardUserCountSaga);
  yield takeLatest(searchBeneficiary, searchBeneficiarySaga);
  // Verification
  yield takeLatest(
    allVerificationBeneficiaryDataCountList,
    allDashboardVerificationBeneficiaryCountSaga
  );
  // Assessment
  yield takeLatest(
    allAssessmentBeneficiaryDataCountList,
    allDashboardAssessmentBeneficiaryCountSaga
  );
  yield takeLatest(
    allDistrictAssessmentBeneficiaryDataCountList,
    allDashboardDistrictWiseBeneficiaryCountAssessmentSaga
  );
  // Fabrication
  yield takeLatest(
    allFabricationBeneficiaryDataCountList,
    allDashboardFabricationBeneficiaryCountSaga
  );
  yield takeLatest(
    allDistrictFabricationBeneficiaryDataCountList,
    allDashboardDistrictWiseBeneficiaryCountFabricationSaga
  );
  // Distribution
  yield takeLatest(
    allDistributionBeneficiaryDataCountList,
    allDashboardDistributionBeneficiaryCountSaga
  );
  yield takeLatest(
    allDistrictDistributionBeneficiaryDataCountList,
    allDashboardDistrictWiseBeneficiaryCountDistributionSaga
  );
  // camp overview
  yield takeLatest(allCampOverViewCountList, allDashboardCampOverviewCountSaga);
  // camp overview
  yield takeLatest(allUpcomingCampsList, allDashboardUpcomingCampListSaga);
}
