/**
 * Page Not found Component
 * @format
 */

import React from "react";

export default function Custom404() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-[300px] m-0 font-bold text-blue-600">404</h1>
      <h2 className="mt-4 text-9xl font-bold text-gray-800">Page Not Found</h2>
      <p className="mt-8 text-5xl text-gray-600">
        Sorry, we couldn&apos;t find the page you&apos;re looking for.
      </p>
      <a href="/">
        <button className="mt-8 text-5xl text-blue-500 hover:underline">
          Go back home
        </button>
      </a>
    </div>
  );
}
