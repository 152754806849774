/**
 * Api Endpoints
 * All Api's endpoint
 * @format
 */

const endPoints = {
  Login: "VerifyOtp",
  SendOtp: "PhoneValidate",
  ReSendOtp: "ResendOtp",

  // User management endPoints
  allUsersList: "GetAllAdmins",
  userProfile: "AdminProfile",
  getUserById: "GetAdminById", //prams
  registerUser: "AdminRegister",
  updateUserById: "UpdateAdminById",
  updateUserProfile: "UpdateAdminProfile",
  updateUserStatus: "UpdateAdminStatus",
  deleteUser: "DeleteAdminById", //prams

  // Role management endPoints
  allRolesList: "GetAllRoles",
  getRoleWithPermission: "GetRoleByIdWithPermission", //prams
  getRoleWithAdminList: "GetRoleByIdWithAdminList", //prams
  createRole: "CreateRole",
  updateRoleById: "UpdateRoleById",
  deleteRole: "DeleteRoleById", //prams

  // Permission management endPoints
  allPermissionsList: "GetAllPermissions",
  getPermissionById: "GetPermissionById", //prams
  createPermission: "CreatePermission",
  updatePermissionById: "UpdatePermissionById",
  deletePermission: "DeletePermissionById", //prams

  // Access Level management endPoints
  allAccessLevelsList: "GetAllAccessLevels",
  getAccessLevelById: "GetAccessLevelById", //prams
  createAccessLevel: "CreateAccessLevel",
  updateAccessLevelById: "UpdateAccessLevelById",
  deleteAccessLevel: "DeleteAccessLevelById", //prams

  // Locale management endPoints
  allLocalesList: "GetAllLocales",
  getLocaleById: "GetLocaleById", //prams
  createLocale: "CreateLocale",
  updateLocaleById: "UpdateLocaleById",
  deleteLocale: "DeleteLocaleById", //prams

  // Beneficiary management endPoints
  allBeneficiaryDetailsList: "GetAllBeneficiaryDetails",
  allBeneficiaryDetailsListStatus: "GetAllBeneficiaryByStatus",
  getBeneficiaryDetailById: "GetBeneficiaryDetailById", //prams
  verifyBeneficiaryData: "CreateBeneficiaryJourney",
  updateVerifyBeneficiaryData:
    "UpdateVerificationForBeneficiariesProcessesByBeneficiaryId",
  // getBeneficiariesProcessesData: "getBeneficiariesProcessesByBeneficiaryId",

  // Common Apis endPoints
  allDistrict: "GetAllDistricts",
  allState: "GetAllStates",
  districtWithBeneficiary: "GetVerifiedBeneficiaryCountByDistrict",
  stateWithBeneficiary: "GetVerifiedBeneficiaryCountByState",
  allUserType: "GetAllUserTypes",
  allStatus: "GetAllStatus",
  allStage: "GetAllBeneficiaryStage",
  allUsersOption: "getAllUserForCounters",

  // Beneficiary Journey endPoints
  // beneficiaryJourneyByBeneficiaryId: "GetBeneficiaryJourneyByBeneficiaryId",
  beneficiaryJourneyByBeneficiaryId: "GetBeneficiaryById",

  // DashBoard endPoints
  overallBeneficiaryCount: "GetOverallBeneficiaryCounts",
  overallUserCount: "GetAdminCount",

  searchBeneficiaryData: "SearchBeneficiary",

  //Verification
  overallVerificationBeneficiaryCount:
    "GetOverallBeneficiaryCountsForVerification",
  overallDistricBeneficiaryCountsVerification:
    "GetDistrictWiseBeneficiaryCountForVerification",
  //Assessment
  overallAssessmentBeneficiaryCount: "GetOverallBeneficiaryCountsForAssessment",
  overallDistricAssessmentBeneficiaryCounts:
    "GetDistrictWiseBeneficiaryCountForAssessment",
  //Fabrication
  overallFabricationBeneficiaryCount:
    "GetOverallBeneficiaryCountsForFabrication",
  overallDistricFabricationBeneficiaryCounts:
    "GetDistrictWiseBeneficiaryCountForFabrication",
  //Distribution
  overallDistributionBeneficiaryCount:
    "GetOverallBeneficiaryCountsForDistribution",
  overallDistricDistributionBeneficiaryCounts:
    "GetDistrictWiseBeneficiaryCountForDistribution",

  // Camp Overview
  overallCampOverviewCount: "GetCampCounts",

  // My Upcoming Camp
  upcomingCampDataList: "GetUpcomingCamps",

  // Camp Center
  listCampCenter: "GetAllCampLocations",
  createCampCenter: "CreateCampLocation",
  getCampCenterById: "GetCampLocationById",
  updateCampCenter: "UpdateCampLocationById",
  updateCampCenterStatus: "UpdateCampLocationActiveStatus",
  deleteCampCenter: "DeleteCampLocationById",
  stateWithCountCampCenter: "CampLocationCountWithState",

  // Schedule Camp Center
  listScheduleCamp: "GetAllScheduledCamps",
  createScheduleCamp: "CreateScheduledCamp",
  getScheduleCampById: "GetScheduledCampById",
  updateScheduleCamp: "UpdateScheduledCampById",
  deleteScheduleCamp: "DeleteScheduledCampById",
  campsListForSelectBox: "GetPastScheduledCamps",
  allBeneficiaryListofScheduledCampById:
    "GetAllCampBeneficiaryByScheduledCampId",
  allBeneficiaryQueryListByScheduledCampId:
    "GetBeneficiaryQueryByScheduledCampId",
  beneficiaryQueryById: "GetBeneficiaryQueryById",
  updateQueryById: "UpdateBeneficiaryQueryById",
  deleteQueryById: "DeleteBeneficiaryQueryById",

  //Schedule Camp Steps Sequence
  scheduleCampStepById: "GetScheduledCampStepSequenceById",
  scheduleCampStepByScheduleCampId:
    "GetScheduledCampStepSequenceByScheduledCampId",
  allScheduleCampSteps: "GetAllScheduledCampStepSequences",
  createScheduleCampStepSequence: "CreateScheduledCampStepSequence",
  updateScheduleCampStepSequence: "UpdateScheduledCampStepSequenceById",

  // Camp Types
  allCampType: "GetAllCampTypes",

  // Camp Stages
  allCampStages: "GetAllCampSteps",

  // Camp Step Types
  allCampStepTypes: "GetAllCampStepTypes",

  // Schedule Camp Counter
  scheduleCampCounterByScheduleCampId: "GetAllCountersByScheduledCampId",
  assignUsertoCounter: "AssignUserOnCampCounter",
  getQueryData: "BeneficiaryQuery",
  assignBeneficary: "createBatchCampBeneficiary",
  allAssignedBeneficaryList: "GetBeneficiaryDetailsByBeneficiaryIds",
};

export default endPoints;
