/**
 * beneficiary Management sagas
 * @format
 */

import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import { createLoader } from "../common";
import { endPoints, httpRequest } from "../../services";
import {
  allBeneficiaryDetailList,
  allBeneficiaryDetailListByStatus,
  getBeneficiaryDetailById,
  getBeneficiaryJourneyDetails,
  getBeneficiaryProcessDetails,
  setAllBeneficiaryDetailDataList,
  setBeneficiaryDetails,
  setBeneficiaryJourneyDetail,
  setBeneficiaryProcessDetail,
  updateVerifyBeneficiary,
  verifyBeneficiary,
} from "./slice";

/**
 * Get all Beneficiary Detail list
 * @param {*}
 */
function* allBeneficiaryDetailListSaga(action) {
  try {
    const {
      search,
      page,
      stateId,
      districtId,
      status,
      perPage,
      onSuccess,
      filter,
    } = action.payload;
    const response = yield httpRequest.post(
      endPoints.allBeneficiaryDetailsList,
      {
        search,
        state: stateId,
        district: districtId,
        status,
        page,
        responsePerPage: perPage,
        startDate: filter.startDate,
        endDate: filter.endDate,
        pinCode: filter.pinCode,
      }
    );
    yield onSuccess({ message: response?.message });
    yield put(setAllBeneficiaryDetailDataList(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get all Beneficiary Detail list By status
 * @param {*}
 */
function* allBeneficiaryDetailListByStatusSaga(action) {
  try {
    const { search, page, stateId, districtId, status, perPage, onSuccess } =
      action.payload;
    const response = yield httpRequest.post(
      endPoints.allBeneficiaryDetailsListStatus,
      {
        search,
        state: stateId,
        district: districtId,
        status,
        page,
        responsePerPage: perPage,
      }
    );
    yield onSuccess({ message: response?.message });
    yield put(setAllBeneficiaryDetailDataList(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get Beneficiary Detail By Id
 * @param {*} Id
 */
function* getBeneficiaryDetailByIdSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield call(
      httpRequest.get,
      `${endPoints.getBeneficiaryDetailById}/${id}`
    );
    yield onSuccess({ message: response?.message });
    yield put(setBeneficiaryDetails(response?.data));
    if (response?.data?.status === "Pending") {
      yield put(setBeneficiaryProcessDetail({}));
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get Beneficiary Detail By Id
 * @param {*} Id
 */
function* getBeneficiaryProcessDataByIdSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield call(
      httpRequest.get,
      `${endPoints.beneficiaryJourneyByBeneficiaryId}/${id}`
    );
    yield onSuccess({ message: response?.message });
    yield put(setBeneficiaryProcessDetail(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Beneficiary Verification By User
 * @param {*} Id
 */
function* verifyBeneficiarySaga(action) {
  try {
    const { values, onSuccess } = action.payload;
    const { beneficiary_id, beneficiary_stage_id, notes, call_status, status } =
      values;
    const verification = {
      beneficiary_stage_id,
      status_id: status,
      data: [{ call_status, notes }],
    };
    const response = yield httpRequest.post(endPoints.verifyBeneficiaryData, {
      beneficiary_id,
      beneficiary_status: verification,
    });
    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Beneficiary Verification Update By User
 * @param {*} Id
 */
function* verifyUpdateBeneficiarySaga(action) {
  try {
    const { values, onSuccess } = action.payload;
    const { beneficiary_id, beneficiary_stage_id, notes, call_status, status } =
      values;
    const verification = {
      beneficiary_stage_id,
      status_id: status,
      data: [{ call_status, notes }],
    };
    const response = yield httpRequest.post(
      endPoints.updateVerifyBeneficiaryData,
      {
        beneficiary_id,
        beneficiary_status: verification,
      }
    );
    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get Beneficiary Journey Detail By beneficiary Id
 * @param {*} Id
 */
// function* getBeneficiaryJourneyDetailByIdSaga(action) {
//   try {
//     const { id, onSuccess } = action.payload;
//     const response = yield call(
//       httpRequest.get,
//       `${endPoints.beneficiaryJourneyByBeneficiaryId}/${id}`
//     );
//     yield onSuccess({ message: response?.message });
//     yield put(setBeneficiaryJourneyDetail(response?.data));
//   } catch (error) {
//     toast.error(error?.response?.data?.message);
//   } finally {
//   }
// }

export function* beneficiaryDetailManagementSaga() {
  yield takeLatest(allBeneficiaryDetailList, allBeneficiaryDetailListSaga);
  yield takeLatest(
    allBeneficiaryDetailListByStatus,
    allBeneficiaryDetailListByStatusSaga
  );
  yield takeLatest(getBeneficiaryDetailById, getBeneficiaryDetailByIdSaga);
  yield takeLatest(verifyBeneficiary, verifyBeneficiarySaga);
  yield takeLatest(
    getBeneficiaryProcessDetails,
    getBeneficiaryProcessDataByIdSaga
  );
  yield takeLatest(updateVerifyBeneficiary, verifyUpdateBeneficiarySaga);
  // yield takeLatest(
  //   getBeneficiaryJourneyDetails,
  //   getBeneficiaryJourneyDetailByIdSaga
  // );
}
