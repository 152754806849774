/**
 * DashboardScreen Page
 * @flow
 * @format
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { HeaderScreen, Sidebar } from "../../../components";
import BeneficiaryCountCard from "../components/beneficiaryCountCard";
import UpcomingCampsCard from "../components/UpcomingCampsCard";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllCampOverviewCount,
  selectAllDashboardBeneficiaryCount,
  selectAllDashboardUserCount,
  selectAllUpcomingCampsList,
  selectAllVerificationBeneficiaryCount,
} from "../selector";
import {
  allAssessmentBeneficiaryDataCountList,
  allBeneficiaryDataCountList,
  allCampOverViewCountList,
  allDistributionBeneficiaryDataCountList,
  allFabricationBeneficiaryDataCountList,
  allUpcomingCampsList,
  allUsersCountList,
  allVerificationBeneficiaryDataCountList,
} from "../slice";
import { SingleUser2 } from "../../../assets/icons/SingleUser2";
import { ViewAllDetailsModels } from "./viewAllDetails";
import { Images } from "../../../constants";
import { LookUpBeneficaryModels } from "./lookupBeneficiary";
import {
  setDistrictId,
  setFilter,
  setStateId,
} from "../../beneficiary_management/slice";
import { ArrowIcon } from "../../../assets/icons/Arrow";
import { NoRecord } from "../../../components/noRecords";
import { selectUserAllPermissions } from "../../common";
import { toast } from "react-toastify";

const bgHeaderColor = {
  Verified: "#EEF5FF",
  Assessments: "#E2FDF3",
  Fabrication: "#FFF5DF",
  Distribution: "#FFEAEF",
};
const allColumnsNames = {
  state: "State",
  district: "District",
  registered: "Registered",
  toBeScheduled: "To be scheduled",
  scheduled: "Scheduled",
  inProgress: "In Progress",
  pending: "Pending",
  verified: "Verified",
  rejected: "Rejected",
};

const CircularProgressbarPath = {
  Verified: "#1D73FF",
  Assessments: "#1CD488",
  Fabrication: "#FBBB28",
  Distribution: "#E11E56",
};

function DashboardScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userPermissions = useSelector(selectUserAllPermissions) || {};
  const [activeCard, setActiveCard] = useState("Verified");
  const [data, setData] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [columnName, setColumnName] = useState("");
  const [columnNames, setColumnNames] = useState({
    one: "",
    two: "Registered",
    three: "Verified",
    four: "Pending",
    five: "Rejected",
    six: "",
    Valueone: "percentage",
    Valuetwo: "beneficiaries",
    Valuethree: "verified",
    Valuefour: "pending",
    Valuefive: "rejected",
    Valuesix: "id",
  });

  const [isviewAllDetailOpen, setIsviewAllDetailOpen] = useState(false);
  const [isviewLookupBeneficaryOpen, setIsviewLookupBeneficaryOpen] =
    useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const { verification, assessment, fabrication, distribution } =
    useSelector(selectAllDashboardBeneficiaryCount) || {};
  const { upcomingCamps, pastCamps, liveCamps, myUpcomingCamp } =
    useSelector(selectAllCampOverviewCount) || {};
  const { data: myUpcomingCampData = [] } =
    useSelector(selectAllUpcomingCampsList) || {};
  const { districtWiseCountsForVerification, stateWiseCountsForVerification } =
    useSelector(selectAllVerificationBeneficiaryCount) || {};

  const usersCounts = useSelector(selectAllDashboardUserCount) || {};

  // Beneficiary Count Start
  const beneficiaryCountContainers = [
    {
      name: "Verified",
      title: ["Registered", "Pending", "Rejected"],
      dataName: [
        "done",
        "total",
        "registered",
        "pending",
        "rejected",
        "percentage",
      ],
      data: {
        done: verification?.verified || 0,
        total: verification?.totalBeneficiary || 0,
        registered: verification?.totalBeneficiary || 0,
        pending: verification?.pending || 0,
        rejected: verification?.rejected || 0,
        percentage: verification?.percentageVerified || 0,
      },
    },
    {
      name: "Assessments",
      title: ["In Progress", "Scheduled", "To be Scheduled"],
      dataName: [
        "done",
        "total",
        "inProgress",
        "scheduled",
        "toBeScheduled",
        "percentage",
      ],
      data: {
        done: assessment?.assessement_completed || 0,
        total: assessment?.total || 0,
        inProgress: assessment?.assessment_in_progress || 0,
        scheduled: assessment?.assessement_scheduled || 0,
        toBeScheduled: assessment?.assessement_to_be_scheduled || 0,
        percentage: assessment?.percentageAssessment || 0,
      },
    },
    {
      name: "Fabrication",
      title: ["In Progress", "Not Yet started", "Not Eligible"],
      dataName: [
        "done",
        "total",
        "inProgress",
        "notYetStarted",
        "notEligible",
        "percentage",
      ],
      data: {
        done: fabrication?.fabrication_completed || 0,
        total: fabrication?.total || 0,
        inProgress: fabrication?.fabrication_in_progress || 0,
        notEligible: fabrication?.not_eligible_for_fabrication || 0,
        notYetStarted: fabrication?.fabrication_not_yet_started || 0,
        percentage: fabrication?.percentageFabrication || 0,
      },
    },
    {
      name: "Distribution",
      title: ["In Progress", "Scheduled", "To be Scheduled"],
      dataName: [
        "done",
        "total",
        "inProgress",
        "scheduled",
        "toBeScheduled",
        "percentage",
      ],
      data: {
        done: distribution?.distribution_completed || 0,
        total: distribution?.total || 0,
        inProgress: distribution?.distribution_in_progress || 0,
        scheduled: distribution?.distribution_scheduled || 0,
        toBeScheduled: distribution?.distribution_to_be_scheduled || 0,
        percentage: distribution?.percentageDistribution || 0,
      },
    },
  ];
  // Beneficiary Count End

  // User Count Start
  const usersCountContainer = [
    {
      title: "Org User",
      value: usersCounts?.orgUser || 0,
    },
    {
      title: "Camp User",
      value: usersCounts?.campUser || 0,
    },
    {
      title: "Both",
      value: usersCounts?.both || 0,
    },
  ];
  // User Count End

  // Camp Overview Count Start
  const campOverviewCountContainer = [
    {
      title: "My Upcoming Camps",
      value: myUpcomingCamp || 0,
    },
    {
      title: `All Upcoming Camps`,
      value: upcomingCamps || 0,
    },
    {
      title: "Live Camps",
      value: liveCamps || 0,
    },
  ];
  // Camp Overview Count End

  // DataTable Start

  const SortIcon = ({ isSorted, isSortedDesc }) => {
    if (!isSorted) {
      return <span className="ml-4 sort-icon">&uarr;&darr;</span>; // Default unsorted icon
    } else if (isSortedDesc) {
      return <span className="ml-4 sort-icon">&darr;</span>; // Descending sort icon
    } else {
      return <span className="ml-4 sort-icon">&uarr;</span>; // Ascending sort icon
    }
  };

  const columnStyle = {
    fontSize: "14px",
    fontWeight: "normal",
    // border: "2px solid black"
  };

  const columns = [
    {
      name: allColumnsNames[columnName],
      selector: (row) => row?.describe,
      sortable: true,
      style: columnStyle,
      minWidth: "200px",
      cell: (row) => {
        return (
          <>
            <div className="flex flex-col items-start space-y-1 justify-between w-full">
              <div
                className="underline"
                onClick={() => {
                  if (row.describe === "state") {
                    toggleViewAllDetails(row);
                  }
                }}
              >
                {row?.name}
              </div>
              <div className="flex flex-row space-x-2">
                <SingleUser2 width={10} height={12} />
                <span>Users:{row.users}</span>
              </div>
            </div>
          </>
        );
      },
    },
    {
      name: columnNames.one,
      selector: (row) => row?.[columnNames.Valueone],
      sortable: true,
      style: columnStyle,
      center: true,
      // minWidth: "50px",
      // maxWidth: "100px",
      cell: (row) => (
        <>
          <div className="w-8 h-8">
            <CircularProgressbar
              value={row?.[columnNames.Valueone] || 0}
              text={`${row?.[columnNames.Valueone] || 0}%`}
              styles={buildStyles({
                pathColor: CircularProgressbarPath[activeCard],
                textColor: "black",
                trailColor: "#d6d6d6",
                backgroundColor: "#3e98c7",
                textSize: "30px",
              })}
            />
          </div>
        </>
      ),
    },
    {
      name: columnNames.two,
      selector: (row) => row?.[columnNames.Valuetwo],
      sortable: true,
      style: columnStyle,
      center: true,
    },
    {
      name: columnNames.three,
      selector: (row) => row?.[columnNames.Valuethree],
      sortable: true,
      style: columnStyle,
      center: true,
    },
    {
      name: columnNames.four,
      selector: (row) => row?.[columnNames.Valuefour],
      sortable: true,
      style: columnStyle,
      center: true,
    },
    {
      name: columnNames.five,
      selector: (row) => row?.[columnNames.Valuefive],
      sortable: true,
      style: columnStyle,
      center: true,
    },
    {
      name: columnNames.six,
      selector: (row) => row?.[columnNames.Valuesix],
      sortable: true,
      style: columnStyle,
      center: true,
      cell: (row) => (
        <>
          <div className="flex w-[80%] items-center justify-end">
            <button
              className="underline text-blue-500 right-0 cursor-pointer"
              onClick={() => {
                // Determine state and district fields based on describe value
                const stateField =
                  row.describe === "state" ? row?.[columnNames.Valuesix] : "";
                const districtField =
                  row.describe === "district"
                    ? row?.[columnNames.Valuesix]
                    : "";

                // Set states
                dispatch(setDistrictId(districtField));
                dispatch(setStateId(stateField));

                // Navigate and pass the sendData object
                navigate("/beneficiaryVerification");
              }}
            >
              View all
            </button>
          </div>
        </>
      ),
    },
  ];
  // DataTable End

  // main full count
  const getDashboardBeneficiaryCount = () => {
    dispatch(allBeneficiaryDataCountList());
  };

  const getDashboardUserCount = () => {
    dispatch(allUsersCountList());
  };

  // Verification
  const getDashboardVerificationBeneficiaryCount = () => {
    dispatch(
      allVerificationBeneficiaryDataCountList({
        onSuccess: (props) => {
          setColumnNames({
            one: "",
            two: "Registered",
            three: "Verified",
            four: "Pending",
            five: "Rejected",
            six: "",
            Valueone: "percentage",
            Valuetwo: "beneficiaries",
            Valuethree: "verified",
            Valuefour: "pending",
            Valuefive: "rejected",
            Valuesix: "id",
          });
        },
      })
    );
  };

  //Assessment
  const getDashboardAssessmentBeneficiaryCount = () => {
    dispatch(
      allAssessmentBeneficiaryDataCountList({
        onSuccess: (props) => {
          setColumnNames({
            one: "",
            two: "Done",
            three: "In Progress",
            four: "Scheduled",
            five: "To Be Scheduled",
            six: "",
            Valueone: "percentage",
            Valuetwo: "done",
            Valuethree: "inProgress",
            Valuefour: "scheduled",
            Valuefive: "toBeScheduled",
            Valuesix: "id",
          });

          if (props.data?.districtWiseCountsForAssessment) {
            setColumnName("district");
            setData(
              props.data?.districtWiseCountsForAssessment?.map((item) => ({
                name: item?.districtName,
                percentage: item?.percentageAssessment,
                users: item?.users,
                done: item?.assessement_completed,
                inProgress: item?.assessment_in_progress,
                scheduled: item?.assessement_scheduled,
                toBeScheduled: item?.assessement_to_be_scheduled,
                id: item?.id,
                describe: "district",
              }))
            );
          } else if (props.data?.stateWiseCountsForAssessment) {
            const dummyArray = props.data?.stateWiseCountsForAssessment?.map(
              (item) => ({
                name: item?.stateName,
                percentage: item?.percentageAssessment,
                users: item?.users,
                done: item?.assessement_completed,
                inProgress: item?.assessment_in_progress,
                scheduled: item?.assessement_scheduled,
                toBeScheduled: item?.assessement_to_be_scheduled,
                id: item?.id,
                describe: "state",
              })
            );
            setColumnName("state");
            setData(dummyArray);
          }
        },
      })
    );
  };

  //Fabrication
  const getDashboardFabricationBeneficiaryCount = () => {
    dispatch(
      allFabricationBeneficiaryDataCountList({
        onSuccess: (props) => {
          setColumnNames({
            one: "",
            two: "Done",
            three: "In Progress",
            four: "Not yet Started",
            five: "Not Eligible",
            six: "",
            Valueone: "percentage",
            Valuetwo: "done",
            Valuethree: "inProgress",
            Valuefour: "scheduled",
            Valuefive: "toBeScheduled",
            Valuesix: "id",
          });

          if (props.data?.districtWiseCountsForFabrication) {
            setColumnName("district");
            setData(
              props.data?.districtWiseCountsForFabrication?.map((item) => ({
                name: item?.districtName,
                percentage: item?.percentageFabrication,
                done: item?.fabrication_completed,
                inProgress: item?.fabrication_in_progress,
                scheduled: item?.fabrication_not_yet_started,
                toBeScheduled: item?.not_eligible_for_fabrication,
                users: item?.users,
                id: item?.id,
                describe: "district",
              }))
            );
          } else if (props.data?.stateWiseCountsForFabrication) {
            const dummyArray = props.data?.stateWiseCountsForFabrication?.map(
              (item) => ({
                name: item?.stateName,
                percentage: item?.percentageFabrication,
                users: item?.users,
                done: item?.fabrication_completed,
                inProgress: item?.fabrication_in_progress,
                scheduled: item?.fabrication_not_yet_started,
                toBeScheduled: item?.not_eligible_for_fabrication,
                id: item?.id,
                describe: "state",
              })
            );
            setColumnName("state");
            setData(dummyArray);
          }
        },
      })
    );
  };

  //Distribution
  const getDashboardDistributionBeneficiaryCount = () => {
    dispatch(
      allDistributionBeneficiaryDataCountList({
        onSuccess: (props) => {
          setColumnNames({
            one: "",
            two: "Done",
            three: "In Progress",
            four: "Scheduled",
            five: "To Be Scheduled",
            six: "",
            Valueone: "percentage",
            Valuetwo: "done",
            Valuethree: "inProgress",
            Valuefour: "scheduled",
            Valuefive: "toBeScheduled",
            Valuesix: "id",
          });

          if (props.data?.districtWiseCountsForDistribution) {
            setColumnName("district");
            setData(
              props.data?.districtWiseCountsForDistribution?.map((item) => ({
                name: item?.districtName,
                percentage: item?.percentageDistribution,
                users: item?.users,
                done: item?.distribution_completed,
                inProgress: item?.distribution_in_progress,
                scheduled: item?.distribution_scheduled,
                toBeScheduled: item?.distribution_to_be_scheduled,
                id: item?.id,
                describe: "district",
              }))
            );
          } else if (props.data?.stateWiseCountsForDistribution) {
            const dummyArray = props.data?.stateWiseCountsForDistribution?.map(
              (item) => ({
                name: item?.stateName,
                percentage: item?.percentageDistribution,
                users: item?.users,
                done: item?.distribution_completed,
                inProgress: item?.distribution_in_progress,
                scheduled: item?.distribution_scheduled,
                toBeScheduled: item?.distribution_to_be_scheduled,
                id: item?.id,
                describe: "state",
              })
            );
            setColumnName("state");
            setData(dummyArray);
          }
        },
      })
    );
  };

  // camp Overview
  const campOverviewCount = () => {
    dispatch(allCampOverViewCountList());
  };

  // My Upcoming Camps
  const myUpcomingCamps = () => {
    dispatch(allUpcomingCampsList());
  };

  useEffect(() => {
    // Update data based on districtWiseCounts or stateWiseCounts For Verification
    if (districtWiseCountsForVerification) {
      setColumnName("district");
      setData(
        districtWiseCountsForVerification?.map((item) => ({
          name: item?.districtName,
          percentage: item?.percentageVerified,
          verified: item?.verified,
          users: item?.users,
          rejected: item?.rejected,
          pending: item?.pending,
          beneficiaries: item?.beneficiaries,
          id: item?.id,
          describe: "district",
        }))
      );
    } else if (stateWiseCountsForVerification) {
      const dummyArray = stateWiseCountsForVerification?.map((item) => ({
        name: item?.stateName,
        percentage: item?.percentageVerified,
        verified: item?.verified,
        users: item?.users,
        rejected: item?.rejected,
        pending: item?.pending,
        beneficiaries: item?.beneficiaries,
        id: item?.id,
        describe: "state",
      }));
      setColumnName("state");
      setData(dummyArray);
    }
  }, [stateWiseCountsForVerification, districtWiseCountsForVerification]);

  useEffect(() => {
    getDashboardBeneficiaryCount();
    getDashboardUserCount();
    getDashboardVerificationBeneficiaryCount();
    dispatch(setDistrictId(""));
    dispatch(setStateId(""));
    dispatch(setFilter({ startDate: "", endDate: "", pinCode: [] }));
    campOverviewCount();
    myUpcomingCamps();
  }, [dispatch]);

  const toggleViewAllDetails = (row) => {
    setSelectedRow(row);
    setIsviewAllDetailOpen(!isviewAllDetailOpen);
  };

  const toggleLookupBeneficaryDetails = () => {
    setIsviewLookupBeneficaryOpen(!isviewLookupBeneficaryOpen);
  };

  const handleCardClick = (name) => {
    setActiveCard(name);
  };

  const handleCallApi = (cardName) => {
    if (cardName === "Verified") {
      getDashboardVerificationBeneficiaryCount();
    } else if (cardName === "Assessments") {
      getDashboardAssessmentBeneficiaryCount();
    } else if (cardName === "Fabrication") {
      getDashboardFabricationBeneficiaryCount();
    } else if (cardName === "Distribution") {
      getDashboardDistributionBeneficiaryCount();
    }
  };

  return (
    <>
      <div className="h-screen min-w-screen relative">
        <div
          onClick={() => {
            toggleLookupBeneficaryDetails();
          }}
        >
          <img
            src={Images.starFixedIcon}
            alt="Logo"
            className=" side-slider "
          />
        </div>
        <HeaderScreen />
        <div className="flex h-[calc(100vh-65px)]">
          <Sidebar />
          {/* w-[calc(100vw-90px)] */}
          <div className="flex flex-col h-full p-4 bg-customBlue-mainBackground w-screen">
            <div className="container min-h-[80px] min-w-full grid grid-cols-12 gap-4 items-start justify-between h-full">
              {/* Left Side */}
              <div
                className={`flex flex-col bg-white rounded-lg font-raleway text-[24px] overflow-y-auto scrollbar-hide h-full border-2 border-gray-100 ${
                  userPermissions?.View_Dashboard_User_Count &&
                  userPermissions?.View_Dashboard_Camp_Count &&
                  userPermissions?.View_Dashboard_Upcoming_Camps
                    ? "col-span-9"
                    : "col-span-12"
                }`}
              >
                <div className="flex flex-row justify-between items-center p-2 px-4">
                  <h3 className="font-bold">Beneficiary Count</h3>
                  {isVisible && (
                    <div
                      className="rotate-180"
                      onClick={() => {
                        setIsVisible(false);
                      }}
                    >
                      <ArrowIcon
                        width={16}
                        height={20}
                        isRotated={true}
                        fillColor={"#181818"}
                      />
                    </div>
                  )}
                </div>

                <div className="flex gap-2 justify-between overflow-auto scrollbar-hide">
                  {beneficiaryCountContainers?.map((cardData, index) => (
                    <div
                      key={index}
                      className="flex-1 min-w-[13.8rem]"
                      onClick={() => {
                        handleCardClick(cardData?.name);
                        handleCallApi(cardData?.name);
                        setIsVisible(true);
                      }}
                    >
                      <BeneficiaryCountCard
                        isVisible={isVisible}
                        name={cardData?.name}
                        title={cardData.title}
                        data={cardData.data}
                        isActive={activeCard === cardData?.name}
                        dataName={cardData.dataName}
                      />
                    </div>
                  ))}
                </div>
                {/* Data Table */}
                <div
                  className={`flex-auto theme-table table-dash ${
                    isVisible ? "activeCard" : "overflow-y-auto scrollbar-hide"
                  }`}
                >
                  <DataTable
                    columns={columns}
                    data={data}
                    customStyles={{
                      headCells: {
                        style: {
                          backgroundColor: bgHeaderColor[activeCard],
                          color: "Black",
                          fontWeight: "bold",
                          fontSize: "14px",
                        },
                      },
                    }}
                    sortIcon={<SortIcon />}
                    noDataComponent={
                      <div className="h-full py-16">
                        <NoRecord
                          title_1={"There are no records to display"}
                          title_2={""}
                        />
                      </div>
                    }
                  />
                </div>
              </div>
              {/* Right Side */}
              {userPermissions?.View_Dashboard_User_Count === true &&
                userPermissions?.View_Dashboard_Camp_Count === true &&
                userPermissions?.View_Dashboard_Upcoming_Camps === true && (
                  <div className="col-span-3 flex flex-col space-y-4 justify-start font-raleway text-[24px] h-full overflow-y-auto scrollbar-hide">
                    {/* User Count */}
                    {userPermissions?.View_Dashboard_User_Count === true && (
                      <div className=" w-full p-4 flex flex-col space-y-4 bg-white rounded-lg font-raleway text-[24px] border-2 border-gray-100">
                        <div className="flex  flex-row justify-between items-center">
                          <h3 className="text-[20px] font-bold">User Count</h3>
                          <button
                            className="text-blue-500 text-sm underline inline-block font-semibold"
                            onClick={() => {
                              if (userPermissions?.View_UserList === true) {
                                // Navigate and pass the sendData object
                                navigate("/userList");
                              } else if (
                                userPermissions?.View_UserList === false
                              ) {
                                toast.error(
                                  "you have No Permssions to View User List"
                                );
                              }
                            }}
                          >
                            View all
                          </button>
                        </div>
                        <div className="flex flex-wrap gap-4 text-[14px]">
                          {usersCountContainer?.map((item, index) => (
                            <div
                              key={index}
                              className={`flex flex-col items-center flex-1 min-w-[50px] justify-between border-2 border-customBlue-border rounded-xl p-2 space-y-2 text-center`}
                            >
                              <span className="text-[#403A44] font-semibold">
                                {item?.title}
                              </span>
                              <h3 className="font-bold text-[26px]">
                                {item?.value}
                              </h3>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {/* Camps Overview */}
                    {userPermissions?.View_Dashboard_Camp_Count === true && (
                      <div className=" w-full p-4 flex flex-col space-y-4 bg-white rounded-lg font-raleway text-[24px] border-2 border-gray-100">
                        <div className="flex md:flex-wrap flex-row justify-between items-center">
                          <h3 className="text-[20px] font-bold">
                            Camps Overview
                          </h3>
                          <button
                            className="text-blue-500 text-sm underline inline-block font-semibold"
                            onClick={() => {
                              // Navigate and pass the sendData object
                              // navigate("/beneficiaryVerification");
                            }}
                          >
                            View all
                          </button>
                        </div>
                        <div className="flex lg:flex-wrap flex-wrap gap-2 text-[14px]">
                          {campOverviewCountContainer?.map((item, index) => (
                            <div
                              key={index}
                              className={`flex flex-col items-center flex-1 flex-auto justify-between border-2 border-customBlue-border rounded-xl p-2 space-y-2 text-center`}
                            >
                              <span className="text-[#403A44] font-semibold min-width ">
                                {item?.title}
                              </span>
                              <h3 className="font-bold text-[26px]">
                                {item?.value}
                              </h3>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {/* my Upcoming Camps */}
                    {userPermissions?.View_Dashboard_Upcoming_Camps ===
                      true && (
                      <UpcomingCampsCard
                        myUpcomingCampData={myUpcomingCampData}
                      />
                    )}
                  </div>
                )}
            </div>
          </div>
        </div>

        {/* open model */}
        {isviewAllDetailOpen && (
          <ViewAllDetailsModels
            rowData={selectedRow}
            toggleModal={toggleViewAllDetails}
            cardName={activeCard}
          />
        )}
        {isviewLookupBeneficaryOpen && (
          <LookUpBeneficaryModels toggleModal={toggleLookupBeneficaryDetails} />
        )}
      </div>
    </>
  );
}

export { DashboardScreen };
