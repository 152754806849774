/**
 * header Component
 * @format
 */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

/**
 * Import
 */
import { Images } from "../constants";
import { selectUserAllPermissions, selectUserProfile } from "../pages/common";
import { constants, getInitialsName } from "../utils";

function HeaderScreen() {
  const [formattedDate, setFormattedDate] = useState("");

  const { data: userProfile = {} } = useSelector(selectUserProfile) || {};
  const userPermissions = useSelector(selectUserAllPermissions) || {};

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Define options to format the date
    const options = {
      weekday: "short", // "Tue"
      year: "numeric", // "2024"
      month: "short", // "May"
      day: "numeric", // "29"
      hour: "2-digit", // "04"
      minute: "2-digit", // "09"
      second: "2-digit", // "41"
    };

    // Format the date using `toLocaleString`
    return date.toLocaleString("en-US", options).replace(/\s*,\s*/g, " ");
  };

  useEffect(() => {
    if (userProfile?.createdAt) {
      setFormattedDate(formatDate(userProfile.createdAt)); // Update state with the formatted date
    }
  }, [userProfile]);

  return (
    <>
      <header className="top-0 left-0 right-0 z-10 flex items-center justify-between min-h-[60px] px-[24px] w-full bg-white">
        <div className="flex flex-row items-center space-x-5">
          <img src={Images.LogoDashboard} alt="Logo" className="w-[48px]" />
          <span className="text-[24px] font-bold">{constants.SSSDS}</span>
        </div>
        <div className="flex items-center gap-[15px]">
          <div className="flex items-center gap-[10px] py-2">
            <div className="relative rounded-xl h-[60px] w-[60px] text-center border flex justify-center items-center font-semibold text-[24px] bg-gray-200">
              {getInitialsName(userProfile?.name || "name")}
              {/* <img src={Images.UserProfile} alt="Logo" />
              <div className="absolute left-2 -bottom-[6px] flex h-[16px] w-[44px] bg-themeGreen rounded-[4px] items-center justify-center gap-[2px]">
                <div className="text-white font-semibold">
                  <LiveIcon width={13} height={13} />
                </div>
                <div className="text-[10px] text-white font-semibold">LIVE</div>
              </div> */}
            </div>
            <div className="flex items-center gap-[15px]">
              <div>
                <div className="text-base leading-none font-bold text-gray-text-500">
                  {userProfile?.name}
                </div>
                <div className="text-xs text-gray-text-100">
                  {formattedDate}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export { HeaderScreen };
