/**
 * Add user Model
 * @format
 */

import React, { useEffect, useState } from "react";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { Switch } from "@material-tailwind/react";
import { toast } from "react-toastify";
import { UserFields } from "../../../constants";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-select";
import { selectAllRoleDataList } from "../../role_management/selector";
import { allAccessLevelList } from "../../access_level_management/slice";
import { selectAllAccessLevelDataList } from "../../access_level_management/selector";
import { Delete } from "../../../assets/icons/Delete";
import { useDispatch, useSelector } from "react-redux";
import { allRoleList } from "../../role_management/slice";
import { createUser, getUserFromSSSApi } from "../slice";
import { Cross } from "../../../assets/icons/Cross";
import {
  getAllDistrict,
  getAllState,
  getAllUserType,
  selectDistrict,
  selectState,
  selectUserAllPermissions,
  selectUserType,
} from "../../common";
import MultipleTagsInput from "../../../components/multipleTagInput";
import { constants } from "../../../utils";
import SelectDropDown from "../../../components/selectDropDown";

const AddUserModel = ({ toggleModal, getData }) => {
  const dispatch = useDispatch(); // For generating unique IDs
  const [userStatus, setUserStatus] = useState(true);
  const [userApiData, setUserApiData] = useState({});
  const userPermissions = useSelector(selectUserAllPermissions) || {};

  // Validations
  const validationSchema = Yup.object().shape({
    [UserFields.NUMBER]: Yup.string()
      .required("Mobile no. or Sai Connect ID is required")
      .matches(/^\d+$/, "Only numeric digits are allowed")
      .min(6, "Mobile no. or Sai Connect ID must be at least 6 digits")
      .max(10, "Mobile no. or Sai Connect ID must not exceed 10 digits"),
    [UserFields.EMAIL]: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    [UserFields.USER_TYPE]: Yup.array()
      .of(Yup.string().required("Invalid user type"))
      .min(1, "Select at least one user type")
      .required("User type is required"),
    [UserFields.IS_ACTIVE]: Yup.boolean(),
  });

  const initialValues = {
    [UserFields.MOBILE_NUMBER]: "",
    [UserFields.NUMBER]: "",
    [UserFields.MEMBER_ID]: "",
    [UserFields.ROLE]: [],
    [UserFields.USER_TYPE]: [],
    [UserFields.NAME]: "",
    [UserFields.EMAIL]: "",
    [UserFields.GENDER]: "",
    [UserFields.PIN_CODE]: "",
    [UserFields.IS_ACTIVE]: true,
    access_level: [{ id: Date.now(), access: "", locale: [] }],
  };

  const [initialObject, setInitialObject] = useState(initialValues);

  const getRoles = () => {
    dispatch(
      allRoleList({
        search: "",
        // page: 1,
        // perPage: 10,
        onSuccess: (props) => {
          console.log("Role loaded successfully");
        },
      })
    );
  };

  const getAccessLevel = () => {
    dispatch(
      allAccessLevelList({
        search: "",
        // page: 1,
        // perPage: 2,
        onSuccess: (props) => {
          console.log("Access level loaded successfully");
        },
      })
    );
  };

  const getDistrict = () => {
    dispatch(
      getAllDistrict({
        // district_name: "",
        // state_code: 1,
        // sai_connect_state_id: 2,
        onSuccess: (props) => {
          console.log("All District loaded successfully");
        },
      })
    );
  };

  const getState = () => {
    dispatch(
      getAllState({
        state_name: "",
        // iso_code: "",
        onSuccess: (props) => {
          console.log("All State loaded successfully");
        },
      })
    );
  };

  const getUserType = () => {
    dispatch(
      getAllUserType({
        search: "",
        onSuccess: (props) => {
          console.log("All User Type loaded successfully");
        },
      })
    );
  };

  useEffect(() => {
    getRoles();
    getAccessLevel();
    getDistrict();
    getState();
    getUserType();
    if (userApiData) {
      const {
        MemberName,
        MemberId,
        MobileNumber,
        EmailID,
        SamithiId,
        SamithiName,
        SSSDistrictId,
        SSSDistrictName,
        SSSStateId,
        SSSStateName,
        GenderName,
        PoliticalStateName,
        PoliticalStateId,
        PoliticalDistrictName,
        PoliticalDistrictId,
        PinCode,
        DesignationName,
      } = userApiData;

      setInitialObject({
        [UserFields.NAME]: MemberName,
        [UserFields.MEMBER_ID]: MemberId,
        [UserFields.MOBILE_NUMBER]: MobileNumber,
        [UserFields.EMAIL]: EmailID,
        [UserFields.SAMITHI_ID]: SamithiId,
        [UserFields.SAMITHI_NAME]: SamithiName,
        [UserFields.SSS_DISTRICT_ID]: SSSDistrictId,
        [UserFields.SSS_DISTRICT_NAME]: SSSDistrictName,
        [UserFields.SSS_DISTRICT_NAME]: SSSDistrictName,
        [UserFields.SSS_STATE_ID]: SSSStateId,
        [UserFields.SSS_STATE_NAME]: SSSStateName,
        [UserFields.GENDER]: GenderName,
        [UserFields.POLITICAL_STATE_NAME]: PoliticalStateName,
        [UserFields.POLITICAL_STATE_ID]: PoliticalStateId,
        [UserFields.POLITICAL_DISTRICT_NAME]: PoliticalDistrictName,
        [UserFields.POLITICAL_DISTRICT_ID]: PoliticalDistrictId,
        [UserFields.PIN_CODE]: PinCode,
        [UserFields.DESIGNATION_NAME]: DesignationName,
        [UserFields.ROLE]: [],
        [UserFields.USER_TYPE]: [],
        [UserFields.IS_ACTIVE]: true,
        access_level: [{ id: Date.now(), access_level_id: "", locale: [] }],
      });
    }
  }, [userApiData]);

  const { data: roleData = [] } = useSelector(selectAllRoleDataList) || {};
  const rolesOptions = roleData?.map((item) => ({
    label: item.role_name,
    value: item._id,
  }));

  const { data: accessLevelData = [] } =
    useSelector(selectAllAccessLevelDataList) || {};
  const accessLevelIds =
    accessLevelData?.reduce((acc, item) => {
      acc[item.name] = item._id;
      return acc;
    }, {}) || {};

  const accessLevelOptions = accessLevelData?.map((item) => ({
    label: item.name,
    value: item._id,
  }));

  const { data: district = [] } = useSelector(selectDistrict) || {};
  const districtOptions = district?.map((item) => ({
    group: item?.state_data?.state_name,
    label: item?.district_name,
    value: item?._id,
  }));

  const { data: state = [] } = useSelector(selectState) || {};
  const stateOptions = state?.map((item) => ({
    group: "India",
    label: item?.state_name,
    value: item?._id,
  }));

  const { data: userType = [] } = useSelector(selectUserType) || {};
  const userTypeOptions = userType?.map((item) => ({
    label: item?.user_type_name,
    value: item?._id,
  }));

  const localeOptions = {
    national: [{ label: "India", value: "India" }],
    state: stateOptions,
    district: districtOptions,
  };

  const getLocaleOptions = (access_level_id) => {
    if (access_level_id === accessLevelIds?.National)
      return localeOptions.national || [];
    if (access_level_id === accessLevelIds?.State)
      return localeOptions.state || [];
    if (access_level_id === accessLevelIds?.District)
      return localeOptions.district || [];
    return [];
  };
  return (
    <>
      <Formik
        initialValues={initialObject}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          delete values[UserFields.NUMBER];
          console.log(values, "Final Payload to Submit");
          if (userPermissions?.Create_User === true) {
            dispatch(
              createUser({
                values,
                onSuccess: (props) => {
                  toast.success(props?.message);
                  toggleModal();
                  const search = "";
                  getData(search);
                },
              })
            );
          } else if (userPermissions?.Create_User === false) {
            toast.error("you have No Permssions to Create User");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            {console.log({ errors })}
            <div className="fixed inset-0 z-50 flex justify-end">
              <div className="fixed inset-0 bg-[#07284B] opacity-50"></div>
              <div className="relative w-[43%] h-full bg-white shadow-lg overflow-y-auto scrollbar-hide">
                <div className="flex justify-between items-center bg-customBlue-mainBackground w-full h-[11%] p-6">
                  <h2 className="text-[20px] font-bold">Create New User</h2>
                  <button
                    onClick={toggleModal}
                    className="text-xl font-semibold"
                  >
                    <Cross width={32} height={32} />
                  </button>
                </div>
                {/* Modal content */}
                <div className="w-full flex flex-col space-y-3 p-6">
                  <div className="w-full">
                    <label className="text-[14px] font-bold">
                      Mobile Number/Sai Connect ID
                    </label>
                    <input
                      autoComplete="off"
                      type="tel"
                      name={UserFields.NUMBER}
                      id={UserFields.NUMBER}
                      maxLength={10}
                      onChange={(e) => {
                        handleChange(e);
                        const number = e.target.value;
                        if (number.length === 6) {
                          dispatch(
                            getUserFromSSSApi({
                              number,
                              onSuccess: (props) => {
                                toast.success(props?.message);
                                setUserApiData(props.response?.data?.data[0]);
                              },
                            })
                          );
                        } else if (number.length === 10) {
                          dispatch(
                            getUserFromSSSApi({
                              number,
                              onSuccess: (props) => {
                                toast.success(props?.message);
                                setUserApiData(props.response?.data?.data[0]);
                              },
                            })
                          );
                        }
                      }}
                      value={values[UserFields.NUMBER]}
                      onBlur={handleBlur}
                      className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px]"
                    />
                    {touched[UserFields.NUMBER] &&
                      errors[UserFields.NUMBER] && (
                        <div className="mt-1 text-xs text-red-500">
                          {errors[UserFields.NUMBER]}
                        </div>
                      )}
                  </div>
                  <div className="w-full">
                    <label className="text-[14px] font-bold">Name</label>
                    <input
                      type="text"
                      name={UserFields.NAME}
                      onChange={handleChange}
                      value={values[UserFields.NAME]}
                      onBlur={handleBlur}
                      className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px]"
                    />
                    {touched[UserFields.NAME] && errors[UserFields.NAME] && (
                      <div className="mt-1 text-xs text-red-500">
                        {errors[UserFields.NAME]}
                      </div>
                    )}
                  </div>
                  <div className="w-full">
                    <label className="text-[14px] font-bold">District</label>
                    <input
                      type="text"
                      name={UserFields.SSS_DISTRICT_NAME}
                      onChange={handleChange}
                      value={values[UserFields.SSS_DISTRICT_NAME]}
                      onBlur={handleBlur}
                      className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px]"
                    />
                    {touched[UserFields.SSS_DISTRICT_NAME] &&
                      errors[UserFields.SSS_DISTRICT_NAME] && (
                        <div className="mt-1 text-xs text-red-500">
                          {errors[UserFields.SSS_DISTRICT_NAME]}
                        </div>
                      )}
                  </div>
                  <div className="w-full">
                    <label className="text-[14px] font-bold">State</label>
                    <input
                      type="text"
                      name={UserFields.SSS_STATE_NAME}
                      onChange={handleChange}
                      value={values[UserFields.SSS_STATE_NAME]}
                      onBlur={handleBlur}
                      className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px]"
                    />
                    {touched[UserFields.SSS_STATE_NAME] &&
                      errors[UserFields.SSS_STATE_NAME] && (
                        <div className="mt-1 text-xs text-red-500">
                          {errors[UserFields.SSS_STATE_NAME]}
                        </div>
                      )}
                  </div>
                  <div className="w-full">
                    <label className="text-[14px] font-bold">Pin Code</label>
                    <input
                      type="text"
                      name={UserFields.PIN_CODE}
                      onChange={handleChange}
                      value={values[UserFields.PIN_CODE]}
                      onBlur={handleBlur}
                      className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px]"
                    />
                    {touched[UserFields.PIN_CODE] &&
                      errors[UserFields.PIN_CODE] && (
                        <div className="mt-1 text-xs text-red-500">
                          {errors[UserFields.PIN_CODE]}
                        </div>
                      )}
                  </div>

                  <div className="w-full">
                    <label className="text-[14px] font-bold">Email</label>
                    <input
                      type="email"
                      name={UserFields.EMAIL}
                      onChange={handleChange}
                      value={values[UserFields.EMAIL]}
                      onBlur={handleBlur}
                      className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px]"
                    />
                    {touched[UserFields.EMAIL] && errors[UserFields.EMAIL] && (
                      <div className="mt-1 text-xs text-red-500">
                        {errors[UserFields.EMAIL]}
                      </div>
                    )}
                  </div>

                  <div className="w-full">
                    <label className="text-[14px] font-bold mb-1 block">
                      User Type
                    </label>
                    <Multiselect
                      options={userTypeOptions}
                      displayValue="label"
                      onSelect={(selectedList) =>
                        setFieldValue(
                          UserFields.USER_TYPE,
                          selectedList.map((item) => item.value)
                        )
                      }
                      onRemove={(selectedList) =>
                        setFieldValue(
                          UserFields.USER_TYPE,
                          selectedList.map((item) => item.value)
                        )
                      }
                      selectedValues={userTypeOptions.filter((option) =>
                        values[UserFields.USER_TYPE].includes(option.value)
                      )}
                      showCheckbox
                      showArrow
                      placeholder="Select"
                      style={{
                        multiselectContainer: {
                          padding: "0px",
                          border: "2px solid #B0E0FF",
                          borderRadius: "12px",
                        },
                        searchBox: {
                          border: "none",
                          height: "44px",
                          overflowY: "scroll",
                          scrollbarWidth: "none",
                          msOverflowStyle: "none",
                        },
                        chips: {
                          backgroundColor: "#B0E0FF",
                          color: "black",
                        },
                      }}
                    />
                    {touched[UserFields.USER_TYPE] &&
                      errors[UserFields.USER_TYPE] && (
                        <div className="mt-1 text-xs text-red-500">
                          {errors[UserFields.USER_TYPE]}
                        </div>
                      )}
                  </div>

                  {/* Conditionally render User Role and Access Level based on User Type */}
                  {values[UserFields.USER_TYPE].includes(constants.orgUser) && (
                    <>
                      <div className="w-full">
                        <label className="text-[14px] font-bold mb-1 block">
                          User Role
                        </label>
                        <Multiselect
                          options={rolesOptions}
                          displayValue="label"
                          onSelect={(selectedList) =>
                            setFieldValue(
                              UserFields.ROLE,
                              selectedList.map((item) => item.value)
                            )
                          }
                          onRemove={(selectedList) =>
                            setFieldValue(
                              UserFields.ROLE,
                              selectedList.map((item) => item.value)
                            )
                          }
                          selectedValues={rolesOptions.filter((option) =>
                            values[UserFields.ROLE].includes(option.value)
                          )}
                          showCheckbox
                          showArrow
                          placeholder="Select Roles"
                          style={{
                            multiselectContainer: {
                              padding: "0px",
                              border: "2px solid #B0E0FF",
                              borderRadius: "12px",
                            },
                            searchBox: {
                              border: "none",
                              height: "44px",
                              overflowY: "scroll",
                              scrollbarWidth: "none",
                              msOverflowStyle: "none",
                            },
                            chips: {
                              backgroundColor: "#B0E0FF",
                              color: "black",
                            },
                          }}
                        />
                        {touched[UserFields.ROLE] &&
                          errors[UserFields.ROLE] && (
                            <div className="mt-1 text-xs text-red-500">
                              {errors[UserFields.ROLE]}
                            </div>
                          )}
                      </div>

                      <div className="w-full">
                        <label className="text-[14px] font-bold">
                          Access Level{" "}
                        </label>
                        <div className="grid grid-cols-12 gap-4 mt-1 w-full items-end">
                          <div className="col-span-5">
                            <label className="text-[12px] font-bold">
                              Access
                            </label>
                          </div>
                          <div className="col-span-6">
                            <label className="text-[12px] font-bold">
                              Locale
                            </label>
                          </div>
                        </div>

                        <FieldArray
                          name="access_level"
                          render={(arrayHelpers) => (
                            <>
                              {values.access_level.map((row, index) => (
                                <div
                                  className="grid grid-cols-12 gap-4 my-1 w-full items-start"
                                  key={row.id}
                                >
                                  {/* {console.log(row, "row")} */}
                                  <div className="col-span-5">
                                    <SelectDropDown
                                      options={accessLevelOptions}
                                      placeholder="select"
                                      value={
                                        accessLevelOptions?.find(
                                          (item) =>
                                            item?.value ===
                                            values.access_level[index]
                                              .access_level_id
                                        ) || null
                                      }
                                      onChange={(selectedOption) => {
                                        const selectedAccess =
                                          selectedOption?.value || "";

                                        setFieldValue(
                                          `access_level[${index}].access_level_id`,
                                          selectedAccess
                                        );
                                        setFieldValue(
                                          `access_level[${index}].locale`,
                                          []
                                        );
                                      }}
                                      touched={touched}
                                      errors={errors}
                                      isSearchable={true}
                                      isClearable={false}
                                    />

                                    {/* <Select
                                      blurInputOnSelect
                                      options={accessLevelOptions}
                                      placeholder="select"
                                      value={
                                        accessLevelOptions?.find(
                                          (item) =>
                                            item?.value ===
                                            values.access_level[index]
                                              .access_level_id
                                        ) || null
                                      }
                                      onChange={(selectedOption) => {
                                        const selectedAccess =
                                          selectedOption?.value || "";
                                        setFieldValue(
                                          `access_level[${index}].access_level_id`,
                                          selectedAccess
                                        );
                                        setFieldValue(
                                          `access_level[${index}].locale`,
                                          []
                                        );
                                      }}
                                      styles={{
                                        control: (provided, state) => ({
                                          ...provided,
                                          border: state.isFocused
                                            ? "2px solid #B0E0FF"
                                            : "2px solid #B0E0FF",
                                          borderRadius: "12px",
                                          height: "44px",
                                          boxShadow: state.isFocused
                                            ? "0 0 0 2px rgba(176, 224, 255, 0.5)"
                                            : "none",
                                          "&:hover": {
                                            borderColor: "#B0E0FF",
                                          },
                                        }),
                                      }}
                                    /> */}
                                  </div>
                                  <div className="col-span-6">
                                    {row?.access_level_id ===
                                    accessLevelIds?.Pincode ? (
                                      <>
                                        <MultipleTagsInput
                                          name={`access_level[${index}].locale`}
                                          placeHolder="Enter locale"
                                          value={
                                            values.access_level[index]
                                              ?.locale || []
                                          }
                                          onChange={(newLocales) =>
                                            setFieldValue(
                                              `access_level[${index}].locale`,
                                              newLocales
                                            )
                                          }
                                        />
                                      </>
                                    ) : (
                                      <Multiselect
                                        groupBy="group"
                                        options={getLocaleOptions(
                                          row.access_level_id
                                        )}
                                        displayValue="label"
                                        onSelect={(selectedList) =>
                                          setFieldValue(
                                            `access_level[${index}].locale`,
                                            selectedList.map(
                                              (item) => item.value
                                            )
                                          )
                                        }
                                        onRemove={(selectedList) =>
                                          setFieldValue(
                                            `access_level[${index}].locale`,
                                            selectedList.map(
                                              (item) => item.value
                                            )
                                          )
                                        }
                                        selectedValues={getLocaleOptions(
                                          row.access_level_id
                                        ).filter((option) =>
                                          row.locale.includes(option.value)
                                        )}
                                        showCheckbox
                                        // showArrow
                                        style={{
                                          multiselectContainer: {
                                            padding: "0px",
                                            border: "2px solid #B0E0FF",
                                            borderRadius: "12px",
                                          },
                                          searchBox: {
                                            border: "none",
                                            // height: "44px",
                                            overflowY: "scroll",
                                            scrollbarWidth: "none",
                                            msOverflowStyle: "none",
                                          },

                                          chips: {
                                            backgroundColor: "#B0E0FF",
                                            color: "black",
                                          },
                                        }}
                                      />
                                    )}
                                  </div>
                                  {values.access_level.length > 1 && (
                                    <div
                                      className="flex justify-start items-center col-span-1 cursor-pointer h-[44px]"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <Delete width={24} height={24} />
                                    </div>
                                  )}
                                </div>
                              ))}
                              <button
                                type="button"
                                onClick={() =>
                                  arrayHelpers.push({
                                    id: Date.now(),
                                    access_level_id: "",
                                    locale: [],
                                  })
                                }
                              >
                                Add Row
                              </button>
                            </>
                          )}
                        />
                      </div>
                    </>
                  )}
                  <div className="w-full">
                    <label className="text-[14px] font-bold">User Status</label>
                    <div className="flex items-center space-x-4">
                      <Switch
                        ripple={false}
                        checked={userStatus}
                        onChange={() => {
                          setUserStatus(!userStatus);
                          setFieldValue(UserFields.IS_ACTIVE, !userStatus);
                        }}
                        value={values[UserFields.IS_ACTIVE]}
                        className="h-full w-full checked:bg-[#B0E0FF]"
                        containerProps={{
                          className: "w-8 h-4",
                        }}
                        circleProps={{
                          className:
                            "before:hidden left-1 border-none w-[12px] h-[12px]",
                        }}
                      />
                      <span>{userStatus ? "Active" : "Inactive"}</span>
                    </div>
                  </div>

                  <div className="w-full flex justify-end space-x-4 h-[48px]">
                    <button
                      className="bg-black text-white px-4 text-[18px] py-2 font-bold rounded-lg min-w-[32%] h-full"
                      onClick={(e) => {
                        e.preventDefault();
                        resetForm();
                      }}
                    >
                      Reset
                    </button>
                    <button
                      className="text-white px-4 py-2 rounded-md bg-WildWatermelon-button w-[32%] h-full font-bold"
                      type="submit"
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export { AddUserModel };
