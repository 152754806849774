/**
 * All Camp list Screen Page
 * @flow
 * @format
 */

import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { NoRecord } from "../../../components/noRecords";
import { HeaderScreen, Sidebar } from "../../../components";
import UpcomingCampsCard from "../../dashboard/components/UpcomingCampsCard";
import { Images } from "../../../constants";
import { Date } from "../../../assets/icons/Date";
import { Clock } from "../../../assets/icons/Clock";
import { MultipleUser } from "../../../assets/icons/MultipleUser";
import { Location } from "../../../assets/icons/Location";
import { Link } from "react-router-dom";
import { LeftArrow } from "../../../assets/icons/LeftArrow";
import { RightArrow } from "../../../assets/icons/RightArrow";
import { CalendarNew } from "../../../assets/icons/Calender";
import { LocationMap } from "../../../assets/icons/LocationMap";
import { ArrowIcon } from "../../../assets/icons/Arrow";

const MyCampsDashboardScreen = () => {
  const dispatch = useDispatch();
  const [currentCampIndex, setCurrentCampIndex] = useState(0);

  // functions
  //   const getData = () => {
  //     dispatch(
  //       allScheduleCampList({
  //         search: searchData,
  //         filter,
  //         page: page,
  //         perPage: perPage,
  //         onSuccess: (props) => {
  //           console.log("Camp Center loaded successfully");
  //         },
  //       })
  //     );
  //   };

  //   useEffect(() => {
  //     getData();
  //   }, []);

  const pastCamps = [
    {
      title: "Assessment Camp",
      schoolName: "Aradhya High School",
      address: "Beside Anand Temple, Dist-Tiruvallur.",
      stage: "Check-in",
      counterNo: 2,
      dates: "20th - 21st, April , 2024",
      time: "9.00 Am - 06.00 Pm",
      beneficiaries: 150,
      contactPerson: "Vijaya Kumar SR",
      contactNumber: "93654 98533",
    },
    {
      title: "Assessment Camp",
      schoolName: "Aradhya High School",
      address: "Beside Anand Temple, Dist-Tiruvallur.",
      stage: "Check-in",
      counterNo: 2,
      dates: "20th - 21st, April , 2024",
      time: "9.00 Am - 06.00 Pm",
      beneficiaries: 150,
      contactPerson: "Vijaya Kumar SR",
      contactNumber: "93654 98533",
    },
  ];

  const upcomingCamps = [
    {
      title: "Assessment Camp",
      schoolName: "Aradhya High School",
      address: "Beside Anand Temple, Dist-Tiruvallur.",
      stage: "Check-in",
      counterNo: 2,
      dates: "20th - 21st, April , 2024",
      time: "9.00 Am - 06.00 Pm",
      beneficiaries: 150,
      contactPerson: "Vijaya Kumar SR",
      contactNumber: "93654 98533",
    },
    {
      title: "Assessment Camp",
      schoolName: "Aradhya High School",
      address: "Beside Anand Temple, Dist-Tiruvallur.",
      stage: "Check-in",
      counterNo: 2,
      dates: "20th - 21st, April , 2024",
      time: "9.00 Am - 06.00 Pm",
      beneficiaries: 150,
      contactPerson: "Vijaya Kumar SR",
      contactNumber: "93654 98533",
    },
  ];

  const today_camps = [
    {
      title: "Assessment Camp",
      schoolName: "Aradhya High School",
      address: "Beside Anand Temple, Dist-Tiruvallur.",
      stage: "Check-in",
      counterNo: 2,
      dates: "20th - 21st, April , 2024",
      time: "9.00 Am - 06.00 Pm",
      beneficiaries: 150,
      contactPerson: "Vijaya Kumar SR",
      contactNumber: "93654 98533",
    },
    {
      title: "Assessment Camp",
      schoolName: "Aradhya High School",
      address: "Beside Anand Temple, Dist-Tiruvallur.",
      stage: "Check-in",
      counterNo: 2,
      dates: "20th - 21st, April , 2024",
      time: "9.00 Am - 06.00 Pm",
      beneficiaries: 150,
      contactPerson: "Vijaya Kumar SR",
      contactNumber: "93654 98533",
    },
  ];

  const todayTotalCamps = today_camps.length || [];

  const handleNextCamp = () => {
    setCurrentCampIndex((prevIndex) =>
      prevIndex < todayTotalCamps - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const handlePrevCamp = () => {
    setCurrentCampIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  const currentCamp = today_camps[currentCampIndex];

  return (
    <>
      <div className="h-screen min-w-screen">
        <HeaderScreen />
        <div className="flex h-[calc(100vh-65px)]">
          <Sidebar />
          {/* w-[calc(100vw-90px)] */}
          <div className="flex flex-col h-full p-4 bg-customBlue-mainBackground w-screen">
            <div className="container min-h-[80px] min-w-full grid grid-cols-12 gap-4 items-start justify-between h-full">
              {/* Left Side */}
              <div className="col-span-9 flex flex-col bg-none rounded-lg font-raleway space-y-4 overflow-y-auto scrollbar-hide h-full">
                {/* Today's camps */}
                <div className="w-full bg-none rounded-xl p-0">
                  <div className="flex flex-row md:flex-wrap space-x-4 justify-between items-center mb-2 gap-2">
                    <h3 className="text-[20px] font-bold">
                      Today’s Camps for me
                    </h3>
                    {todayTotalCamps > 0 ? (
                      <div className="text-sm whitespace-nowrap">
                        <button
                          onClick={handlePrevCamp}
                          disabled={currentCampIndex === 0}
                          className=" font-bold"
                        >
                          <LeftArrow width={6} height={11} />
                        </button>
                        <span className="px-2 text-[14px]">{`${
                          currentCampIndex + 1
                        } / ${todayTotalCamps}`}</span>
                        <button
                          onClick={handleNextCamp}
                          disabled={currentCampIndex === todayTotalCamps - 1}
                          className=" font-bold"
                        >
                          <RightArrow width={6} height={11} />
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {todayTotalCamps > 0 ? (
                    <>
                      <div className="flex flex-col justify-between items-center p-2 px-4 bg-white rounded-xl border-2 border-customBlue-border gap-3">
                        <div className="flex justify-between w-full items-center">
                          <h3 className="font-bold">First Assessment Camp</h3>
                          <div className="flex gap-3">
                            <span className="font-semibold">
                              Counter No:{" "}
                              <span className="text-[#FF5976] font-bold">
                                2
                              </span>
                            </span>
                            <span className="font-semibold">
                              Stage:{" "}
                              <span className="text-[#FF5976] font-bold">
                                {" "}
                                Screening
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="flex flex-wrap w-full gap-2 text-[14px]">
                          <div className="border-2 border-gray-100 flex-1 p-2 rounded-lg">
                            <div className="flex lg:flex-wrap justify-between gap-2 w-full items-center">
                              <h3 className="font-bold whitespace-nowrap">
                                Camp Center:
                              </h3>
                              <div className="flex gap-1 flex-wrap items-center">
                                <span className="font-semibold">
                                  Daljeet Lodha Daljeet Lodha{" "}
                                  <span className="text-gray-text-divider">
                                    |
                                  </span>
                                </span>

                                <span className="font-semibold flex gap-2 items-center">
                                  <LocationMap
                                    width={16}
                                    height={18}
                                    fillColor={"#00000080"}
                                  />
                                  Beside Anand Temple,Dist-Tiruvallur.
                                  <div>
                                    <a
                                      href=""
                                      className="text-[#256CD0] font-semibold underline"
                                    >
                                      View Map
                                    </a>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-col justify-between items-center p-2 px-4 bg-white rounded-lg border-2 border-gray-100 ">
                            <div className="flex justify-between w-full items-center">
                              <div className="flex flex-col gap-3">
                                <div className="font-semibold">
                                  <span className="font-bold me-3">
                                    Camp Type:
                                  </span>
                                  Assessment Camp
                                </div>
                                <div className="font-bold">
                                  Camp Code:{" "}
                                  <span className="text-[#FF5976] ms-3">
                                    {" "}
                                    EXY1234D
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-wrap w-full gap-2 text-[14px]">
                          <div className="flex-1 p-2 rounded-lg bg-[#EFF9FF]">
                            <div className="flex lg:flex-wrap justify-between gap-2 w-full items-center">
                              <span className="font-semibold">
                                Scheduled Beneficiaries{" "}
                                <span className="text-[#256CD0] py-1 px-2 bg-[#DDF1FE] rounded font-bold">
                                  20
                                </span>
                              </span>

                              <span className="font-semibold flex gap-2 items-center">
                                <CalendarNew
                                  width={16}
                                  height={18}
                                  fillColor={"#00000080"}
                                />
                                Apr 21, 2024
                              </span>
                              <span className="font-semibold flex gap-2 items-center">
                                <Clock
                                  width={16}
                                  height={18}
                                  fillColor={"#00000080"}
                                />
                                9.00 Am - 06.00 Pm
                              </span>
                            </div>
                          </div>

                          <button className="p-2 px-4 bg-[#FF5976] text-white rounded-lg font-semibold">
                            Login
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    // <div className="border-2 border-gray-100 bg-white p-2">
                    //   Today camps
                    // </div>
                    <div className="flex flex-col justify-start items-center space-y-6 ">
                      <span className="text-center text-[1rem] font-medium">
                        There isn’t any Today Camp(s) for you!
                      </span>
                    </div>
                  )}
                </div>
                {/* Upcoming Camps */}
                <div className="w-full bg-none rounded-xl p-0">
                  <div className="flex flex-row md:flex-wrap space-x-4 justify-between items-center mb-2 gap-2">
                    <h3 className="text-[20px] font-bold">
                      Upcoming Camps for me
                    </h3>
                    <div className="text-sm whitespace-nowrap">30 Camps</div>
                  </div>
                  {upcomingCamps.length > 0 ? (
                    <div className="flex flex-col space-y-4 ">
                      {upcomingCamps?.map((item, upcomingCampIndex) => (
                        <>
                          <div className="flex flex-col justify-between items-center p-2 px-4 bg-white rounded-xl border-2 border-customBlue-border gap-3">
                            <div className="flex justify-between w-full items-center">
                              <h3 className="font-bold">
                                First Assessment Camp
                              </h3>
                              <div className="flex gap-3">
                                <span className="font-semibold">
                                  Counter No:{" "}
                                  <span className="text-[#FF5976] font-bold">
                                    2
                                  </span>
                                </span>
                                <span className="font-semibold">
                                  Stage:{" "}
                                  <span className="text-[#FF5976] font-bold">
                                    {" "}
                                    Screening
                                  </span>
                                </span>
                              </div>
                            </div>
                            <div className="flex flex-wrap w-full gap-2 text-[14px]">
                              <div className="border-2 border-gray-100 flex-1 p-2 rounded-lg">
                                <div className="flex lg:flex-wrap justify-between gap-2 w-full items-center">
                                  <h3 className="font-bold whitespace-nowrap">
                                    Camp Center:
                                  </h3>
                                  <div className="flex gap-1 flex-wrap items-center">
                                    <span className="font-semibold">
                                      Daljeet Lodha Daljeet Lodha{" "}
                                      <span className="text-gray-text-divider">
                                        |
                                      </span>
                                    </span>

                                    <span className="font-semibold flex gap-2 items-center">
                                      <LocationMap
                                        width={16}
                                        height={18}
                                        fillColor={"#00000080"}
                                      />
                                      Beside Anand Temple,Dist-Tiruvallur.
                                      <div>
                                        <a
                                          href=""
                                          className="text-[#256CD0] font-semibold underline"
                                        >
                                          View Map
                                        </a>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="flex flex-col justify-between items-center p-2 px-4 bg-white rounded-lg border-2 border-gray-100 ">
                                <div className="flex justify-between w-full items-center">
                                  <div className="flex flex-col gap-3">
                                    <div className="font-semibold">
                                      <span className="font-bold me-3">
                                        Camp Type:
                                      </span>
                                      Assessment Camp
                                    </div>
                                    <div className="font-bold">
                                      Camp Code:{" "}
                                      <span className="text-[#FF5976] ms-3">
                                        {" "}
                                        EXY1234D
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="flex flex-wrap w-full gap-2 text-[14px]">
                              <div className="flex-1 p-2 rounded-lg bg-[#EFF9FF]">
                                <div className="flex lg:flex-wrap justify-between gap-2 w-full items-center">
                                  <span className="font-semibold">
                                    Scheduled Beneficiaries{" "}
                                    <span className="text-[#256CD0] py-1 px-2 bg-[#DDF1FE] rounded font-bold">
                                      20
                                    </span>
                                  </span>

                                  <span className="font-semibold flex gap-2 items-center">
                                    <CalendarNew
                                      width={16}
                                      height={18}
                                      fillColor={"#00000080"}
                                    />
                                    Apr 21, 2024
                                  </span>
                                  <span className="font-semibold flex gap-2 items-center">
                                    <Clock
                                      width={16}
                                      height={18}
                                      fillColor={"#00000080"}
                                    />
                                    9.00 Am - 06.00 Pm
                                  </span>
                                </div>
                              </div>

                              <button className="flex flex-row justify-between items-center space-x-2 p-2 px-4 bg-[#4AB8FF] text-white rounded-lg font-semibold">
                                <span>View Beneficiaries</span>
                                <ArrowIcon
                                  width={6}
                                  height={10}
                                  // isRotated={isRotated}
                                  fillColor={"#ffffff"}
                                />
                              </button>
                            </div>
                          </div>
                        </>
                        // <div className="border-2 border-gray-100 p-2 bg-white">
                        //   {item?.title}
                        // </div>
                      ))}
                    </div>
                  ) : (
                    <div className="flex flex-col justify-start items-center space-y-6 ">
                      <span className="text-center text-[1rem] font-medium">
                        There isn’t any Today Camp(s) for you!
                      </span>
                    </div>
                  )}
                </div>
              </div>
              {/* Right Side */}
              <div className="col-span-3 flex flex-col space-y-4 justify-start font-raleway text-[24px] h-full overflow-y-auto scrollbar-hide">
                {/* my Past Camps */}
                <div className="w-full bg-white p-6 border-2 border-gray-100">
                  <div className="flex flex-row md:flex-wrap space-x-4 justify-between items-center mb-2 gap-2">
                    <h3 className="text-[20px] font-bold">My Past Camps</h3>
                  </div>
                  {pastCamps.length > 0 ? (
                    pastCamps?.map((camp, campIndex) => (
                      <div
                        className="rounded-xl mb-4 p-4 border border-gray-100"
                        key={campIndex}
                      >
                        <div className="py-0 border-b ">
                          <h4 className="text-[16px] font-bold mb-2">
                            {camp?.title}
                          </h4>
                        </div>
                        <div className="flex flex-row justify-between items-center text-sm text-gray-700 mb-2 py-2">
                          <strong>{camp?.schoolName}</strong>
                          <Link href="#" className="text-blue-500 underline">
                            View Map
                          </Link>
                        </div>
                        <div className="flex flex-row space-x-2 items-center">
                          <Location
                            height={13}
                            width={10}
                            fillColor={"#000000"}
                          />
                          <p className="text-sm">{camp?.address}</p>
                        </div>
                        <div className="flex flex-row flex-wrap justify-start items-center bg-gray-100 p-0 rounded-xl my-4 text-[14px]">
                          <span className="p-2 flex-1">
                            Stage: <strong>{camp?.stage}</strong>
                          </span>
                          <span className="p-2 flex-1">
                            Counter No: <strong>{camp?.counterNo}</strong>
                          </span>
                        </div>
                        <div className="text-sm text-gray-700">
                          <div className="flex items-center mb-2">
                            <span
                              role="img"
                              aria-label="calendar"
                              className="mr-2"
                            >
                              <Date width={18} height={18} />
                            </span>
                            {camp?.dates}
                          </div>
                          <div className="flex items-center mb-2">
                            <span
                              role="img"
                              aria-label="clock"
                              className="mr-2"
                            >
                              <Clock width={16} height={17} />
                            </span>
                            {camp?.time}
                          </div>
                          <div className="flex items-center mb-4">
                            <span
                              role="img"
                              aria-label="users"
                              className="mr-2"
                            >
                              <MultipleUser width={18} height={20} />
                            </span>
                            {camp?.beneficiaries} Beneficiaries
                          </div>
                          {/* <div>
                          <strong className="text-[14px] font-bold underline ">
                            Contact Person
                          </strong>
                          <p>
                            {contactPerson},{" "}
                            <span className="underline text-[14px] font-bold">
                              {contactNumber}
                            </span>
                          </p>
                        </div> */}
                        </div>{" "}
                      </div>
                    ))
                  ) : (
                    <div className="flex flex-col justify-start items-center space-y-6 ">
                      <img
                        src={Images.upcomingCampImage}
                        alt="Upcoming Camp"
                        className="w-full h-full "
                      />
                      <span className="text-center text-[1rem] font-medium">
                        There isn’t any Past Camp(s) for you!
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { MyCampsDashboardScreen };
