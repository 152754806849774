/**
 * Routes Constants
 * @format
 */

// Auth Routes
export const AuthRoutes = {
  LoginScreen: "/login",
};

// Routes
export const MainRoutes = {
  DashboardScreen: "/",
};

//Beneficiary Routes
export const BeneficiaryRoutes = {
  BeneficiaryVerification: "/beneficiaryVerification",
  BeneficiaryList: "/beneficiaryList",
};

//User Routes
export const UserRoutes = {
  UserList: "/userList",
};

//Role Routes
export const RoleRoutes = {
  RoleList: "/roleList",
};

//Camp Routes
export const CampsRoutes = {
  CampsList: "/scheduleCampsList",
  MyCamp: "/myCamp",
  MyCampList: "/myCampList",
};

//Camp Center Routes
export const CampCentersRoutes = {
  CampCentersList: "/campCentersList",
};

//Camp Center Routes
export const CampOverviewRoutes = {
  CampOverviewList: "/campOverview",
};
