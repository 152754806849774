/**
 * Beneficiary Detail Components
 * @format
 */

import React from "react";
import { Link } from "react-router-dom";
import { Email } from "../../../assets/icons/Email";
import { Telephone } from "../../../assets/icons/Call";
import { constants } from "../../../utils";

const BeneficiaryDetail = (props) => {
  const user = props.userData;

  return (
    <>
      <div className="container bg-white p-0  mb-3 font-DM">
        <div className="flex flex-1 justify-between w-full items-start leading-normal text-xs mb-2 ">
          <div className="">
            <span className="text-gray-text-600 font-bold text-[18px] font-raleway">
              Beneficiary details
            </span>{" "}
          </div>
          <div className="text-green-600 text-right">
            <Link
              to="/your-link-url"
              className="text-activeLink underline ml-2 font-medium text-[13px] font-raleway"
            >
              {constants.Communication_history}
            </Link>
          </div>
        </div>
        <div className="container flex flex-row justify-between gap-[24px] ">
          {/* <div className=""> */}
          <img
            src={user?.profile_picture}
            alt=""
            className=" rounded-xl min-w-[80px] w-[80px] max-h-[80px] h-[80px]"
          />
          {/* </div> */}
          <div className="flex flex-col w-full justify-between gap-[13px]">
            <div className="flex w-full items-start justify-between">
              <div className="font-semibold text-[16px] capitalize font-raleway">
                {user.name}{" "}
                <span className="text-gray-500 font-semibold">
                  {" "}
                  <span className="text-gray-text-divider">|</span>{" "}
                  {constants.age}
                </span>{" "}
                {user.age}; {user.gender}
              </div>
              {/* adhar numb */}
              <div className="font-semibold text-[16px] capitalize font-raleway">
                <span className="text-gray-500 font-semibold">
                  {" "}
                  Aadhar No. :
                </span>{" "}
                {user.aadhar}
              </div>
            </div>
            <div className="flex flex-row w-full justify-between gap-[10px] ">
              <div className="flex flex-col justify-between w-full gap-[7px] ">
                <div className=" text-gray-600 font-semibold text-[14px] ">
                  <span>{constants.registration_no} </span>{" "}
                  <span className="text-black">
                    {user?.registration_number}
                  </span>
                </div>
                <div className=" text-gray-600 font-semibold text-[14px]">
                  <span>{constants.disability_type} </span>{" "}
                  <span className="text-black">
                    {user?.disability_type_id?.name}
                  </span>
                </div>
              </div>
              <div className="flex flex-col justify-between text-xs gap-[7]">
                <div className="text-left flex items-center text-[15px]">
                  <span className="mr-2">
                    <Email width={20} height={20} />
                  </span>
                  <span className="overflow-scroll scrollbar-hide font-[600] font-DM">
                    {user?.email}
                  </span>
                </div>
                <div className="flex items-center text-[15px]">
                  <span className="text-gray-500 mr-2">
                    <Telephone width={20} height={20} />
                  </span>
                  <span className="font-medium font-DM whitespace-nowrap text-activeLink">
                    +91 {user?.mobile_number}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BeneficiaryDetail;
