/**
 * Single Date Picker Component
 * @format
 */

import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const SingleDatePickerComponent = ({
  name,
  value,
  setStartDate,
  placeholderText = "Select date range",
  isClearable = true,
  dateFormat = "dd-MMM-yyyy",
  className = "w-full h-[44px] rounded-xl text-[14px] border-[2px] px-2 border-customBlue-border placeholder-black",
}) => {
  const formatDate = (date) => {
    if (!date) return null;

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  };

  const parseDate = (dateString) => {
    if (!dateString) return null;

    const [year, month, day] = dateString.split("-");
    return new Date(year, month - 1, day);
  };

  const handleDateChange = (date) => {
    setStartDate(name, date ? formatDate(date) : "");
  };

  return (
    <DatePicker
      name={name}
      selected={parseDate(value)}
      onChange={handleDateChange}
      placeholderText={placeholderText}
      className={className}
      isClearable={isClearable}
      dateFormat={dateFormat}
    />
  );
};

export default SingleDatePickerComponent;
