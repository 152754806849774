/**
 * Show Heading Component
 * @flow
 * @format
 */

import React from "react";

const HeadingComponent = (props) => {
  const { name, total, colors } = props;

  return (
    <>
      <div
        className={` p-4 h-max shadow-sm flex overflow-hidden rounded-xl w-full justify-between gap-1 items-center`}
        style={{ backgroundColor: colors?.background }}
      >
        <div
          className={`text-[1rem] font-bold`}
          style={{ color: colors?.text }}
        >
          {name}
        </div>
        <div className="bg-[white] rounded-md px-[6px] py-[2px]">
          <span className={` font-bold`} style={{ color: colors?.text }}>
            {total}
          </span>
        </div>
      </div>
    </>
  );
};

export default HeadingComponent;
