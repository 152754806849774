/**
 * No record Message Component
 * @format
 */

import React from "react";
import { Images } from "../constants";

function NoRecord(props) {
  const { title_1, title_2 } = props;
  return (
    <div className="px-4 text-center ">
      <img src={Images.noRecord} alt="Logo" className="max-w-[25rem]" />
      <div className="text-[1.4rem] font-bold mt-5">{title_1}</div>
      <div className="text-[1.15rem]">{title_2}</div>
    </div>
  );
}

export { NoRecord };
