/**
 * View All Next Count Details Model
 * @format
 */

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Cross } from "../../../assets/icons/Cross";
import { Formik } from "formik";
import BeneficiaryJourney from "../../beneficiary_management/components/beneficiaryJourney";
import PersonalDetails from "../../beneficiary_management/components/personalDetails";
import BeneficiaryAllDetail from "../../beneficiary_management/components/beneficiaryAllDetail";
import { searchBeneficiary } from "../slice";
import { getBeneficiaryDetailById } from "../../beneficiary_management/slice";
import { selectBeneficiaryDetails } from "../../beneficiary_management/selector";
import { Link } from "react-router-dom";
import { Email } from "../../../assets/icons/Email";
import { Telephone } from "../../../assets/icons/Call";
import BeneficiaryDetailWithCurrentStatus from "../../beneficiary_management/components/beneficiaryDetailWithCurrentStatus";

const LookUpBeneficaryModels = ({ toggleModal }) => {
  const [beneficiaryDetailToggle, setBeneficiaryDetailToggle] = useState(false);
  const [beneficiary, setBeneficiary] = useState(null);
  const scrollableRef = useRef(null);
  const beneficiaryData = beneficiary || {};
  // const beneficiaryData = useSelector(selectBeneficiaryDetails) || {};
  const dispatch = useDispatch();

  const initialValues = {
    search: "",
  };

  useEffect(() => {
    if (
      scrollableRef.current &&
      typeof scrollableRef.current.scrollTo === "function"
    ) {
      scrollableRef.current.scrollTo(0, 0);
    }
  }, [beneficiaryData]);

  return (
    <div className="fixed h-full inset-0 z-50 flex justify-end">
      <div className="fixed inset-0 bg-[#07284B] opacity-50"></div>
      <div className="flex flex-col relative w-[55%] h-full bg-white shadow-lg overflow-y-auto scrollbar-hide">
        <div className="flex justify-between items-center bg-customBlue-mainBackground w-full h-[11%] p-5">
          <h2 className="text-[20px] font-bold">Lookup Beneficiary</h2>
          <button onClick={toggleModal} className="text-xl font-semibold">
            <Cross width={32} height={32} />
          </button>
        </div>
        {/* Modal content */}
        <div className="w-full flex flex-col flex-auto justify-start space-y-4 py-6 px-6">
          {/* Search Input fields */}
          <div className="container p-0 text-black">
            <Formik
              initialValues={initialValues}
              validationSchema={Yup.object().shape({
                search: Yup.string()
                  .required("Reg. Number Or Mobile Number are required")
                  .max(10, "cannot exceed 10 characters"),
              })}
              onSubmit={(values, actions) => {
                const { search } = values;
                dispatch(
                  searchBeneficiary({
                    search,
                    onSuccess: (props) => {
                      console.log(props?.data);
                      setBeneficiary(props?.data);
                      setBeneficiaryDetailToggle(true);
                      actions.resetForm();
                      // dispatch(
                      //   getBeneficiaryDetailById({
                      //     id: props?.data,
                      //     onSuccess: () => {
                      //       setBeneficiaryDetailToggle(true);
                      //       actions.resetForm();
                      //     },
                      //   })
                      // );
                    },
                  })
                );
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="container p-0 flex justify-start items-center h-full">
                    <div className="flex flex-col gap-1.5 w-full">
                      <label className="text-[14px] font-bold">
                        Beneficiary Reg. no / Mobile no
                      </label>
                      <div className="relative h-[40px] rounded-xl w-full">
                        <button
                          type="submit"
                          className="absolute inset-y-0 end-6 flex text-[14px] items-center ps-5 h-full underline text-activeLink decoration-activeLink"
                        >
                          Lookup
                        </button>
                        <input
                          autoComplete="off"
                          type="text"
                          maxLength={10}
                          name="search"
                          id="search"
                          onChange={(e) => {
                            handleChange({
                              target: {
                                name: e.target.name,
                                value: e.target.value.toUpperCase(),
                              },
                            });
                          }}
                          value={values.search}
                          onBlur={handleBlur}
                          placeholder="Enter here"
                          className="block w-full h-full p-4 text-[14px] font-DM rounded-xl search-bar border border-customBlue-border placeholder-black"
                        />
                        {touched.search && errors.search && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors.search}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>

          {/* Beneficiary Details */}
          <div className="flex justify-center items-center w-full overflow-y-auto scrollbar-hide px-0">
            {beneficiaryDetailToggle &&
            Object.keys(beneficiaryData).length > 0 ? (
              beneficiaryData?.status === "Verified" ? (
                <div className="container p-0 h-full self-end w-full ">
                  <div
                    className="container h-full overflow-y-scroll scrollbar-hide space-y-6 flex flex-col bg-white px-4 py-4 max-w-full w-full rounded-xl"
                    // ref={scrollableRef}
                  >
                    {/* Beneficiary Detail */}
                    <BeneficiaryDetailWithCurrentStatus
                      userData={beneficiaryData}
                    />

                    {/* Personal Detail */}
                    <div className="container p-0 flex flex-col justify-between max-w-full w-full  ">
                      <PersonalDetails userData={beneficiaryData} />
                    </div>
                    {/* Beneficiary Journey */}
                    <div className="container p-0 flex flex-col justify-between items-center max-w-full w-full  ">
                      <BeneficiaryJourney beneficiary={beneficiaryData} />
                    </div>
                  </div>
                </div>
              ) : (
                <BeneficiaryAllDetail
                  singleBeneficiary={beneficiaryData}
                  data={beneficiaryData}
                  bySearch={true}
                />
              )
            ) : (
              <div className="flex justify-center items-center container pr-4 pt-2 h-full self-end w-full bg-white rounded-lg"></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { LookUpBeneficaryModels };
