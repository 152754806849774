/**
 *Assign User for Camp Screen Page
 * @flow
 * @format
 */

import React, { useEffect, useState } from "react";
import { Cross } from "../../../assets/icons/Cross";
import DataTable from "react-data-table-component";
import { CampFields } from "../../../constants";
import { Formik } from "formik";
import Select from "react-select";
import ScheduleCampDetailCardComponent from "../component/scheduleCampDetailCard";
import {
  assignUsertoCampCounters,
  getScheduleCampCountersByScheduleCampId,
} from "../slice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  selectScheduleCampCurrentPage,
  selectScheduleCampFilter,
  selectScheduleCampRespPerPage,
  selectScheduleCampSearchQuery,
} from "../selector";
import { getallUsersOptions, selectAllUserOptions } from "../../common";
import SelectDropDown from "../../../components/selectDropDown";

const AssignUserForCampModel = ({ toggleModal, rowData, getData }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const initialValues = {
    [CampFields.COUNTER_ID]: "",
    [CampFields.USER_ID]: "",
  };

  const scheduleCampSearchQuery = useSelector(selectScheduleCampSearchQuery);
  const scheduleCampCurrentPage = useSelector(selectScheduleCampCurrentPage);
  const scheduleCampPerPage = useSelector(selectScheduleCampRespPerPage);
  const scheduleCampFilter = useSelector(selectScheduleCampFilter);

  const { data: usersOptionData = [] } =
    useSelector(selectAllUserOptions) || {};

  const usersOptions = usersOptionData?.map((item) => ({
    label: (
      <div className="w-full flex flex-col justify-center items-start space-y-2 ">
        <div className="!font-bold text-[14px] text-black">{item?.name}</div>
        <div className="font-medium text-[14px]">
          {item?.political_district_name}, {item?.political_state_name}
        </div>
        <div className="font-medium text-[14px]">
          Contact: +91 {item?.mobile_number}
        </div>
      </div>
    ),
    value: item?._id,
  }));

  const [initialObject, setInitialObject] = useState(initialValues);

  const columnStyle = {
    fontSize: "14px",
    fontWeight: "normal",
  };

  const getScheduleCampCounters = (scheduleCampId) => {
    dispatch(
      getScheduleCampCountersByScheduleCampId({
        scheduled_camp_id: scheduleCampId,
        onSuccess: (response) => {
          const { data: list = [] } = response;
          const data2 = list.map((item, index) => {
            return {
              ...item,
              [`user_id_${index}`]: item?.user_id || null, // Dynamic key based on index
            };
          });
          setData(data2);
        },
      })
    );
  };

  const getUserDopdownOption = () => {
    dispatch(
      getallUsersOptions({
        scheduled_camp_id: rowData?._id,
      })
    );
  };

  useEffect(() => {
    if (rowData?._id) {
      getScheduleCampCounters(rowData?._id);
    }
    getUserDopdownOption();
  }, [rowData]);

  return (
    <Formik
      initialValues={initialObject}
      enableReinitialize={true}
      onSubmit={(values) => {
        const updatedData = data.map((item, index) => {
          return {
            counter_id: item?._id,
            user_id: values[`user_id_${index}`] || item?.user_id,
          };
        });

        // Call your API with updatedData
        dispatch(
          assignUsertoCampCounters({
            counter_data: updatedData,
            onSuccess: (props) => {
              const { message, data } = props;
              toast.success(message);
              getData(
                scheduleCampSearchQuery,
                scheduleCampFilter,
                scheduleCampCurrentPage,
                scheduleCampPerPage
              );
              getScheduleCampCounters(rowData?._id);
            },
          })
        );
      }}
    >
      {({
        values,
        errors,
        handleChange,
        touched,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => {
        const columns = [
          {
            name: "#",
            selector: (row) => row?.has,
            style: columnStyle,
            maxWidth: "50px",
            width: "50px",
          },
          {
            name: "Stage",
            selector: (row) => row?.camp_step_id?.camp_step_name,
            style: columnStyle,
            maxWidth: "150px",
          },
          {
            name: "Counter No.",
            selector: (row) => row?.counter_number,
            center: true,
            style: columnStyle,
            maxWidth: "130px",
          },
          {
            name: "User",
            selector: (row, index) => row?.user_id,
            center: true,
            style: columnStyle,
            cell: (row, index) => (
              <>
                <div className="w-full">
                  <SelectDropDown
                    options={usersOptions}
                    name={`user_id_${index}`}
                    placeholder="Select"
                    onChange={(selectedOption) => {
                      // Update Formik state
                      setFieldValue(
                        `user_id_${index}`,
                        selectedOption?.value || ""
                      );

                      // Update the row's user_id value
                      const updatedRow = {
                        ...row,
                        [`user_id_${index}`]: selectedOption?.value || "",
                      };

                      const updatedData = data.map((item, i) =>
                        i === index ? updatedRow : item
                      );
                      setData(updatedData);
                    }}
                    value={
                      usersOptions.find(
                        (item) => item.value === row[`user_id_${index}`]
                      ) || null
                    }
                    touched={touched}
                    errors={errors}
                    isSearchable={true}
                    isClearable={false}
                  />
                  {/* <Select
                    options={usersOptions}
                    name={`user_id_${index}`}
                    placeholder="Select"
                    onChange={(selectedOption) => {
                      // Update Formik state
                      setFieldValue(
                        `user_id_${index}`,
                        selectedOption?.value || ""
                      );

                      // Update the row's user_id value
                      const updatedRow = {
                        ...row,
                        [`user_id_${index}`]: selectedOption?.value || "",
                      };

                      const updatedData = data.map((item, i) =>
                        i === index ? updatedRow : item
                      );
                      setData(updatedData);
                    }}
                    value={
                      usersOptions.find(
                        (item) => item.value === row[`user_id_${index}`]
                      ) || null
                    }
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        border: "2px solid #B0E0FF",
                        borderRadius: "12px",
                        width: "100%",
                        padding: "2px 0px 2px 0px",
                        boxShadow: state.isFocused
                          ? "0 0 0 2px rgba(176, 224, 255, 0.5)"
                          : "none",
                        "&:hover": {
                          borderColor: "#B0E0FF",
                        },
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#B0E0FF"
                          : provided.backgroundColor,
                        color: state.isSelected ? "#fff" : provided.color,
                      }),
                    }}
                  /> */}
                </div>
              </>
            ),
          },
        ];

        return (
          <form onSubmit={handleSubmit}>
            <div className="fixed h-full inset-0 z-50 flex justify-end bg-[#07284b80]">
              <div className="flex flex-col relative w-[45%] h-full bg-white shadow-lg overflow-y-auto">
                <div className="flex justify-between items-center bg-customBlue-mainBackground w-full h-[11%] p-5">
                  <h2 className="text-[20px] font-bold">Assign User</h2>
                  <button
                    onClick={toggleModal}
                    className="text-xl font-semibold"
                  >
                    <Cross width={32} height={32} />
                  </button>
                </div>
                <div className="w-full flex flex-col flex-auto space-y-4 py-6 px-6">
                  <div>
                    <ScheduleCampDetailCardComponent data={rowData} />
                  </div>
                  <div className="h-full">
                    <DataTable
                      columns={columns}
                      data={data}
                      customStyles={{
                        headCells: {
                          style: {
                            backgroundColor: "#DDF1FE",
                            color: "Black",
                            fontWeight: "bold",
                            fontSize: "14px",
                          },
                        },
                      }}
                    />
                    <div className="w-full flex justify-end space-x-4 mt-4">
                      <button
                        className="bg-[#C1C1C1] text-white px-4 text-[18px] py-2 font-bold rounded-lg min-w-[32%]"
                        onClick={(e) => {
                          e.preventDefault();
                          resetForm();
                        }}
                      >
                        Reset
                      </button>
                      <button
                        className="text-white px-4 py-2 rounded-md bg-WildWatermelon-button w-[32%] font-bold"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export { AssignUserForCampModel };
