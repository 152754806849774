import React from "react";

export const SpeechBubble = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M6.375 7.5C6.89277 7.5 7.3125 7.08027 7.3125 6.5625C7.3125 6.04473 6.89277 5.625 6.375 5.625C5.85723 5.625 5.4375 6.04473 5.4375 6.5625C5.4375 7.08027 5.85723 7.5 6.375 7.5Z"
          fill="#07284B"
        />
        <path
          d="M10.125 7.5C10.6428 7.5 11.0625 7.08027 11.0625 6.5625C11.0625 6.04473 10.6428 5.625 10.125 5.625C9.60723 5.625 9.1875 6.04473 9.1875 6.5625C9.1875 7.08027 9.60723 7.5 10.125 7.5Z"
          fill="#07284B"
        />
        <path
          d="M13.875 7.5C14.3928 7.5 14.8125 7.08027 14.8125 6.5625C14.8125 6.04473 14.3928 5.625 13.875 5.625C13.3572 5.625 12.9375 6.04473 12.9375 6.5625C12.9375 7.08027 13.3572 7.5 13.875 7.5Z"
          fill="#07284B"
        />
        <path
          d="M16.5 13.125H3.75C1.68225 13.125 0 11.4427 0 9.375V3.75C0 1.68225 1.68225 0 3.75 0H16.5C18.5677 0 20.25 1.68225 20.25 3.75V4.10808L23.4818 5.72395C23.7994 5.88281 24 6.20738 24 6.5625C24 6.91762 23.7994 7.24219 23.4818 7.40105L20.25 9.01692V9.375C20.25 11.4427 18.5677 13.125 16.5 13.125ZM3.75 1.875C2.71613 1.875 1.875 2.71613 1.875 3.75V9.375C1.875 10.4089 2.71613 11.25 3.75 11.25H16.5C17.5339 11.25 18.375 10.4089 18.375 9.375V8.4375C18.375 8.08237 18.5756 7.75781 18.8932 7.59895L20.9662 6.5625L18.8932 5.52605C18.5756 5.36719 18.375 5.04262 18.375 4.6875V3.75C18.375 2.71613 17.5339 1.875 16.5 1.875H3.75Z"
          fill="#07284B"
        />
        <path
          d="M20.25 24H7.5C5.43225 24 3.75 22.3178 3.75 20.25V19.8919L0.51825 18.276C0.200625 18.1172 0 17.7926 0 17.4375C0 17.0824 0.200625 16.7578 0.51825 16.599L4.26825 14.724C4.73152 14.4924 5.29453 14.6801 5.52605 15.1432C5.75756 15.6063 5.56992 16.1694 5.1068 16.401L3.03384 17.4375L5.1068 18.474C5.42438 18.6328 5.625 18.9574 5.625 19.3125V20.25C5.625 21.2839 6.46613 22.125 7.5 22.125H20.25C21.2839 22.125 22.125 21.2839 22.125 20.25V14.625C22.125 14.0703 21.8827 13.5476 21.4604 13.191C21.0648 12.8569 21.0149 12.2654 21.3489 11.8698C21.683 11.4742 22.2744 11.4244 22.6701 11.7584C23.5153 12.4721 24 13.5169 24 14.625V20.25C24 22.3178 22.3177 24 20.25 24Z"
          fill="#07284B"
        />
        <path
          d="M17.625 16.5H10.125C9.60722 16.5 9.1875 16.0803 9.1875 15.5625C9.1875 15.0447 9.60722 14.625 10.125 14.625H17.625C18.1428 14.625 18.5625 15.0447 18.5625 15.5625C18.5625 16.0803 18.1428 16.5 17.625 16.5Z"
          fill="#07284B"
        />
        <path
          d="M17.625 20.25H10.125C9.60722 20.25 9.1875 19.8303 9.1875 19.3125C9.1875 18.7947 9.60722 18.375 10.125 18.375H17.625C18.1428 18.375 18.5625 18.7947 18.5625 19.3125C18.5625 19.8303 18.1428 20.25 17.625 20.25Z"
          fill="#07284B"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2544">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
