/**
 * All Camp list Screen Page
 * @flow
 * @format
 */

import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { AssignBeneficiariesForCampModel } from "./assignBeneficiariesScreen";
import { AssignUserForCampModel } from "./assignUserScreen";
import { AddCampModel } from "./addCampScreen";
import { ListCampBeneficiary } from "./campBeneficiaryListScreen";
import { SetupStepFlowModel } from "./campFlowSetupScreen";
import { InstructionOfCampModel } from "./instructionsOfCampScreen";
import { HeaderScreen, Sidebar } from "../../../components";
import { AddCircle } from "../../../assets/icons/AddCircle";
import { SearchIcon } from "../../../assets/icons/SearchIcon";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
} from "@material-tailwind/react";
import { Dots } from "../../../assets/icons/Dots";
import NavigationBar from "../../beneficiary_management/components/navigationBar";
import { EditScheduleCampModel } from "./editCampScreen";
import {
  selectAllScheduleCampList,
  selectScheduleCampCurrentPage,
  selectScheduleCampFilter,
  selectScheduleCampRespPerPage,
  selectScheduleCampSearchQuery,
} from "../selector";
import { useSelector } from "react-redux";
import { allScheduleCampList, setScheduleCampSearchQuery } from "../slice";
import { NoRecord } from "../../../components/noRecords";

const ListCampModel = () => {
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState({});

  const {
    data = [],
    overAllCamps,
    totalUpcomingCamps,
    totalPastCamps,
  } = useSelector(selectAllScheduleCampList) || {};
  const currentPage = useSelector(selectScheduleCampCurrentPage);
  const perPage = useSelector(selectScheduleCampRespPerPage);
  const filter = useSelector(selectScheduleCampFilter);
  const scheduleCampSearchQuery = useSelector(selectScheduleCampSearchQuery);

  // const [
  //   isListCampBeneficiariesModalOpen,
  //   setIsListCampBeneficiariesModalOpen,
  // ] = useState(false);
  const [isAssignedUsersModelOpen, setIsAssignedUsersModelOpen] =
    useState(false);
  const [isAssignBeneficiariesModelOpen, setIsAssignBeneficiariesModelOpen] =
    useState(false);
  const [isInstructionCampModelOpen, setIsInstructionCampModelOpen] =
    useState(false);
  const [isStepsFlowCampModelOpen, setIsStepsFlowCampModelOpen] =
    useState(false);
  const [isAddCampModalOpen, setIsAddCampModalOpen] = useState(false);
  const [isEditScheduleCampModalOpen, setIsEditScheduleCampModalOpen] =
    useState(false);

  // const toggleListCampBeneficiariesModal = (row) => {
  //   setIsListCampBeneficiariesModalOpen(!isListCampBeneficiariesModalOpen);
  //   setSelectedRow(row);
  // };

  const toggleAddModal = () => {
    setIsAddCampModalOpen(!isAddCampModalOpen);
  };

  const toggleEditScheduleCampModal = (row) => {
    setIsEditScheduleCampModalOpen(!isEditScheduleCampModalOpen);
    setSelectedRow(row);
  };

  const toggleAssignedUsersModal = (row) => {
    setIsAssignedUsersModelOpen(!isAssignedUsersModelOpen);
    setSelectedRow(row);
  };

  const toggleAssignBeneficiariesInCampModal = (row) => {
    setIsAssignBeneficiariesModelOpen(!isAssignBeneficiariesModelOpen);
    setSelectedRow(row);
  };

  const toggleInstructionCampModal = (row) => {
    setIsInstructionCampModelOpen(!isInstructionCampModelOpen);
    setSelectedRow(row);
  };

  const toggleStepsFlowModal = (row) => {
    setIsStepsFlowCampModelOpen(!isStepsFlowCampModelOpen);
    setSelectedRow(row);
  };

  const SortIcon = ({ isSorted, isSortedDesc }) => {
    if (!isSorted) {
      return <span className="ml-4 sort-icon">&uarr;&darr;</span>; // Default unsorted icon
    } else if (isSortedDesc) {
      return <span className="ml-4 sort-icon">&darr;</span>; // Descending sort icon
    } else {
      return <span className="ml-4 sort-icon">&uarr;</span>; // Ascending sort icon
    }
  };

  const columnStyle = {
    fontSize: "14px",
    fontWeight: "normal",
    // border: "2px solid black",
  };

  const columns = [
    {
      name: "State",
      selector: (row) => row?.state?.state_name,
      style: columnStyle,
      maxWidth: "150px",
      backgroundColor: "black",
    },
    {
      name: "Center",
      selector: (row) => row?.camp_location_id?.camp_location_name,
      style: columnStyle,
      maxWidth: "150px",
    },
    {
      name: "Camp Code",
      selector: (row) => row?.camp_code,
      style: columnStyle,
      maxWidth: "150px",
    },
    {
      name: "Camp Type",
      selector: (row) => row?.camp_type_id?.camp_type_display_name,
      style: columnStyle,
      maxWidth: "150px",
    },
    {
      name: "Date",
      selector: (row) => row?.from_date,
      style: columnStyle,
      maxWidth: "150px",
    },
    {
      name: "Time",
      selector: (row) => row?.from_time,
      style: columnStyle,
      minWidth: "200px",
      cell: (row) => (
        <>
          <div className="w-full">
            {row?.from_time} - {row?.to_time}
          </div>
        </>
      ),
    },
    {
      name: "No. of Beneficiaries",
      selector: (row) => row?.max_numbers,

      style: columnStyle,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      minWidth: "180px",
      maxWidth: "180px",

      cell: (row) => (
        <Menu>
          <MenuHandler>
            <Button variant="text">
              {" "}
              <Dots></Dots>{" "}
            </Button>
          </MenuHandler>
          <MenuList className="p-0 text-black rounded-none">
            <MenuItem
              className="border-b border-[#E6E6E6] rounded-none p-3"
              onClick={() => {
                toggleEditScheduleCampModal(row);
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              className="border-b border-[#E6E6E6] p-3 rounded-none flex items-center gap-[0.625rem]"
              onClick={() => {
                toggleInstructionCampModal(row);
              }}
            >
              {" "}
              <div
                className={`h-[8px] w-[8px] rounded-2xl ${
                  row?.stepStatus?.Instructions
                    ? "bg-[#4ADB06]"
                    : "bg-[#FC5454]"
                }`}
              ></div>{" "}
              Instructions
            </MenuItem>
            <MenuItem
              className="border-b border-[#E6E6E6] p-3 rounded-none flex items-center gap-[0.625rem]"
              onClick={() => {
                toggleStepsFlowModal(row);
              }}
            >
              {" "}
              <div
                className={`h-[8px] w-[8px] rounded-2xl ${
                  row?.stepStatus?.CampFlowSetup
                    ? "bg-[#4ADB06]"
                    : "bg-[#FC5454]"
                }`}
              ></div>{" "}
              Camp Flow Setup
            </MenuItem>
            <MenuItem
              className="border-b border-[#E6E6E6] p-3 rounded-none flex items-center gap-[0.625rem]"
              onClick={() => {
                toggleAssignedUsersModal(row);
              }}
            >
              {" "}
              <div
                className={`h-[8px] w-[8px] rounded-2xl ${
                  row?.stepStatus?.AssignUser ? "bg-[#4ADB06]" : "bg-[#FC5454]"
                }`}
              ></div>{" "}
              Assign Users
            </MenuItem>
            <MenuItem
              className="border-b border-[#E6E6E6] p-3 rounded-none flex items-center gap-[0.625rem]"
              onClick={() => {
                toggleAssignBeneficiariesInCampModal(row);
              }}
            >
              {" "}
              <div
                className={`h-[8px] w-[8px] rounded-2xl ${
                  row?.stepStatus?.AssignBeneficiaries
                    ? "bg-[#4ADB06]"
                    : "bg-[#FC5454]"
                }`}
              ></div>{" "}
              Assign Beneficiaries
            </MenuItem>
          </MenuList>
        </Menu>
      ),

      button: true,
    },
  ];

  // functions
  const getData = (searchData, filter, page, perPage) => {
    dispatch(
      allScheduleCampList({
        search: searchData,
        filter: filter,
        page: page,
        perPage: perPage,
        onSuccess: (props) => {
          console.log("Camp Center loaded successfully");
        },
      })
    );
  };

  useEffect(() => {
    getData(scheduleCampSearchQuery, filter, currentPage, perPage);
  }, [scheduleCampSearchQuery, perPage, currentPage]);

  return (
    <>
      <div className="h-screen min-w-screen relative">
        <HeaderScreen />
        <div className="flex h-[calc(100vh-80px)]">
          <Sidebar />
          <div className="flex flex-col h-full bg-customBlue-mainBackground max-w-[calc(100%-87px)] w-[100%] overflow-auto">
            <div className="container min-h-[80px] min-w-full px-6 flex items-center justify-between">
              <div className="font-bold font-raleway text-[24px]">
                Scheduled Camps
              </div>
              <div className="container flex justify-between items-center space-x-4 h-[55%] w-[45%]">
                {/* Search and Filter */}
                <div className="container p-0 flex justify-between items-center w-[70%] ">
                  {/* Search Bar */}
                  <div className="relative flex-1 rounded-lg h-full ">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none h-full">
                      <SearchIcon width={20} height={20} />
                    </div>
                    <input
                      type="search"
                      id="default-search"
                      className="block w-full h-[100%] px-3 py-2 ps-10 text-sm text-gray-text-100 rounded-lg border border-gray-border-100"
                      placeholder="Search"
                      onChange={(event) => {
                        dispatch(
                          setScheduleCampSearchQuery(event.target.value)
                        );
                        // setSearchQuery(event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="">
                  <button
                    className="bg-[#3699FF] flex font-semibold h-full items-center justify-center px-3 py-2 rounded-lg space-x-2 text-sm text-white w-full whitespace-nowrap"
                    onClick={() => {
                      toggleAddModal();
                    }}
                  >
                    <AddCircle width={18} height={18} />
                    <span>Schedule Camp</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-between px-6 space-y-4">
              {/* Data Table */}
              <div className="">
                <NavigationBar
                  statuses={[
                    `Overview(${overAllCamps || 0})`,
                    `Upcoming Camps(${totalUpcomingCamps || 0})`,
                    `Past camps(${totalPastCamps || 0})`,
                  ]}
                  getData={getData}
                  // beneficiaryDetailToggle={setBeneficiaryDetailToggle}
                  barName="scheduleCamp"
                />
              </div>
              <div className=" overflow-y-auto scrollbar-hide theme-table">
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={{
                    headCells: {
                      style: {
                        backgroundColor: "#DDF1FE",
                        color: "Black",
                        fontWeight: "bold",
                        fontSize: "14px",
                      },
                    },
                  }}
                  sortIcon={<SortIcon />}
                  noDataComponent={
                    <div className="h-full py-16">
                      <NoRecord
                        title_1={"There are no records to display"}
                        title_2={""}
                      />
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {/* Modals */}
        {/* {isListCampBeneficiariesModalOpen && (
          <ListCampBeneficiary
            toggleModal={toggleListCampBeneficiariesModal}
            rowData={selectedRow}
            getData={getData}
          />
        )} */}

        {isAddCampModalOpen && (
          <AddCampModel toggleModal={toggleAddModal} getData={getData} />
        )}

        {isAssignedUsersModelOpen && (
          <AssignUserForCampModel
            toggleModal={toggleAssignedUsersModal}
            rowData={selectedRow}
            getData={getData}
          />
        )}

        {isAssignBeneficiariesModelOpen && (
          <AssignBeneficiariesForCampModel
            toggleModal={toggleAssignBeneficiariesInCampModal}
            rowData={selectedRow}
            getData={getData}
          />
        )}

        {isStepsFlowCampModelOpen && (
          <SetupStepFlowModel
            toggleModal={toggleStepsFlowModal}
            rowData={selectedRow}
            getData={getData}
          />
        )}

        {isInstructionCampModelOpen && (
          <InstructionOfCampModel
            toggleModal={toggleInstructionCampModal}
            rowData={selectedRow}
            getData={getData}
          />
        )}

        {isEditScheduleCampModalOpen && (
          <EditScheduleCampModel
            toggleModal={toggleEditScheduleCampModal}
            ScheduleCampId={selectedRow?._id}
            getData={getData}
          />
        )}
      </div>
    </>
  );
};

export { ListCampModel };
