/**
 * Beneficiary Detail Management slice
 * @format
 */

import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  allBeneficiaryDetailDataList: [],
  beneficiaryDetails: {},
  beneficiaryProcessDetail: {},
  beneficiaryJourneyDetail: {},
  beneficiaryDetailCurrentPage: 1,
  beneficiaryDetailDataPerPage: 10,
  districtId: "",
  stateId: "",
  beneficiaryStatus: "",
  filter: {
    startDate: "",
    endDate: "",
    pinCode: [],
  },

  //current camp
  currCampCurrentPage: 1,
  currCampDataPerPage: 10,
  currCampSearch: "",
  currCampDistrictId: "",
  currCampNavStatus: "",
  currCampStateId: "",
  currCampFilter: {
    pinCode: [],
  },
};

const beneficiaryDetailManagementSlice = createSlice({
  name: "beneficiaryDetailManagement",
  initialState,
  reducers: {
    setAllBeneficiaryDetailDataList(state, action) {
      state.allBeneficiaryDetailDataList = action.payload;
    },
    setBeneficiaryDetails(state, action) {
      state.beneficiaryDetails = action.payload;
    },
    setBeneficiaryDetailCurrentPage(state, action) {
      state.beneficiaryDetailCurrentPage = action.payload;
    },
    setBeneficiaryDetailDataPerPage(state, action) {
      state.beneficiaryDetailDataPerPage = action.payload;
    },
    setBeneficiaryProcessDetail(state, action) {
      state.beneficiaryProcessDetail = action.payload;
    },
    setBeneficiaryJourneyDetail(state, action) {
      state.beneficiaryJourneyDetail = action.payload;
    },
    setDistrictId(state, action) {
      state.districtId = action.payload;
    },
    setStateId(state, action) {
      state.stateId = action.payload;
    },
    setBeneficiaryStatus(state, action) {
      state.beneficiaryStatus = action.payload;
    },
    setFilter(state, action) {
      state.filter = { ...state.filter, ...action.payload };
    },

    //current camp
    setCurrCampCurrentPage(state, action) {
      state.currCampCurrentPage = action.payload;
    },
    setCurrCampDataPerPage(state, action) {
      state.currCampDataPerPage = action.payload;
    },
    setCurrCampNavStatus(state, action) {
      state.currCampNavStatus = action.payload;
    },
    setCurrCampSearch(state, action) {
      state.currCampSearch = action.payload;
    },
    setCurrCampDistrictId(state, action) {
      state.currCampDistrictId = action.payload;
    },
    setCurrCampStateId(state, action) {
      state.currCampStateId = action.payload;
    },
    setCurrCampFilter(state, action) {
      state.currCampFilter = { ...state.currCampFilter, ...action.payload };
    },
  },
});

// Reducer
export const beneficiaryDetailManagementReducer =
  beneficiaryDetailManagementSlice.reducer;

// Actions
export const {
  setAllBeneficiaryDetailDataList,
  setBeneficiaryDetails,
  setBeneficiaryDetailCurrentPage,
  setBeneficiaryDetailDataPerPage,
  setBeneficiaryProcessDetail,
  setBeneficiaryJourneyDetail,
  setDistrictId,
  setStateId,
  setBeneficiaryStatus,
  setFilter,

  //current camp
  setCurrCampCurrentPage,
  setCurrCampDataPerPage,
  setCurrCampDistrictId,
  setCurrCampFilter,
  setCurrCampSearch,
  setCurrCampStateId,
  setCurrCampNavStatus,
} = beneficiaryDetailManagementSlice.actions;

export const allBeneficiaryDetailList = createAction(
  "ALLBENEFICIARYDETAILLIST"
);
export const allBeneficiaryDetailListByStatus = createAction(
  "ALLBENEFICIARYDETAILLISTSTATUS"
);
export const getBeneficiaryDetailById = createAction(
  "GETBENEFICIARYDETAILBYID"
);
export const verifyBeneficiary = createAction("VERIFYBENEFICIARY");
export const getBeneficiaryProcessDetails = createAction(
  "BENEFICIARYPROCESSDETAILS"
);
export const getBeneficiaryJourneyDetails = createAction(
  "BENEFICIARYJOURNEYDETAILS"
);
export const updateVerifyBeneficiary = createAction("UPDATEVERIFYBENEFICIARY");
