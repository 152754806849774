/**
 * Dashboard Management Slice
 * @format
 */

import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  allBeneficiaryCount: {},
  allUsersCount: {},
  allDistrictBeneficiaryCount: {},
  // verification
  allVerificationBeneficiaryCount: {},
  allDistrictVerificationBeneficiaryCount: {},
  // Assessments
  allAssessmentBeneficiaryCount: {},
  allDistrictAssessmentBeneficiaryCount: {},
  // Fabrications
  allFabricationBeneficiaryCount: {},
  allDistrictFabricationBeneficiaryCount: {},
  // Distributions
  allDistributionBeneficiaryCount: {},
  allDistrictDistributionBeneficiaryCount: {},
  // camp Over view
  campOverviewCount: {},
  // Upcoming Camp
  upcomingCampsData: [],
};

const dashboardManagementSlice = createSlice({
  name: "dashboardManagement",
  initialState,
  reducers: {
    setAllDashboardBeneficiaryCount(state, action) {
      state.allBeneficiaryCount = action.payload;
    },
    setAllDashboardUsersCount(state, action) {
      state.allUsersCount = action.payload;
    },
    setAllDashboardDistrictBeneficiaryCount(state, action) {
      state.allDistrictBeneficiaryCount = action.payload;
    },
    //Verification
    setAllVerificationBeneficiaryCount(state, action) {
      state.allVerificationBeneficiaryCount = action.payload;
    },
    setAllDistrictVerificationBeneficiaryCount(state, action) {
      state.allDistrictVerificationBeneficiaryCount = action.payload;
    },
    //Assessments
    setAllAssessmentBeneficiaryCount(state, action) {
      state.allAssessmentBeneficiaryCount = action.payload;
    },
    setAllDistrictAssessmentBeneficiaryCount(state, action) {
      state.allDistrictAssessmentBeneficiaryCount = action.payload;
    },
    //Fabrication
    setAllFabricationBeneficiaryCount(state, action) {
      state.allFabricationBeneficiaryCount = action.payload;
    },
    setAllDistrictFabricationBeneficiaryCount(state, action) {
      state.allDistrictFabricationBeneficiaryCount = action.payload;
    },
    //Distribution
    setAllDistributionBeneficiaryCount(state, action) {
      state.allDistributionBeneficiaryCount = action.payload;
    },
    setAllDistrictDistributionBeneficiaryCount(state, action) {
      state.allDistrictDistributionBeneficiaryCount = action.payload;
    },
    //  camp Overview
    setAllCampOverviewCount(state, action) {
      state.campOverviewCount = action.payload;
    },
    // My Upcoming Camp
    setAllUpComingCamps(state, action) {
      state.upcomingCampsData = action.payload;
    },
  },
});

// Reducer
export const dashboardManagementReducer = dashboardManagementSlice.reducer;

// Actions
export const {
  setAllDashboardBeneficiaryCount,
  setAllDashboardUsersCount,
  setAllDashboardDistrictBeneficiaryCount,
  setAllAssessmentBeneficiaryCount,
  setAllFabricationBeneficiaryCount,
  setAllDistributionBeneficiaryCount,
  setAllVerificationBeneficiaryCount,
  setAllCampOverviewCount,
  setAllUpComingCamps,
  setAllDistrictVerificationBeneficiaryCount,
  setAllDistrictAssessmentBeneficiaryCount,
  setAllDistrictFabricationBeneficiaryCount,
  setAllDistrictDistributionBeneficiaryCount,
} = dashboardManagementSlice.actions;

export const allBeneficiaryDataCountList = createAction("ALLBENEFICIARYCOUNT");
export const allUsersCountList = createAction("ALLUSERCOUNT");
export const allDistrictWiseBeneficiaryCount = createAction(
  "ALLDISTRICTWISEBENEFICIARYCOUNT"
);
export const searchBeneficiary = createAction("SEARCHBENEFICIARYDATA");
//Verification
export const allVerificationBeneficiaryDataCountList = createAction(
  "ALLVERIFICATIONBENEFICIARYCOUNT"
);
export const allDistrictVerificationBeneficiaryDataCountList = createAction(
  "ALLDISTRICTVERIFICATIONBENEFICIARYCOUNT"
);
//Assessment
export const allAssessmentBeneficiaryDataCountList = createAction(
  "ALLASSESSMENTBENEFICIARYCOUNT"
);
export const allDistrictAssessmentBeneficiaryDataCountList = createAction(
  "ALLDISTRICTASSESSMENTBENEFICIARYCOUNT"
);
// Fabrication
export const allFabricationBeneficiaryDataCountList = createAction(
  "ALLFABRICATIONBENEFICIARYCOUNT"
);
export const allDistrictFabricationBeneficiaryDataCountList = createAction(
  "ALLDISTRICTFABRICATIONBENEFICIARYCOUNT"
);
// Distribution
export const allDistributionBeneficiaryDataCountList = createAction(
  "ALLDISTRIBUTIONBENEFICIARYCOUNT"
);
export const allDistrictDistributionBeneficiaryDataCountList = createAction(
  "ALLDISTRICTDISTRIBUTIONBENEFICIARYCOUNT"
);
// camp overview
export const allCampOverViewCountList = createAction("ALLCAMPOVERVIEWCOUNT");
// Upcoming Camps
export const allUpcomingCampsList = createAction("ALLUPCOMINGCAMPS");
