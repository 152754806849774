/**
 * Utils
 * @format
 */

import moment from "moment";

export function manipulateDateAndTime(date) {
  const new_date = new Date(date);

  // Format the date as "DD-MM-YYYY"
  const formattedDate = new_date
    .toLocaleDateString("en-GB")
    .replace(/\//g, "-");

  // Get the time in "H.MM AM/PM" format
  const formattedTime = new_date
    .toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })
    .replace(":", ".");

  return {
    date: formattedDate,
    time: formattedTime,
  };
}

export function getInitialsName(name) {
  // Split the name by space
  const nameParts = name.split(" ");

  // Map through the name parts and get the first letter of each part
  const initials = nameParts
    .map((part) => part.charAt(0).toUpperCase())
    .join("");

  return initials;
}

export function convertTo12HourFormat(time) {
  const [hour, minute] = time.split(":");
  const period = hour >= 12 ? "PM" : "AM";
  const hour12 = hour % 12 || 12;
  return `${String(hour12).padStart(2, "0")}:${minute} ${period}`;
}

export function formatCampDate(fromDate, toDate) {
  const date1 = moment(fromDate);
  const date2 = moment(toDate);

  return `${date1.format("MMMM D")} - ${date2.format("D")}, ${date2.format(
    "YYYY"
  )}`;
}

export function formatQuery(query) {
  return query
    ?.map((condition, index) => {
      let formattedCondition = "";
      let Status = condition?.status;

      if (Status === "belonging_to") {
        Status = "belonging to";
      } else if (Status === "of_assessment_Camp") {
        Status = "of assessment Camp";
      }

      switch (condition.field_2) {
        case "state":
          formattedCondition = ` ${Status} State of ${condition?.stateName.join(
            ", "
          )}`;
          break;
        case "district":
          formattedCondition = ` ${Status} District of ${condition?.districtName.join(
            ", "
          )}`;
          break;
        case "pinCode":
          formattedCondition = ` ${Status} Pin Codes ${condition.pinCode.join(
            ", "
          )}`;
          break;
        case "between":
          formattedCondition = `${capitalize(Status)} between ${
            condition?.startDate
          } to ${condition?.endDate}`;
          break;
        case "before":
          formattedCondition = `${capitalize(Status)} before ${
            condition?.date
          }`;
          break;
        case "after":
          formattedCondition = `${capitalize(Status)} after ${condition?.date}`;
          break;

        case "camp_code_name":
          formattedCondition = ` ${Status} camp code/name ${condition.pinCode.join(
            ", "
          )}`;
          break;
        case "held_between":
          formattedCondition = `${capitalize(Status)} held between ${
            condition?.startDate
          } to ${condition?.endDate}`;
          break;
        case "held_on":
          formattedCondition = `${capitalize(Status)} held on ${
            condition?.date
          }`;
          break;
        case "held_at":
          formattedCondition = `${capitalize(Status)} held at ${
            condition?.date
          }`;
          break;
      }

      // Add operation, except for the last condition
      if (index < query.length - 1) {
        formattedCondition += ` ${condition.operation} `;
      }

      return formattedCondition;
    })
    .join("");
}

export function capitalize(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}
