/**
 * Add instruction of Camp Screen Page
 * @flow
 * @format
 */

import { Formik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Cross } from "../../../assets/icons/Cross";
import TextAreaWithEditorComponent from "../../../components/textAreaWithEditor";
import ScheduleCampDetailCardComponent from "../component/scheduleCampDetailCard";
import { CampFields } from "../../../constants";
import { updateScheduleCampById } from "../slice";
import { toast } from "react-toastify";
import {
  selectScheduleCampCurrentPage,
  selectScheduleCampFilter,
  selectScheduleCampRespPerPage,
  selectScheduleCampSearchQuery,
} from "../selector";

const InstructionOfCampModel = (props) => {
  const dispatch = useDispatch();
  const { toggleModal, rowData, getData } = props;

  const scheduleCampSearchQuery = useSelector(selectScheduleCampSearchQuery);
  const scheduleCampCurrentPage = useSelector(selectScheduleCampCurrentPage);
  const scheduleCampPerPage = useSelector(selectScheduleCampRespPerPage);
  const scheduleCampFilter = useSelector(selectScheduleCampFilter);
  const initialValues = {
    [CampFields.ID]: "",
    [CampFields.INSTRUCTIONS]: "",
  };

  const validationSchema = Yup.object().shape({
    [CampFields.INSTRUCTIONS]: Yup.string().required(
      "Instructions is required"
    ),
  });

  const [initialObject, setInitialObject] = useState(initialValues);

  useEffect(() => {
    if (rowData) {
      setInitialObject(rowData);
    }
  }, [rowData]);

  return (
    <>
      <Formik
        initialValues={initialObject}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          const updatedValues = {
            [CampFields.INSTRUCTIONS]: values[CampFields.INSTRUCTIONS],
            [CampFields.ID]: rowData?._id,
          };

          dispatch(
            updateScheduleCampById({
              values: updatedValues,
              onSuccess: (props) => {
                toast.success(props?.message);
                toggleModal();
                getData(
                  scheduleCampSearchQuery,
                  scheduleCampFilter,
                  scheduleCampCurrentPage,
                  scheduleCampPerPage
                );
              },
            })
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="fixed h-full inset-0 z-50 flex justify-end">
              <div className="fixed inset-0 bg-[#07284B] opacity-50"></div>
              <div className="flex flex-col relative w-[50%] h-full bg-white shadow-lg overflow-y-auto scrollbar-hide">
                <div className="flex justify-between items-center bg-customBlue-mainBackground w-full h-[11%] p-6">
                  <h2 className="text-[20px] font-bold">
                    Instructions for Beneficiaries
                  </h2>
                  <button
                    onClick={toggleModal}
                    className="text-xl font-semibold p-1"
                  >
                    <Cross width={32} height={32} />
                  </button>
                </div>
                {/* Modal content */}
                <div className="w-full flex flex-col flex-auto h-screen justify-between py-3 px-6 space-y-3">
                  <div className={"w-full h-full flex flex-col space-y-3"}>
                    <div>
                      <ScheduleCampDetailCardComponent data={rowData} />
                    </div>
                    {/* INSTRUCTIONS */}
                    <div className="h-[calc(100%-180px)]">
                      <label className="text-[14px] font-bold">
                        Instructions
                      </label>
                      <TextAreaWithEditorComponent
                        name={CampFields.INSTRUCTIONS}
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                      {touched[CampFields.INSTRUCTIONS] &&
                        errors[CampFields.INSTRUCTIONS] && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors[CampFields.INSTRUCTIONS]}
                          </div>
                        )}
                    </div>
                  </div>

                  {/* buttons */}
                  <div className="w-full flex justify-end space-x-4 h-[48px] ">
                    <button
                      className="bg-black text-white px-4 text-[18px] py-2 font-bold rounded-lg min-w-[32%] h-full"
                      onClick={(e) => {
                        e.preventDefault();
                        resetForm();
                      }}
                    >
                      Reset
                    </button>
                    <button
                      className="text-white px-4 py-2 rounded-md bg-WildWatermelon-button w-[32%] h-full font-bold"
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export { InstructionOfCampModel };
