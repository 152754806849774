/**
 * Schedule camp detail Component
 * @format
 */

import React, { useState } from "react";
import { CalendarNew } from "../../../assets/icons/Calender";
import { Clock } from "../../../assets/icons/Clock";
import { Location } from "../../../assets/icons/Location";
import { formatCampDate } from "../../../utils/utils";

const ScheduleCampDetailCardComponent = ({ data }) => {

  return (
    <>
      <div className="container text-[14px] px-4 py-3 text-black bg-[#F5F5F5] rounded-xl flex flex-col gap-2">
        <div>
          <div className="font-bold mb-1">
            Camp Name - {data?.camp_location_id?.camp_location_name}
          </div>
          <div className="font-bold ">Camp Code - {data?.camp_code}</div>
        </div>
        <div className="font-semibold  flex items-center gap-1.5">
          <Location width={16} height={18} fillColor={"#00000080"} />
          <div className=" text-[#00000080]">
            {data?.camp_location_id?.address_1}{" "}
            {data?.camp_location_id?.address_2}, Dist-{" "}
            {data?.camp_location_id?.district?.district_name}
          </div>
        </div>
        <div className="font-semibold  flex items-center gap-[2rem] flex-wrap md:gap-[0.5rem]">
          <div className="font-semibold  flex items-center gap-1.5">
            <CalendarNew />
            <div className=" ">
              {formatCampDate(data?.from_date, data?.to_date)}
            </div>
          </div>
          <div className="font-semibold  flex items-center gap-1.5">
            <Clock width={16} height={16} />
            <div className="">
              {" "}
              {data?.from_time}-{data?.to_time}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScheduleCampDetailCardComponent;
