/**
 * Edit Role Model
 * @format
 */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { RoleFields } from "../../../constants";
import { Switch } from "@material-tailwind/react";
import { updateRoleById } from "../slice";
import { selectRoleCurrentPage, selectRoleDataPerPage } from "../selector";
import { selectAllPermissionDataList } from "../../permissions_management/selector";
import { allPermissionList } from "../../permissions_management/slice";
import { Cross } from "../../../assets/icons/Cross";
import Select from "react-select";
import SelectDropDown from "../../../components/selectDropDown";

const EditRoleModels = ({ role, toggleModal, getData }) => {
  const dispatch = useDispatch();
  const currentPage = useSelector(selectRoleCurrentPage);
  const limit = useSelector(selectRoleDataPerPage);
  // const { data = [] } = useSelector(selectAllPermissionDataList) || {};

  const initialValues = {
    [RoleFields.ROLE_NAME]: "",
    [RoleFields.ROLE_TYPE]: "",
    [RoleFields.ROLE_DESCRIPTION]: "",
    [RoleFields.PERMISSION]: [],
    [RoleFields.IS_ACTIVE]: null,
  };
  const [initialObject, setInitialObject] = useState(initialValues);

  useEffect(() => {
    setInitialObject(role);
    // getPermission();
  }, []);

  // const permissionOptions = data?.map((item) => ({
  //   label: item.name,
  //   value: item._id,
  // }));

  // const getPermission = () => {
  //   dispatch(
  //     allPermissionList({
  //       search: "",
  //       page: 1,
  //       perPage: 10,
  //       onSuccess: (props) => {
  //         console.log("Permission loaded successfully");
  //       },
  //     })
  //   );
  // };

  const roleTypeOptions = [
    { label: "Org", value: "org_user" },
    { label: "Camp", value: "camp_user" },
  ];

  // Yup validation schema
  const validationSchema = Yup.object().shape({
    [RoleFields.ROLE_NAME]: Yup.string()
      .required("Role Name is required")
      .max(50, "Role Name cannot exceed 50 characters"),
    [RoleFields.ROLE_TYPE]: Yup.string()
      .required("Role Type is required")
      .oneOf(["org_user", "camp_user"], "Invalid Role Type"),
    [RoleFields.ROLE_DESCRIPTION]: Yup.string()
      .required("Role Description is required")
      .max(200, "Role Description cannot exceed 200 characters"),
  });

  return (
    <Formik
      initialValues={initialObject}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        const updatedValues = {
          ...values,
          id: values._id,
        };
        delete updatedValues.permission;
        delete updatedValues._id;
        dispatch(
          updateRoleById({
            updatedValues,
            onSuccess: (props) => {
              toast.success(props?.message);
              toggleModal();
              getData("", currentPage, limit);
            },
          })
        );
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="fixed h-full inset-0 z-50 flex justify-end">
            <div className="fixed inset-0 bg-[#07284B] opacity-50"></div>
            <div className="flex flex-col relative w-[43%] h-full bg-white shadow-lg overflow-y-auto scrollbar-hide">
              <div className="flex justify-between items-center bg-customBlue-mainBackground w-full h-[11%] p-6">
                <h2 className="text-[20px] font-bold">Edit Role</h2>
                <button onClick={toggleModal} className="text-xl font-semibold">
                  <Cross width={32} height={32} />
                </button>
              </div>
              {/* Modal content */}
              <div className="w-full flex flex-col flex-auto justify-between py-6 px-8 ">
                <div className={"w-full flex flex-col space-y-3"}>
                  <div className="w-full">
                    <label className="text-[14px] font-bold">Role Name</label>
                    <input
                      autoComplete="off"
                      type="text"
                      name={RoleFields.ROLE_NAME}
                      id={RoleFields.ROLE_NAME}
                      onChange={handleChange}
                      value={values[RoleFields.ROLE_NAME]}
                      onBlur={handleBlur}
                      className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px] focus:outline-none focus:ring-2 focus:ring-customBlue-hover"
                    />

                    {touched[RoleFields.ROLE_NAME] &&
                      errors[RoleFields.ROLE_NAME] && (
                        <div className="mt-1 text-xs text-red-500">
                          {errors[RoleFields.ROLE_NAME]}
                        </div>
                      )}
                  </div>

                  <div className="w-full">
                    <label className="text-[14px] font-bold">Role Type</label>
                    <SelectDropDown
                      options={roleTypeOptions}
                      name={RoleFields.ROLE_TYPE}
                      placeholder="Select"
                      onChange={(selectedOption) =>
                        setFieldValue(
                          RoleFields.ROLE_TYPE,
                          selectedOption?.value || ""
                        )
                      }
                      value={
                        roleTypeOptions?.find(
                          (item) => item?.value === values[RoleFields.ROLE_TYPE]
                        ) || null
                      }
                      touched={touched}
                      errors={errors}
                      isSearchable={true}
                      isClearable={false}
                    />
                    {/* <Select
                      options={roleTypeOptions}
                      name={RoleFields.ROLE_TYPE}
                      id={RoleFields.ROLE_TYPE}
                      isClearable={true}
                      placeholder="Select"
                      onChange={(selectedOption) =>
                        setFieldValue(
                          RoleFields.ROLE_TYPE,
                          selectedOption?.value || ""
                        )
                      }
                      value={
                        roleTypeOptions?.find(
                          (item) => item?.value === values[RoleFields.ROLE_TYPE]
                        ) || null
                      }
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          border: state.isFocused
                            ? "2px solid #B0E0FF"
                            : "2px solid #B0E0FF",
                          borderRadius: "12px",
                          height: "44px",
                          boxShadow: state.isFocused
                            ? "0 0 0 2px rgba(176, 224, 255, 0.5)"
                            : "none",
                          "&:hover": {
                            borderColor: "#B0E0FF",
                          },
                        }),
                      }}
                    /> */}

                    {touched[RoleFields.ROLE_TYPE] &&
                      errors[RoleFields.ROLE_TYPE] && (
                        <div className="mt-1 text-xs text-red-500">
                          {errors[RoleFields.ROLE_TYPE]}
                        </div>
                      )}
                  </div>

                  <div className="w-full">
                    <label className="text-[14px] font-bold">
                      Role Description
                    </label>
                    <textarea
                      autoComplete="off"
                      name={RoleFields.ROLE_DESCRIPTION}
                      id={RoleFields.ROLE_DESCRIPTION}
                      onChange={handleChange}
                      value={values[RoleFields.ROLE_DESCRIPTION]}
                      onBlur={handleBlur}
                      className="w-full h-[88px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px] resize-none focus:outline-none focus:ring-2 focus:ring-customBlue-hover"
                    />
                    {touched[RoleFields.ROLE_DESCRIPTION] &&
                      errors[RoleFields.ROLE_DESCRIPTION] && (
                        <div className="mt-1 text-xs text-red-500">
                          {errors[RoleFields.ROLE_DESCRIPTION]}
                        </div>
                      )}
                  </div>
                  <div className="w-full">
                    <label className="text-[14px] font-bold">Status</label>
                    <div className="flex items-center space-x-4">
                      <Switch
                        ripple={false}
                        checked={values[RoleFields.IS_ACTIVE]}
                        onChange={() =>
                          setFieldValue(
                            RoleFields.IS_ACTIVE,
                            !values[RoleFields.IS_ACTIVE]
                          )
                        }
                        className="h-full w-full checked:bg-[#B0E0FF]"
                        containerProps={{
                          className: "w-8 h-4",
                        }}
                        circleProps={{
                          className:
                            "before:hidden left-1 border-none w-[12px] h-[12px]",
                        }}
                      />
                      <span>
                        {values[RoleFields.IS_ACTIVE] ? "Active" : "Inactive"}
                      </span>
                    </div>
                  </div>

                  {/* <div className="w-full">
                  <label className="text-[14px] font-bold">Delete</label>
                  <div className="flex items-center space-x-4">
                    <Switch
                      ripple={false}
                      checked={values[RoleFields.IS_DELETED]}
                      onChange={() =>
                        setFieldValue(
                          RoleFields.IS_DELETED,
                          !values[RoleFields.IS_DELETED]
                        )
                      }
                      className="h-full w-full checked:bg-[#B0E0FF]"
                      containerProps={{
                        className: "w-8 h-4",
                      }}
                      circleProps={{
                        className:
                          "before:hidden left-1 border-none w-[12px] h-[12px]",
                      }}
                    />
                    <span>
                      {values[RoleFields.IS_DELETED] ? "True" : "False"}
                    </span>
                  </div>
                </div> */}
                </div>

                {/* buttons */}
                <div className="w-full flex justify-end space-x-4 h-[48px]">
                  <button
                    className="bg-black text-white px-4 text-[18px] py-2 font-bold rounded-lg min-w-[32%] h-full"
                    onClick={(e) => {
                      e.preventDefault();
                      resetForm();
                    }}
                  >
                    Reset
                  </button>
                  <button
                    className="flex text-white px-4 py-2 rounded-md bg-WildWatermelon-button w-[32%] h-full font-bold items-center justify-center"
                    type="submit"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export { EditRoleModels };
