/**
 * Date Table Component
 * @format
 */

import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import { httpRequest } from "../services";
import { Loader } from "./loader";
import { NoRecord } from "./noRecords";

const DateTableComponent = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  //   const [sortByDir, setSortByDir] = useState("desc");
  const [isInitialize, setIsInitialize] = useState(false);
  const { pagination, searchQuery } = props;

  const fetchApiData = async (searchData, page, perPage) => {
    let response = [];

    setLoading(true);
    if (props.type == "get") {
      response = await httpRequest.get(props.url, {
        search: searchData,
        page: page,
        responsePerPage: perPage,
        onSuccess: (props) => {
          console.log("Users loaded successfully");
        },
      });
    } else {
      response = await httpRequest.post(props.url, {
        search: searchData,
        page: page,
        responsePerPage: perPage,
        onSuccess: (props) => {
          console.log("Users loaded successfully");
        },
      });
    }
    setData(response.data.data);
    setTotalRows(response.data.totalAdmin);
    setLoading(false);
    setIsInitialize(true);
  };

  useEffect(() => {
    fetchApiData(searchQuery, currentPage, perPage).catch((err) => {
      setLoading(false);
    });
  }, [props.url, searchQuery]);

  const modifyCreatedAt = (data) => {
    if (data.length > 0 && data[0].hasOwnProperty("createdAt")) {
      data.forEach((item) => {
        const date = new Date(item.createdAt);
        const formattedDate = `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()}`;
        item.createdAt = formattedDate;
      });
    }
    return data;
  };

  const columns = useMemo(() => props.apiColumn);

  const handlePageChange = (page) => {
    console.log(page, "page");

    if (isInitialize !== false) {
      fetchApiData(searchQuery, page, 1).catch((err) => {
        setLoading(false);
      });
      setCurrentPage(page);
    }
  };

  const handlePerRowsChange = (newPerPage, page) => {
    console.log(newPerPage, page, "page");
    if (isInitialize !== false) {
      fetchApiData(searchQuery, page, newPerPage).catch((err) => {
        setLoading(false);
      });
      setPerPage(newPerPage);
    }
  };

  return (
    <DataTable
      columns={columns}
      data={data}
      customStyles={{
        headCells: {
          style: {
            backgroundColor: props.backgroundColor,
            color: "Black",
            fontWeight: "bold",
            fontSize: "14px",
            height: "48px",
          },
        },
      }}
      progressPending={loading}
      progressComponent={<Loader />}
      paginationPerPage={perPage}
      paginationRowsPerPageOptions={[10]}
      pagination={pagination}
      paginationServer
      paginationTotalRows={totalRows}
      paginationDefaultPage={currentPage}
      subHeader={false}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      sortIcon={props.sortIcon}
      selectableRows={props.selectableRows}
      dense
      striped
      noDataComponent={
        <div className="h-full py-16">
          <NoRecord title_1={"There are no records to display"} title_2={""} />
        </div>
      }
    />
  );
};

export default DateTableComponent;
