/**
 * View All Next Conut Details Model
 * @format
 */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Cross } from "../../../assets/icons/Cross";
import DataTable from "react-data-table-component";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { SingleUser2 } from "../../../assets/icons/SingleUser2";
import {
  allDistrictAssessmentBeneficiaryDataCountList,
  allDistrictDistributionBeneficiaryDataCountList,
  allDistrictFabricationBeneficiaryDataCountList,
  allDistrictWiseBeneficiaryCount,
  setAllDashboardDistrictBeneficiaryCount,
} from "../slice";
import { Loader } from "../../../components";
import { setDistrictId, setStateId } from "../../beneficiary_management/slice";
import { useNavigate } from "react-router-dom";
import { NoRecord } from "../../../components/noRecords";

const ViewAllDetailsModels = ({ rowData, toggleModal, cardName }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeStatus, setActiveStatus] = useState(cardName);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [columnNames, setColumnNames] = useState({
    one: "District",
    two: "",
    three: "Verified",
    four: "Registered",
    five: "Pending",
    six: "Rejected",
    seven: "",
    Valueone: "districtName",
    Valuetwo: "percentage",
    Valuethree: "verified",
    Valuefour: "registered",
    Valuefive: "pending",
    Valuesix: "rejected",
    Valueseven: "id",
  });

  const statuses = [
    { name: "Registered", value: "Verified" },
    { name: "Assessments", value: "Assessments" },
    { name: "Fabrication", value: "Fabrication" },
    { name: "Distribution", value: "Distribution" },
  ];

  // DataTable Start

  const SortIcon = ({ isSorted, isSortedDesc }) => {
    if (!isSorted) {
      return <span className="ml-4 sort-icon">&uarr;&darr;</span>; // Default unsorted icon
    } else if (isSortedDesc) {
      return <span className="ml-4 sort-icon">&darr;</span>; // Descending sort icon
    } else {
      return <span className="ml-4 sort-icon">&uarr;</span>; // Ascending sort icon
    }
  };

  const columnStyle = {
    fontSize: "14px",
    fontWeight: "normal",
    // border: "2px solid black"
  };

  const columns = [
    {
      name: columnNames?.one,
      selector: (row) => row?.[columnNames.Valueone],
      sortable: true,
      style: columnStyle,
      minWidth: "200px",
      cell: (row) => (
        <>
          <div className="flex flex-col items-start space-y-1 justify-between w-full">
            <div className="underline">{row?.districtName}</div>
            <div className="flex flex-row space-x-2">
              <SingleUser2 width={10} height={12} />
              <span>Users:{row.users}</span>
            </div>
          </div>
        </>
      ),
    },
    {
      name: columnNames?.two,
      selector: (row) => row?.[columnNames.Valuetwo],
      sortable: true,
      style: columnStyle,
      cell: (row) => (
        <>
          <div className="w-8 h-8">
            <CircularProgressbar
              value={row?.percentageVerified || 0}
              text={`${row?.percentageVerified || 0}%`}
              styles={buildStyles({
                pathColor: "#1D73FF",
                textColor: "black",
                trailColor: "#d6d6d6",
                backgroundColor: "#3e98c7",
                textSize: "30px",
              })}
            />
          </div>
        </>
      ),
    },
    {
      name: columnNames?.three,
      selector: (row) => row?.[columnNames.Valuethree],
      sortable: true,
      style: columnStyle,
    },
    {
      name: columnNames?.four,
      selector: (row) => row?.[columnNames.Valuefour],
      sortable: true,
      style: columnStyle,
    },
    {
      name: columnNames?.five,
      selector: (row) => row?.[columnNames.Valuefive],
      sortable: true,
      style: columnStyle,
    },
    {
      name: columnNames?.six,
      selector: (row) => row?.[columnNames.Valuesix],
      sortable: true,
      style: columnStyle,
    },
    {
      name: columnNames?.seven,
      selector: (row) => row?.[columnNames.Valueseven],
      sortable: true,
      style: columnStyle,
      cell: (row) => (
        <>
          <div className="flex w-[80%] items-center justify-end">
            <button
              className="underline text-blue-500 right-0 cursor-pointer"
              onClick={() => {
                // Set states
                dispatch(setDistrictId(row.id));
                dispatch(setStateId(""));

                // Navigate and pass the sendData object
                navigate("/beneficiaryVerification");
              }}
            >
              View all
            </button>
          </div>
        </>
      ),
    },
  ];
  // DataTable End

  const getVerificationallDestrictCountByStateID = (id) => {
    dispatch(
      allDistrictWiseBeneficiaryCount({
        id,
        onSuccess: (props) => {
          setColumnNames({
            one: "District",
            two: "",
            three: "Verified",
            four: "Registered",
            five: "Pending",
            six: "Rejected",
            seven: "",
            Valueone: "districtName",
            Valuetwo: "percentage",
            Valuethree: "verified",
            Valuefour: "beneficiaries",
            Valuefive: "pending",
            Valuesix: "rejected",
            Valueseven: "id",
          });
          setLoading(false);
          setData(
            props.data?.map((item) => ({
              districtName: item?.districtName,
              percentage: item?.percentageVerified,
              beneficiaries: item?.beneficiaries,
              pending: item?.pending,
              verified: item?.verified,
              rejected: item?.rejected,
              id: item?.id,
              users: item?.users,
              describe: "district",
            }))
          );
        },
      })
    );
  };
  const getAssessmentallDestrictCountByStateID = (id) => {
    dispatch(
      allDistrictAssessmentBeneficiaryDataCountList({
        id,
        onSuccess: (props) => {
          setColumnNames({
            one: "District",
            two: "",
            three: "Done",
            four: "In Progress",
            five: "Scheduled",
            six: "To Be Scheduled",
            seven: "",
            Valueone: "districtName",
            Valuetwo: "percentage",
            Valuethree: "done",
            Valuefour: "inProgress",
            Valuefive: "scheduled",
            Valuesix: "toBeScheduled",
            Valueseven: "id",
          });
          setLoading(false);
          setData(
            props.data?.map((item) => ({
              districtName: item?.districtName,
              percentage: item?.percentageAssessment,
              done: item?.assessement_completed,
              inProgress: item?.assessment_in_progress,
              scheduled: item?.assessement_scheduled,
              toBeScheduled: item?.assessement_to_be_scheduled,
              id: item?.id,
              users: item?.users,
              describe: "district",
            }))
          );
        },
      })
    );
  };
  const getFabricationallDestrictCountByStateID = (id) => {
    dispatch(
      allDistrictFabricationBeneficiaryDataCountList({
        id,
        onSuccess: (props) => {
          setColumnNames({
            one: "District",
            two: "",
            three: "Done",
            four: "In Progress",
            five: "Not yet Started",
            six: "Not Eligible",
            seven: "",
            Valueone: "districtName",
            Valuetwo: "percentage",
            Valuethree: "done",
            Valuefour: "inProgress",
            Valuefive: "scheduled",
            Valuesix: "toBeScheduled",
            Valueseven: "id",
          });
          setLoading(false);
          setData(
            props.data?.map((item) => ({
              districtName: item?.districtName,
              percentage: item?.percentageFabrication,
              done: item?.fabrication_completed,
              inProgress: item?.fabrication_in_progress,
              scheduled: item?.fabrication_not_yet_started,
              toBeScheduled: item?.not_eligible_for_fabrication,
              id: item?.id,
              users: item?.users,
              describe: "district",
            }))
          );
        },
      })
    );
  };
  const getDistributionallDestrictCountByStateID = (id) => {
    dispatch(
      allDistrictDistributionBeneficiaryDataCountList({
        id,
        onSuccess: (props) => {
          setColumnNames({
            one: "District",
            two: "",
            three: "Done",
            four: "In Progress",
            five: "Scheduled",
            six: "To Be Scheduled",
            seven: "",
            Valueone: "districtName",
            Valuetwo: "percentage",
            Valuethree: "done",
            Valuefour: "inProgress",
            Valuefive: "scheduled",
            Valuesix: "toBeScheduled",
            Valueseven: "id",
          });
          setLoading(false);
          setData(
            props.data?.map((item) => ({
              districtName: item?.districtName,
              percentage: item?.percentageDistribution,
              done: item?.distribution_completed,
              inProgress: item?.distribution_in_progress,
              scheduled: item?.distribution_scheduled,
              toBeScheduled: item?.distribution_to_be_scheduled,
              id: item?.id,
              users: item?.users,
              describe: "district",
            }))
          );
        },
      })
    );
  };

  const handleStatusClick = async (value) => {
    try {
      setActiveStatus(value);
      if (value === "Verified") {
        getVerificationallDestrictCountByStateID(rowData?.id);
      } else if (value === "Assessments") {
        getAssessmentallDestrictCountByStateID(rowData?.id);
      } else if (value === "Fabrication") {
        getFabricationallDestrictCountByStateID(rowData?.id);
      } else if (value === "Distribution") {
        getDistributionallDestrictCountByStateID(rowData?.id);
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  useEffect(() => {
    if (rowData.describe === "state") {
      if (cardName === "Verified") {
        getVerificationallDestrictCountByStateID(rowData?.id);
      } else if (cardName === "Assessments") {
        getAssessmentallDestrictCountByStateID(rowData?.id);
      } else if (cardName === "Fabrication") {
        getFabricationallDestrictCountByStateID(rowData?.id);
      } else if (cardName === "Distribution") {
        getDistributionallDestrictCountByStateID(rowData?.id);
      }
    }
    setActiveStatus(cardName);
  }, [rowData]);

  return (
    <>
      <div className="fixed h-full inset-0 z-50 flex justify-end">
        <div className="fixed inset-0 bg-[#07284B] opacity-50"></div>
        <div className="flex flex-col relative w-[75%] h-full bg-white shadow-lg overflow-y-auto scrollbar-hide">
          <div className="flex justify-between items-center bg-customBlue-mainBackground w-full h-[11%] p-6">
            <h2 className="text-[20px] ">
              Beneficiary Count /{" "}
              <span className="font-bold">{rowData?.name}</span>
            </h2>
            <button
              onClick={() => {
                toggleModal(); // Call the toggleModal function
                dispatch(setAllDashboardDistrictBeneficiaryCount(null)); // Reset the state
              }}
              className="text-xl font-semibold"
            >
              <Cross width={32} height={32} />
            </button>
          </div>
          {/* Modal content */}
          <div className="w-full flex flex-col flex-auto justify-start space-y-6 py-6 px-8 ">
            {/* NavBar */}
            <div className="container p-0 text-black h-[30px]">
              <div className="container p-0 flex justify-start items-center h-full">
                {statuses.map((status, index) => {
                  const [statusName] = status?.name.split("(");
                  return (
                    <button
                      key={index}
                      onClick={() => handleStatusClick(status?.value)}
                      className={`mr-8 h-full text-[14px] font-semibold p-navigation leading-4 ${
                        activeStatus === status?.value
                          ? "text-black font-[600] border-b-2 border-customBlue-activeBorder"
                          : "text-black border-b border-customBlue-lightBorder"
                      }`}
                    >
                      {statusName}
                    </button>
                  );
                })}
              </div>
            </div>

            {/* Data Table */}
            <div className="flex justify-center items-center overflow-y-auto scrollbar-hide theme-table">
              <DataTable
                columns={columns}
                data={data}
                progressPending={loading}
                progressComponent={<Loader />}
                customStyles={{
                  headCells: {
                    style: {
                      backgroundColor: "#F7F7F7",
                      color: "Black",
                      fontWeight: "bold",
                      fontSize: "14px",
                    },
                  },
                }}
                sortIcon={<SortIcon />}
                noDataComponent={
                  <div className="h-full py-16">
                    <NoRecord title_1={"There are no records to display"} title_2={""} />
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ViewAllDetailsModels };
