/**
 * Dashboard Management Selector
 * @format
 */

const dashboardManagement = (state) => state.dashboardManagementReducer;

// User
export const selectAllDashboardUserCount = (state) =>
  dashboardManagement(state).allUsersCount;

//Verification
export const selectAllDashboardBeneficiaryCount = (state) =>
  dashboardManagement(state).allBeneficiaryCount;

export const selectAllDashboardDistrictBeneficiaryCount = (state) =>
  dashboardManagement(state).allDistrictBeneficiaryCount;

//Verification
export const selectAllVerificationBeneficiaryCount = (state) =>
  dashboardManagement(state).allVerificationBeneficiaryCount;
export const selectAllDistrictVerificationBeneficiaryCount = (state) =>
  dashboardManagement(state).allDistrictVerificationBeneficiaryCount;

//Assessments
export const selectAllAssessmentBeneficiaryCount = (state) =>
  dashboardManagement(state).allAssessmentBeneficiaryCount;
export const selectAllDistrictAssessmentBeneficiaryCount = (state) =>
  dashboardManagement(state).allDistrictAssessmentBeneficiaryCount;

//Fabrications
export const selectAllFabricationBeneficiaryCount = (state) =>
  dashboardManagement(state).allFabricationBeneficiaryCount;
export const selectAllDistrictFabricationBeneficiaryCount = (state) =>
  dashboardManagement(state).allDistrictFabricationBeneficiaryCount;

//Distributions
export const selectAllDistributionBeneficiaryCount = (state) =>
  dashboardManagement(state).allDistributionBeneficiaryCount;
export const selectAllDistrictDistributionBeneficiaryCount = (state) =>
  dashboardManagement(state).allDistrictDistributionBeneficiaryCount;

// camp Overview
export const selectAllCampOverviewCount = (state) =>
  dashboardManagement(state).campOverviewCount;

// Upcoming Camps
export const selectAllUpcomingCampsList = (state) =>
  dashboardManagement(state).upcomingCampsData;
