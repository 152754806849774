/**
 * Edit User Model
 * @format
 */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FieldArray, Formik } from "formik";
import { toast } from "react-toastify";
import { Switch } from "@material-tailwind/react";

import { UserFields } from "../../../constants";
import { selectAllRoleDataList } from "../../role_management/selector";
import { allRoleList } from "../../role_management/slice";
import { updateUserById } from "../slice";
import { selectAllAccessLevelDataList } from "../../access_level_management/selector";
import { Cross } from "../../../assets/icons/Cross";
import {
  getAllDistrict,
  getAllState,
  getAllUserType,
  selectDistrict,
  selectState,
  selectUserAllPermissions,
  selectUserType,
} from "../../common";
import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";
import { Delete } from "../../../assets/icons/Delete";
import { selectUserCurrentPage, selectUserDataPerPage } from "../selector";
import { allAccessLevelList } from "../../access_level_management/slice";
import MultipleTagsInput from "../../../components/multipleTagInput";
import { constants } from "../../../utils";
import SelectDropDown from "../../../components/selectDropDown";

const EditUserModels = ({ userData, toggleModal, getData }) => {
  const dispatch = useDispatch();
  const userPermissions = useSelector(selectUserAllPermissions) || {};
  const currentPage = useSelector(selectUserCurrentPage);
  const limit = useSelector(selectUserDataPerPage);

  const user = userData || {};

  const initialValues = {
    [UserFields.MOBILE_NUMBER]: "",
    [UserFields.MEMBER_ID]: "",
    [UserFields.ROLE]: [],
    [UserFields.ACCESS_LEVEL]: [],
    [UserFields.USER_TYPE]: [],
    [UserFields.NAME]: "",
    [UserFields.EMAIL]: "",
    [UserFields.GENDER]: "",
    [UserFields.IS_ACTIVE]: false,
  };
  const [initialObject, setInitialObject] = useState(initialValues);

  const getRoles = () => {
    dispatch(
      allRoleList({
        search: "",
        // page: 1,
        // perPage: 10,
        onSuccess: (props) => {
          console.log("Role loaded successfully");
        },
      })
    );
  };

  const getAccessLevel = () => {
    dispatch(
      allAccessLevelList({
        search: "",
        // page: 1,
        // perPage: 2,
        onSuccess: (props) => {
          console.log("Access level loaded successfully");
        },
      })
    );
  };

  const getDistrict = () => {
    dispatch(
      getAllDistrict({
        // district_name: "",
        // state_code: "",
        // sai_connect_state_id: "",
        onSuccess: (props) => {
          console.log("All District loaded successfully");
        },
      })
    );
  };

  const getState = () => {
    dispatch(
      getAllState({
        // state_name: "",
        // iso_code: "",
        onSuccess: (props) => {
          console.log("All State loaded successfully");
        },
      })
    );
  };

  const getUserType = () => {
    dispatch(
      getAllUserType({
        search: "",
        onSuccess: (props) => {
          console.log("All User Type loaded successfully");
        },
      })
    );
  };

  useEffect(() => {
    getRoles();
    getAccessLevel();
    getDistrict();
    getState();
    getUserType();
    if (user) {
      setInitialObject({
        ...user,
        [UserFields.ROLE]: user?.role?.map((item) => item._id) || [],
        [UserFields.USER_TYPE]:
          user?.user_type_id?.map((item) => item._id) || [],
        [UserFields.ACCESS_LEVEL]:
          user?.access_level?.map((item) => ({
            access_level_id: item?.access_level_id?._id || "",
            locale: item?.locale?.map((i) => i) || [],
          })) || [],
      });
    }
  }, [user]);

  const { data: roleData = [] } = useSelector(selectAllRoleDataList) || {};
  const rolesOptions = roleData?.map((item) => ({
    label: item.role_name,
    value: item._id,
  }));

  const { data: accessLevelData = [] } =
    useSelector(selectAllAccessLevelDataList) || {};
  const accessLevelIds =
    accessLevelData?.reduce((acc, item) => {
      acc[item.name] = item._id;
      return acc;
    }, {}) || {};
  const accessLevelOptions = accessLevelData?.map((item) => ({
    label: item.name,
    value: item._id,
  }));

  const { data: userType = [] } = useSelector(selectUserType) || {};
  const userTypeOptions = userType?.map((item) => ({
    label: item?.user_type_name,
    value: item?._id,
  }));

  const { data: district = [] } = useSelector(selectDistrict) || {};
  const districtOptions = district?.map((item) => ({
    group: item?.state_data?.state_name,
    label: item?.district_name,
    value: item?._id,
  }));

  const { data: state = [] } = useSelector(selectState) || {};
  const stateOptions = state?.map((item) => ({
    group: "India",
    label: item?.state_name,
    value: item?._id,
  }));

  const localeOptions = {
    national: [{ label: "India", value: "India" }],
    state: stateOptions,
    district: districtOptions,
  };

  const getLocaleOptions = (access_level_id) => {
    if (access_level_id === accessLevelIds?.National)
      return localeOptions.national || [];
    if (access_level_id === accessLevelIds?.State)
      return localeOptions.state || [];
    if (access_level_id === accessLevelIds?.District)
      return localeOptions.district || [];
    return [];
  };

  return (
    <Formik
      initialValues={initialObject}
      // validationSchema={updateUserValidationSchema}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        const updatedValues = {
          ...values,
          id: values._id,
        };
        delete updatedValues._id;
        console.log(updatedValues, "values");
        if (userPermissions?.Edit_User === true) {
          dispatch(
            updateUserById({
              updatedValues,
              onSuccess: (props) => {
                toast.success(props?.message);
                toggleModal();
                const search = "";
                getData("", currentPage, limit);
              },
            })
          );
        } else if (userPermissions?.Edit_User === false) {
          toast.error("you have No Permssions to Edit User");
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="fixed inset-0 z-50 flex justify-end">
            <div className="fixed inset-0 bg-[#07284B] opacity-50"></div>
            <div className="relative w-[43%] h-full bg-white shadow-lg overflow-y-auto scrollbar-hide">
              <div className="flex justify-between items-center bg-customBlue-mainBackground w-full h-[11%] p-6">
                <h2 className="text-[20px] font-bold">Edit User</h2>
                <button onClick={toggleModal} className="text-xl font-semibold">
                  <Cross width={32} height={32} />
                </button>
              </div>
              {/* Modal content */}
              <div className="w-full flex flex-col space-y-3 p-6">
                <div className="w-full">
                  <label className="text-[14px] font-bold">
                    Mobile Number/Sai Connect ID
                  </label>
                  <input
                    autoComplete="off"
                    type="tel"
                    name={UserFields.MEMBER_ID}
                    id={UserFields.MEMBER_ID}
                    maxLength={10}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={values[UserFields.MEMBER_ID]}
                    onBlur={handleBlur}
                    className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px]"
                  />
                  {touched[UserFields.MEMBER_ID] &&
                    errors[UserFields.MEMBER_ID] && (
                      <div className="mt-1 text-xs text-red-500">
                        {errors[UserFields.MEMBER_ID]}
                      </div>
                    )}
                </div>
                <div className="w-full">
                  <label className="text-[14px] font-bold">Name</label>
                  <input
                    type="text"
                    name={UserFields.NAME}
                    onChange={handleChange}
                    value={values[UserFields.NAME]}
                    onBlur={handleBlur}
                    className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px]"
                  />
                  {touched[UserFields.NAME] && errors[UserFields.NAME] && (
                    <div className="mt-1 text-xs text-red-500">
                      {errors[UserFields.NAME]}
                    </div>
                  )}
                </div>
                <div className="w-full">
                  <label className="text-[14px] font-bold">District</label>
                  <input
                    type="text"
                    name={UserFields.SSS_DISTRICT_NAME}
                    onChange={handleChange}
                    value={values[UserFields.SSS_DISTRICT_NAME]}
                    onBlur={handleBlur}
                    className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px]"
                  />
                  {touched[UserFields.SSS_DISTRICT_NAME] &&
                    errors[UserFields.SSS_DISTRICT_NAME] && (
                      <div className="mt-1 text-xs text-red-500">
                        {errors[UserFields.SSS_DISTRICT_NAME]}
                      </div>
                    )}
                </div>
                <div className="w-full">
                  <label className="text-[14px] font-bold">State</label>
                  <input
                    type="text"
                    name={UserFields.SSS_STATE_NAME}
                    onChange={handleChange}
                    value={values[UserFields.SSS_STATE_NAME]}
                    onBlur={handleBlur}
                    className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px]"
                  />
                  {touched[UserFields.SSS_STATE_NAME] &&
                    errors[UserFields.SSS_STATE_NAME] && (
                      <div className="mt-1 text-xs text-red-500">
                        {errors[UserFields.SSS_STATE_NAME]}
                      </div>
                    )}
                </div>
                <div className="w-full">
                  <label className="text-[14px] font-bold">Pin Code</label>
                  <input
                    type="text"
                    name={UserFields.PIN_CODE}
                    onChange={handleChange}
                    value={values[UserFields.PIN_CODE]}
                    onBlur={handleBlur}
                    className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px]"
                  />
                  {touched[UserFields.PIN_CODE] &&
                    errors[UserFields.PIN_CODE] && (
                      <div className="mt-1 text-xs text-red-500">
                        {errors[UserFields.PIN_CODE]}
                      </div>
                    )}
                </div>

                <div className="w-full">
                  <label className="text-[14px] font-bold">Email</label>
                  <input
                    type="email"
                    name={UserFields.EMAIL}
                    onChange={handleChange}
                    value={values[UserFields.EMAIL]}
                    onBlur={handleBlur}
                    className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px]"
                  />
                  {touched[UserFields.EMAIL] && errors[UserFields.EMAIL] && (
                    <div className="mt-1 text-xs text-red-500">
                      {errors[UserFields.EMAIL]}
                    </div>
                  )}
                </div>

                <div className="w-full">
                  <label className="text-[14px] font-bold">User Type</label>
                  <Multiselect
                    options={userTypeOptions}
                    displayValue="label"
                    onSelect={(selectedList) =>
                      setFieldValue(
                        UserFields.USER_TYPE,
                        selectedList.map((item) => item.value)
                      )
                    }
                    onRemove={(selectedList) =>
                      setFieldValue(
                        UserFields.USER_TYPE,
                        selectedList.map((item) => item.value)
                      )
                    }
                    selectedValues={userTypeOptions.filter((option) =>
                      values[UserFields.USER_TYPE].includes(option.value)
                    )}
                    showCheckbox
                    showArrow
                    placeholder="Select"
                    style={{
                      multiselectContainer: {
                        padding: "0px",
                        border: "2px solid #B0E0FF",
                        borderRadius: "12px",
                      },
                      searchBox: {
                        border: "none",
                        height: "44px",
                        overflowY: "scroll",
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",
                      },
                      chips: {
                        backgroundColor: "#B0E0FF",
                        color: "black",
                      },
                    }}
                  />
                  {touched[UserFields.USER_TYPE] &&
                    errors[UserFields.USER_TYPE] && (
                      <div className="mt-1 text-xs text-red-500">
                        {errors[UserFields.USER_TYPE]}
                      </div>
                    )}
                </div>

                {values[UserFields.USER_TYPE].includes(constants.orgUser) && (
                  <>
                    <div className="w-full">
                      <label className="text-[14px] font-bold">User Role</label>

                      <Multiselect
                        options={rolesOptions}
                        displayValue="label"
                        onSelect={(selectedList) =>
                          setFieldValue(
                            UserFields.ROLE,
                            selectedList.map((item) => item.value)
                          )
                        }
                        onRemove={(selectedList) =>
                          setFieldValue(
                            UserFields.ROLE,
                            selectedList.map((item) => item.value)
                          )
                        }
                        selectedValues={rolesOptions.filter((option) =>
                          values[UserFields.ROLE].includes(option.value)
                        )}
                        showCheckbox
                        showArrow
                        placeholder="Select Roles"
                        style={{
                          multiselectContainer: {
                            padding: "0px",
                            border: "2px solid #B0E0FF",
                            borderRadius: "12px",
                          },
                          searchBox: {
                            border: "none",
                            height: "44px",
                            overflowY: "scroll",
                            scrollbarWidth: "none",
                            msOverflowStyle: "none",
                          },
                          chips: {
                            backgroundColor: "#B0E0FF",
                            color: "black",
                          },
                        }}
                      />

                      {touched[UserFields.ROLE] && errors[UserFields.ROLE] && (
                        <div className="mt-1 text-xs text-red-500">
                          {errors[UserFields.ROLE]}
                        </div>
                      )}
                    </div>

                    <div className="w-full">
                      <label className="text-[14px] font-bold">
                        Access Level{" "}
                        {/* <span className="ml-2">
                    <button
                      type="button"
                      className="border rounded-md text-[12px] px-2"
                    >
                      Add
                    </button>
                  </span> */}
                      </label>
                      <div className="grid grid-cols-12 gap-4 mt-1 w-full items-end">
                        <div className="col-span-5">
                          <label className="text-[12px] font-bold">
                            Access
                          </label>
                        </div>
                        <div className="col-span-6">
                          <label className="text-[12px] font-bold">
                            Locale
                          </label>
                        </div>
                      </div>

                      <FieldArray
                        name="access_level"
                        render={(arrayHelpers) => (
                          <>
                            {values.access_level?.map((row, index) => (
                              <div
                                className="grid grid-cols-12 gap-4 mt-1 w-full items-start"
                                key={row.id}
                              >
                                <div className="col-span-5">
                                  <SelectDropDown
                                    options={accessLevelOptions}
                                    placeholder="select"
                                    value={
                                      accessLevelOptions?.find(
                                        (item) =>
                                          item?.value ===
                                          values.access_level[index]
                                            .access_level_id
                                      ) || null
                                    }
                                    onChange={(selectedOption) => {
                                      const selectedAccess =
                                        selectedOption?.value || "";

                                      setFieldValue(
                                        `access_level[${index}].access_level_id`,
                                        selectedAccess
                                      );
                                      setFieldValue(
                                        `access_level[${index}].locale`,
                                        []
                                      );
                                    }}
                                    touched={touched}
                                    errors={errors}
                                    isSearchable={true}
                                    isClearable={false}
                                  />
                                  {/* <Select
                                    blurInputOnSelect
                                    options={accessLevelOptions}
                                    placeholder="select"
                                    value={
                                      accessLevelOptions?.find(
                                        (item) =>
                                          item?.value ===
                                          values.access_level[index]
                                            .access_level_id
                                      ) || null
                                    }
                                    onChange={(selectedOption) => {
                                      const selectedAccess =
                                        selectedOption?.value || "";

                                      setFieldValue(
                                        `access_level[${index}].access_level_id`,
                                        selectedAccess
                                      );
                                      setFieldValue(
                                        `access_level[${index}].locale`,
                                        []
                                      );
                                    }}
                                    styles={{
                                      control: (provided) => ({
                                        ...provided,
                                        border: "2px solid #B0E0FF",
                                        borderRadius: "12px",
                                        height: "44px",
                                      }),
                                    }}
                                  /> */}
                                </div>
                                <div className="col-span-6">
                                  {row?.access_level_id ===
                                  accessLevelIds?.Pincode ? (
                                    <>
                                      {/* <input
                                    type="text"
                                    name={UserFields.LOCALE}
                                    onChange={(e) => {
                                      const inputValues = e.target.value
                                        .split(",")
                                        .map((item) => item.trim());
                                      setFieldValue(
                                        `access_level[${index}].locale`,
                                        inputValues
                                      );
                                    }}
                                    value={
                                      Array.isArray(
                                        values.access_level[index]?.locale
                                      )
                                        ? values.access_level[
                                            index
                                          ].locale.join(", ")
                                        : values.access_level[index]?.locale ||
                                          ""
                                    }
                                    onBlur={handleBlur}
                                    className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px]"
                                  /> */}
                                      <MultipleTagsInput
                                        name={`access_level[${index}].locale`}
                                        placeHolder="Enter locale"
                                        value={
                                          values.access_level[index]?.locale ||
                                          []
                                        }
                                        onChange={(newLocales) =>
                                          setFieldValue(
                                            `access_level[${index}].locale`,
                                            newLocales
                                          )
                                        }
                                      />
                                    </>
                                  ) : (
                                    <Multiselect
                                      groupBy="group"
                                      options={getLocaleOptions(
                                        row.access_level_id
                                      )}
                                      displayValue="label"
                                      onSelect={(selectedList) =>
                                        setFieldValue(
                                          `access_level[${index}].locale`,
                                          selectedList?.map(
                                            (item) => item.value
                                          )
                                        )
                                      }
                                      onRemove={(selectedList) =>
                                        setFieldValue(
                                          `access_level[${index}].locale`,
                                          selectedList?.map(
                                            (item) => item.value
                                          )
                                        )
                                      }
                                      selectedValues={getLocaleOptions(
                                        row.access_level_id
                                      ).filter((option) =>
                                        row.locale.includes(option.value)
                                      )}
                                      showCheckbox
                                      // showArrow
                                      style={{
                                        multiselectContainer: {
                                          padding: "0px",
                                          border: "2px solid #B0E0FF",
                                          borderRadius: "12px",
                                        },
                                        searchBox: {
                                          border: "none",
                                          // height: "44px",
                                          overflowY: "scroll",
                                          scrollbarWidth: "none",
                                          msOverflowStyle: "none",
                                        },
                                        chips: {
                                          backgroundColor: "#B0E0FF",
                                          color: "black",
                                        },
                                      }}
                                    />
                                  )}
                                </div>
                                {values.access_level.length > 1 && (
                                  <div
                                    className="flex justify-start items-center col-span-1 cursor-pointer h-[44px]"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <Delete width={24} height={24} />
                                  </div>
                                )}
                              </div>
                            ))}
                            <button
                              type="button"
                              onClick={() =>
                                arrayHelpers.push({
                                  id: Date.now(),
                                  access_level_id: "",
                                  locale: [],
                                })
                              }
                            >
                              Add Row
                            </button>
                          </>
                        )}
                      />
                    </div>
                  </>
                )}

                <div className="w-full">
                  <label className="text-[14px] font-bold">User Status</label>
                  <div className="flex items-center space-x-4">
                    <Switch
                      ripple={false}
                      checked={values[UserFields.IS_ACTIVE]}
                      onChange={() =>
                        setFieldValue(
                          UserFields.IS_ACTIVE,
                          !values[UserFields.IS_ACTIVE]
                        )
                      }
                      className="h-full w-full checked:bg-[#B0E0FF]"
                      containerProps={{
                        className: "w-8 h-4",
                      }}
                      circleProps={{
                        className:
                          "before:hidden left-1 border-none w-[12px] h-[12px]",
                      }}
                    />
                    <span>
                      {values[UserFields.IS_ACTIVE] ? "Active" : "Inactive"}
                    </span>
                  </div>
                </div>

                <div className="w-full flex justify-end space-x-4 h-[48px]">
                  <button
                    className="bg-black text-white px-4 py-2 rounded-md w-[32%] h-full"
                    onClick={(e) => {
                      e.preventDefault();
                      resetForm();
                    }}
                  >
                    Reset
                  </button>
                  <button
                    className="text-white px-4 py-2 rounded-md bg-WildWatermelon-button w-[32%] h-full font-bold"
                    type="submit"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export { EditUserModels };
