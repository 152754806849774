/**
 * Distribution Components
 * @format
 */

import React, { useState } from "react";

export function DistributionComponent(props) {
  const campSteps = [
    {
      stepName: "Check In",
    },
    {
      stepName: "Screening",
    },
    {
      stepName: "Measuring & Casting",
    },
  ];

  // State to track which step is open
  const [openStepBody, setOpenStepBody] = useState(null);

  // Toggle open state for the clicked step
  const toggleStep = (index) => {
    setOpenStepBody((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <>
      <div className="w-full flex flex-col justify-start items-start space-y-0 divide-y-2">
        {campSteps?.map((stepItem, stepIndex) => (
          <div className="w-full py-3" key={stepIndex}>
            {/* Label to toggle step body */}
            <label
              className="cursor-pointer font-bold text-[16px]"
              onClick={() => toggleStep(stepIndex)}
            >
              {`Step ${stepIndex + 1}: ${stepItem?.stepName}`}
            </label>

            {/* Show body if this step is open */}
            {openStepBody === stepIndex && (
              <div className="mt-2">
                <div>body content for {stepItem.stepName}</div>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
}
