/**
 * Assessment Components
 * @format
 */

import React, { useState } from "react";

export function AssessmentComponent(props) {
  const campSteps = [
    {
      stepName: "Check In",
      stepBody: [
        {
          fieldName: "Did the person checked in?",
          fieldtype: "checkBox",
        },
      ],
    },
    {
      stepName: "Screening",
      stepBody: [
        {
          fieldName: "Diagnosis",
          fieldtype: "checkBox",
        },
        {
          fieldName: "Recommended Device ",
          fieldtype: "checkBox",
        },
      ],
    },
    {
      stepName: "Measuring & Casting",
    },
  ];

  // State to track which step is open
  const [openStepBody, setOpenStepBody] = useState(null);

  // Toggle open state for the clicked step
  const toggleStep = (index) => {
    setOpenStepBody((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <>
      <div className="w-full flex flex-col justify-start items-start space-y-0 divide-y-2">
        {campSteps?.map((stepItem, stepIndex) => (
          <div className="w-full py-3" key={stepIndex}>
            {/* Label to toggle step body */}
            <label
              className="cursor-pointer font-bold text-[16px]"
              onClick={() => toggleStep(stepIndex)}
            >
              {`Step ${stepIndex + 1}: ${stepItem?.stepName}`}
            </label>

            {/* Show body if this step is open */}
            {openStepBody === stepIndex && (
              <div className="mt-2">
                {stepItem?.stepBody?.map((fieldItem, fieldIndex) => (
                  <div key={fieldIndex}>{fieldItem?.fieldName}</div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
}
